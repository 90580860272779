import React from 'react';
import './styles/App.css';
import { IntlProvider } from 'react-intl';
import Layout from './Layout';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { getLang } from './Redux/Actions/localeActions';
const history = createBrowserHistory();

class App extends React.Component {

  componentWillMount(){
    this.props.getLang()
  }

  render() {
    const { lang, messages } = this.props;

    return lang && (
      <IntlProvider key={lang} locale={lang} {...{messages}}>
        <Router history={history}>
          <div className="App">
            <Layout {...this.state} />
          </div>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (getServicePorps) => {
  return { 
    lang: getServicePorps.locale.lang,
    messages: getServicePorps.locale.messages
  }
}

export default connect(mapStateToProps, {
  getLang 
})(App);
