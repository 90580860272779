export const DOCUMENT_FORM_INIT_FORM_STATE = '[DOCUMENT] Init form state';
export const DOCUMENT_FORM_UPDATE_FORM_VALUE = '[DOCUMENT] Update form value';
export const DOCUMENT_FORM_SET_STEP = '[DOCUMENT] Set step';
export const DOCUMENT_FORM_FETCH_CONFIG = '[DOCUMENT] Fetch config';
export const DOCUMENT_FORM_FETCH_DOCUMENT = '[DOCUMENT] Fetch document';
export const DOCUMENT_FORM_STORE_VALUES = '[DOCUMENT] Store values';
export const DOCUMENT_FORM_REMOVE_STORE = '[DOCUMENT] Remove store';
export const DOCUMENT_FORM_REMOVE_STORE_KEY = '[DOCUMENT] Remove store key';

export const LOCALE_SET_LANGUAGE = '[LOCALE] Set language';
export const GET_LANGUAGE = '[GET] get language'
