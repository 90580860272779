import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { injectIntl } from 'react-intl';
import * as Service from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import * as Utils from '../../Utilities/Utils'

const getListStyle = (isDraggingOver, name) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    display: 'flex',
    flexWrap: name === 'droppableTemplate' ? 'none' : 'wrap',
    flexDirection: name === 'droppableTemplate' ? 'row': 'none',
    height: 'fit-content',
    maxHeight: '36vh',
    overflowX: name === 'droppableTemplate' ? 'scroll': 'none',
    overflowY: name === 'droppableTemplate' ? 'none': 'scroll',
    flex: name === 'droppableTemplate' ? 1 : 'unset',
});
 
const getItemStyle = (isDragging, draggableStyle, name) => ({
    height: name === 'droppableTemplate' ? 'fit-content' : '12vh',
    width: name === 'droppableTemplate' ? 'fit-content' : '12vh',
    minWidth: name === 'droppableTemplate' ? 'fit-content' : '12vh',
    margin: name === 'droppableTemplate' ? 'auto 0.5vw' : '0.5vh',
    ...draggableStyle
})

const getImgStyle = (item, token) => ({
    background: `url(${Utils.getPhoto(item.id, token, true)}) center center / 100% 100% no-repeat`,
    backgroundSize: 'cover'
})


class TabBarImg extends Component {

    SCROLL_DISTANCE = 80

    state = {
        allLocalImg: null
    }

    render() {
        const { imgTest, name } = this.props
        const { allLocalImg } = this.state
        const token = Utils.getToken()

        return imgTest && imgTest.length > 0 ? (
            <div className={`vertical-tap-bar`}>
                {name === 'droppableTemplate' ? 
                    <div className='TabBarImg-arrow-navigation-left' onClick={this.scrollLeft}><img src={Icons.SelectArriba}/></div> : null
                }
                <Droppable droppableId={name} direction={name === 'droppableTemplate' ? 'horizontal' : 'vertical'} isDragDisabled={false}>
                    {(provided, snapshot) => (
                        <div id={`${name}-style-3`} className="vertical-tap-bar scrollbar"
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver, name)}
                        >
                        {imgTest.map((item, k) => { return (
                            !item.noVisible && <Draggable
                                key={`draggable-key-${item.id}`}
                                draggableId={`${name}-draggable-${k}`}
                                index={item.id}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            name
                                        )}
                                    >
                                    {name === 'droppableTemplate' ? <img className="carrousel-draggable-img" id={`id-photo-${k}`} src={`data:image/jpeg;base64,${item.source ? item.source : item }`} />:
                                        <div className="carrousel-draggable-element">
                                            {/* <img id={`id-photo-${k}`} className="carrousel-draggable-element-img" src={`${Utils.getPhoto(item.id, token, true)}`} /> */}
                                            <div className="carrousel-draggable-element-icon" onClick={()=>this.delRepoItem(item)}>
                                                <img src={Icons.TrashWhiteTransparent} />
                                            </div>
                                            <div id={`id-photo-${k}`} className="carrousel-draggable-element-img" style={getImgStyle(item, token)}></div>
                                        </div> }
                                    </div>
                                )}
                            </Draggable>
                        )})}
                        {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {name === 'droppableTemplate' ? 
                    <div className='TabBarImg-arrow-navigation-right' onClick={this.scrollRight} ><img src={Icons.SelectArriba}/></div> : null
                }
            </div>
        ) : null
    }

    scrollRight = () => {
        document.getElementById('droppableTemplate-style-3').scrollLeft += this.SCROLL_DISTANCE;
    }
    scrollLeft = () => {
        document.getElementById('droppableTemplate-style-3').scrollLeft -= this.SCROLL_DISTANCE;
    }

    delRepoItem = (item) => {
        const { intl, displayNotifyDialog, callGetAllRepositoryItems, defaultCollectionId } = this.props
        Service.isUserRepoItem(item.id)
            .then(response=>{
                if(!response.data) { 
                    Service.deleteRepoItem(item.id)
                        .then(response=>{
                            callGetAllRepositoryItems(defaultCollectionId)
                        })
                        .catch(error=>{
                            console.error('deleteRepoItem -> ', error)
                        })
                } else {
                    displayNotifyDialog(intl.formatMessage({id:'MSG_IMG_USED'}))
                }
            })
            .catch(error=>{console.error('isUsedRepoItem -> ', error)})
    }
}

export default injectIntl(TabBarImg)