import React, { Component } from 'react';
import DraggableSpace from '../DraggableSpace'
import * as Utils from '../../Utilities/Utils'

export default class Template1 extends Component {

   render() {
      const { items, page, pagePosition, stateGemerics, methodToRun, reloadActualPages, isPreview } = this.props
      const textContent = Utils.getElementsText(page, "TEXT")
      const imgContent = Utils.getElementsText(page, "PICT")
      const ordedElements = Utils.orderArrayByParam(this.props.page.elements)
      
      return(
         <div key={`template-1-${pagePosition}-id_${page.id}`} className="main-template">
            <DraggableSpace stateGemerics={stateGemerics}
                            sizeImg={'65%'}
                            items={imgContent.pictureElement.repositoryItemId ? [imgContent.pictureElement] : null} 
                            page={page} 
                            pagePosition={`${pagePosition}-${ordedElements[0].id}`} 
                            methodToRun={methodToRun} 
                            reloadActualPages={reloadActualPages} 
                            isPreview={isPreview}
                            editSize={{height:400,width:450}}
            />
            <textarea id='splitLines' key={`template-1-${pagePosition}-text-area-id_${page.id}`} className={`template1-text-2 scrollbar ${isPreview ? 'template-preview' : ''}`}
                      defaultValue={textContent.textElement && textContent.textElement.text} onBlur={(data)=>this.compileAllText(data, textContent)}
                      disabled={isPreview ? true : false}
            />
         </div>
      );
   }

   compileAllText = (data, element) => {
      const { updateText } = this.props
      updateText(data, element)
   }

   sendMessage = (e) => {
      let limit = 12
      let textarea = document.getElementById("splitLines");
      let spaces = 75
      
      let lines = e.target.value.split("\n");

      for (let i = 0; i < lines.length; i++) {
         if (lines[i].length <= spaces) continue;
         let j = 0;

         let space = spaces;

         while (j++ <= spaces) {
            if (lines[i].charAt(j) === " ") space = j;
         }

         lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
         lines[i] = lines[i].substring(0, space);
      }
      
      // if(lines.length>limit) {
      //    textarea.style.color = 'red';
      //    setTimeout(function(){
      //          textarea.style.color = '';
      //    }, 500);
      // }    
      textarea.value = lines.slice(0, limit).join("\n");

   }
}
