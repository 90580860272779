import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Dialog from './GenericDialog';
import Form from '../UserApplications/Documents/Form';

import { fetchDocument, fetchFormConfig } from '../Redux/Actions/documentFormActions';

class DialogCreateDoc extends Component {
  NEW_SECTION_ADDITIONAL_INFO = 'Recuerda que una vez creado el documento podrás editarlo y renombrar su título cuando quieras';

  state = {
    inputValue: null
  };

  submit = async () => {
    const { history, fetchDocument, fetchFormConfig, close } = this.props;

    await fetchDocument('testament_open.md');
    fetchFormConfig('testament_open');
    history.push('/fillabledocument');

    close();
  };

  render() {
    const { open, close } = this.props;

    return (
      <Dialog
        showButtons={false}
        open={open}
        cross={close}
        customStyles="main-new-section"
      >
        <Form
          id="previous"
          className="FillableDocument__Form pad"
          submitEvent={this.submit}
          exitEvent={close}
        />
        <div className="new-section-aditional-info">
          <FormattedMessage id="NEW_SECTION_ADDITIONAL_INFO" />
        </div>
      </Dialog>
    );
  }
}

export default withRouter(
  connect(
    null,
    { fetchDocument, fetchFormConfig }
  )(DialogCreateDoc)
);
