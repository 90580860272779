import React, { Component } from 'react';
import * as Service from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import * as Icons from '../Utilities/ImgConfig';
import ApplicationList from '../components/ApplicationList';
import AlbumsDialog from '../Dialogs/AlbumsDialog'
import Dialog from '../Dialogs/GenericDialog'
import LegalNote from '../LegalNote'
import { FormattedMessage, injectIntl } from 'react-intl';

class MyApps extends Component {

  componentWillMount() {
    if(Utils.userAcceptPolicyRules()) {
      this.getInfo()
      this.getProfileImage(this.state.profileFilename);
      this.getAllAlbums()
    } else {
      this.openCloseDialogR()
    }
    this.getCode()
  }

  constructor(props) {
    super(props);
      this.state = {
        param1: null,
        albums: null,
        profileImage: null,
        userData: {},
        profileFilename: null,
        dataLoginFacebook: null,
        showAlbumList: false,
        allUserInfo: null,
        showDialogR: null,
        isLoadCode: null
      }
  }
   
  render() {
    const { userData, showAlbumList, albums, dataLoginFacebook, allUserInfo, showDialogR, isLoadCode } = this.state
    const { intl } = this.props;
    
    const previewPolicyRules = showDialogR ? 
      <Dialog open={showDialogR} accept={this.acceptPolicyRules} close={this.openCloseDialogR} cross={true} customStyles='main-dialog-notify-reset scrollbar-dialog'>
        <LegalNote />
      </Dialog> : null

    const albumList = showAlbumList ? 
                      <AlbumsDialog open={showAlbumList}
                                    close={this.showDialogAlbums}
                                    albums={albums}
                                    token={Utils.getToken()} 
                                    dataLoginFacebook={dataLoginFacebook}
                                    push={this.props.router.history.push}
                                    allUserInfo={allUserInfo}
                                    getAllAlbums={this.getAllAlbums}
                        /> : null
    const allName = userData && ((userData.name ? userData.name : ' ') +' '+ (userData.surname ? userData.surname : ' ')) || ' '

    return isLoadCode ? (
        <div className="load-code-view">
          <img src={Icons.spinner}/>
          <div><FormattedMessage id="CONNECTION_WITH_INSTAGRAM" />{' ...'}</div>
        </div>
      ) : (
        <div className="myapp-main-container">
          <video autoPlay loop muted id="myVideo">
            <source src={Icons.vitalbook_1} type="video/mp4" />
            {'Your browser does not support HTML5 video.'}
          </video>
          {previewPolicyRules}
          {albumList}
          <div className="listapps-container-title-apps">
            <div className="listapps-container-title">
              <label className="listapps-container-title-user">{intl.formatMessage({id: 'WELCOME'})+' '+allName+`,`}</label>
              <label className="listapps-container-title-welcome"><FormattedMessage id="MY_PANEL" /></label>
            </div>
            <ApplicationList name="App1" albumList={this.showDialogAlbums} push={this.pushToScreen} token={Utils.getToken()} allUserInfo={allUserInfo} />
          </div>
        </div>
      );
  }

  getCode = () => {
    let queryString = window.location.search.replace('?','');
    let arrayParams
    if (queryString) {
      this.setState({isLoadCode: true})
      queryString = queryString.split('#')[0];
      arrayParams = queryString.split('&');
      arrayParams = arrayParams[0].split("code=");
      arrayParams[1]=arrayParams[1].replace("#_", "")
      if (arrayParams[1] !== null && arrayParams[1] !== "")
        Service.accessAuthInstagram(arrayParams[1])
          .then(response => {
            window.close()
          })
          .catch(error => {
            window.close()
          })
    }
  }

  openCloseDialogR = () => {
    this.setState({showDialogR: !this.state.showDialogR})
  }
  acceptPolicyRules = () => {
    Service.acceptPrivacyPolicy({ token: Utils.getFacebookToken(),  userId: this.props.router.history.location.state.userDataF.id })
      .then(response => {
        localStorage.setItem('token', response.data.token)
        this.setState({showDialogR: !this.state.showDialogR}, 
          ()=>this.getInfo(),
          ()=>this.getAllAlbums(),
          ()=>this.getProfileImage(this.state.profileFilename)
        )
      })
      .catch(error => {
        console.error('ACCEPT_POLICY_RULES_ERROR -> ', error)
      })
  }
  showDialogAlbums = () => {
    const { showAlbumList } = this.state
    if (Utils.userAcceptPolicyRules())
      this.setState({showAlbumList: !showAlbumList})
    else
      this.openCloseDialogR()
  }
  getAllAlbums = () => {
    Service.getUserAlbums(localStorage.getItem('userMail'))
      .then((response)=>{
        this.setState({albums: response.data.content})
      })
      .catch((error)=>{
        console.error("GET_USERS_ALBUMS_ERROR -> ", error)
      })
  }
  getInfo = () => {
    Service.getInfoLoggedUser(localStorage.getItem('userMail'))
      .then((response) => {
        this.updateStateUserData(response.data.content[0])
        this.setState({profileFilename: response.data.content[0].profileImage, allUserInfo: response.data.content[0]});
      })
      .catch((error)=>{
          console.error("GET_INFO_LOGED_USER_ERROR -> ", error)
      })
  }
  updateStateUserData = (response) => {
    let date = new Date(response.birthday);
    let userInfo = {
      'name': response.name,
      'surname': response.surname,
      'birthday': date,
      'email': response.email,
      'id': response.id
    };
    this.setState({userData : userInfo});
  }
  getProfileImage = (profileFilename) => {
    if(profileFilename){
      Service.uploadToBase64(profileFilename)
        .then((response)=>{
          this.setState({profileImage: `data:image/jpeg;base64,${response.data}`});
        })
        .catch((error)=>{
          console.error("Get_Profile_Image_ERROR -> ", error)
        })
    }
  }
  getDataLoginFacebook = (response) => {
    this.setState({dataLoginFacebook: response});
  }
  pushToScreen = (navDirection, params) => {
    if (Utils.userAcceptPolicyRules())
      this.props.router.history.push(navDirection, params)
    else
      this.openCloseDialogR()
  }
}

export default injectIntl(MyApps)