import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Icons from './Utilities/ImgConfig';

class PrivacyRules extends Component {

    render() {
        
        return (
            <div>
                <div className="main-legal-note scrollbar">
                    <div className="legal-note-header">
                        <div>
                            <img className="legal-note-header-logo" src={Icons.LogoVitalbookBig} />
                        </div>
                    </div>
                    <div className="legal-note-content">

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_PRIVACY_RULES" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_PRIVACY_RULES_TEXT" />
                        
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_IDENTIFICATION" /></h3>
                        <ul><FormattedMessage id="LEGAL_NOTE_TITLE_IDENTIFICATION_TEXT_LINE_1" /></ul>
                        <ul><FormattedMessage id="LEGAL_NOTE_TITLE_IDENTIFICATION_TEXT_LINE_2" /></ul>
                        <ul><FormattedMessage id="LEGAL_NOTE_TITLE_IDENTIFICATION_TEXT_LINE_3" /></ul>
                        <ul><FormattedMessage id="LEGAL_NOTE_TITLE_IDENTIFICATION_TEXT_LINE_4" /></ul>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_1" />Tratamos datos personales en los siguientes supuestos:
                            <ul><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_2" />(i) Visitas a nuestra página web;</ul>
                            <ul><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_3" />(ii) Interacción, conexión o enlaces con nuestra página web a través de herramientas de redes sociales, blogs u otras webs; </ul>
                            <ul><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_4" />(iii) Uso de alguno de nuestros servicios de comunicación electrónica: correo electrónico, formulario de contacto o chat online.</ul>
                            <ul><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_5" />(iv) Envío de currículum vitae para formar parte de nuestro equipo.</ul>
                            <ul><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_6" />(v) Creación de cuentas de usuario y acceso a la cuenta. Contactos de la cuenta;</ul>
                            <ul><FormattedMessage id="LEGAL_NOTE_TITLE_YOUR_DATA_LINE_7" />(vi) Contratación electrónica de nuestros servicios.</ul>

                        
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_1" />
                        

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_2" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_3" />
                            

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_4" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_5" />

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_6" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_7" />

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_8" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_9" />

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_10" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_11" />
                            <br></br>
                            <br></br>
                            <u><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_12" /></u><br></br>
                            <u><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_13" /></u><br></br>
                            <br></br>
                            <br></br>

                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_14" />
                            <br></br>
                            <u><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_15" /></u><br></br>
                            <br></br>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_16" /> 
                            <br></br>
                            <u><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_17" /></u><br></br>
                            <br></br>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_18" />

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_19" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_20" />

                            <h4><FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_21" /></h4>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_USE_DATA_LINE_22" />

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_1" />                        
                        <br></br>
                        <u><FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_2" /></u><br></br>
                        <br></br>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_3" />
                        <br></br>
                        <u><FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_4" /></u><br></br>
                        <br></br>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_5" />
                        <br></br>
                        <u><FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_6" /></u><br></br>
                        <br></br>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_COMMUNICATION_DATA_LINE_7" />


                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_1" />
                                                
                        <table className="legal-note-table">
                            <tr>
                                <th><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_2" /></th>
                                <th><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_3" /></th> 
                            </tr>
                            <tr>
                                <td><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_4" /></td>
                                <td><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_5" /></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_6" /></td>
                                <td><FormattedMessage id="LEGAL_NOTE_TITLE_TRANSFER_DATA_LINE_7" /></td>
                            </tr>
                        </table>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_RIGHTS" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_RIGHTS_LINE" />


                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_CONTROL_RIGHTS" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_CONTROL_RIGHTS_LINE" />


                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_SAVED_DATA" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_SAVED_DATA_LINE" />
                        
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_USER_RESPONSABILITY" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_USER_RESPONSABILITY_LINE" />
                    
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_SECURITY_MEASURES" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_SECURITY_MEASURES_LINE" />
                    
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_RELATIONAL_RULES" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_RELATIONAL_RULES_LINE_1" />
                        <FormattedMessage id="LEGAL_NOTE_TITLE_RELATIONAL_RULES_LINE_2" />
                        <FormattedMessage id="LEGAL_NOTE_TITLE_RELATIONAL_RULES_LINE_3" />
                        <FormattedMessage id="LEGAL_NOTE_TITLE_RELATIONAL_RULES_LINE_4" />
                        <FormattedMessage id="LEGAL_NOTE_TITLE_RELATIONAL_RULES_LINE_5" />

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_LEGISLATION_JURISDICTION_MIN" /></h3>
                        <FormattedMessage id="LEGAL_NOTE_TITLE_LEGISLATION_JURISDICTION_MIN_LINE" />
                    </div>
                    <br></br>
                </div>
            </div>
        )
    }
}

export default PrivacyRules;
