import React, { Component } from 'react';

class Faqs extends Component {

  state = {
    hideShowParraf:   true,
    hideShowParraf1:  false,
    hideShowParraf2:  false,
    hideShowParraf3:  false,
    hideShowParraf4:  false,
    hideShowParraf5:  false,
    hideShowParraf6:  false,
    hideShowParraf7:  false,
    hideShowParraf8:  false,
    hideShowParraf9:  false,
    hideShowParraf10: false,
    hideShowParraf11: false,
    hideShowParraf12: false,
    hideShowParraf13: false,
    hideShowParraf14: false,
    hideShowParraf15: false,
    hideShowParraf15: false,
    hideShowParraf16: false,
    hideShowParraf17: false,
    hideShowParraf18: false,
    hideShowParraf19: false,
    hideShowParraf20: false,
    hideShowParraf21: false,
    hideShowParraf22: false
  }


  mostrar = (changeState, val) => {
    this.setState({[changeState]: !val})
  } 


  render() {
    const { hideShowParraf,   hideShowParraf1,  hideShowParraf2, 
            hideShowParraf3,  hideShowParraf4,  hideShowParraf5,
            hideShowParraf6,  hideShowParraf7,  hideShowParraf8,
            hideShowParraf9,  hideShowParraf10, hideShowParraf11,
            hideShowParraf12, hideShowParraf13, hideShowParraf14,
            hideShowParraf15, hideShowParraf16, hideShowParraf17,
            hideShowParraf18, hideShowParraf19, hideShowParraf20,
            hideShowParraf21, hideShowParraf22
    } = this.state

    return (
      <div>        
        <div className='main-FAQS'>
            {/* <div className="legal-note-header">
            <div className="legal-note-header-back"><img src={Icons.HomeLogoGrey} onClick={this.returnHome}/></div>
            <div><img className="legal-note-header-logo" src={Icons.LogoVitalbookBig} onClick={this.returnHome}/></div>
            <div></div> */}
          {/* </div> */}
          <h2>FAQS Vitalbook</h2>
          <li><a href="https://www.youtube.com/channel/UC8a4aFJpWyXyS66pd-hTmSg/videos">Videos de ayuda</a></li>
          <dl className="scrollbar">
          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf', hideShowParraf) }>¿Qué es Vitalbook?</h3>              
            <dd>
              <div className={`container-faq-2 ${hideShowParraf ? 'show-class' : '' }`} >
                <p> 
                  Nos pasamos toda la vida almacenando información, vivencias, experiencias y conocimientos,
                  pero al fallecer este “disco duro” se borra sin tener la posibilidad de tener una copia de
                  seguridad, de dejar un legado mental de todo aquello que para ti es importante. Seguramente
                  guardas en diferentes dispositivos algunos vídeos, fotos, contraseñas, contratos y cosas que si
                  no estás serán difíciles de localizar y quedarán “borradas”.<br /><br />

                  Vitalbook te ayuda a guardar toda esa información y compartirla con las personas que desees.<br />

                  Es una plataforma creada en un entorno seguro, dónde el usuario guardará sus recuerdos
                  basados en sus fotos, vídeos, redes sociales y documentos y podrá traspasarlos a sus
                  descendientes y amigos. Cuando él ya no esté esta información quedará en custodia de un
                  usuario (un Vitalangel, que él habrá elegido previamente) con la finalidad de realizar un
                  fideicomiso de esta información.<br /><br /><br />
                </p>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf1', hideShowParraf1)}>¿Cómo se accede a Vitalbook?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf1 ? 'show-class' : '' }`}>
                Para acceder a <b> Vitalbook </b> escribe la URL <b> www.vitalbook.me </b> en tu navegador habitual.
                <br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>  
            <h3 onClick={()=>this.mostrar('hideShowParraf2', hideShowParraf2)}>¿Qué contenido tendré a mi disposición?</h3>           
            <dd>
              <div className={`container-faq-2 ${hideShowParraf2 ? 'show-class' : '' }`}>
                Todo tu contenido multimedia (video, imágenes) estará a tu disposición en <b>Vitalbook</b>.  <br/>
                En concreto, esto incluye todo el contenido multimedia previamente subido a través de <b>vitalbook.me</b>  y todo el contenido multimedia que se subió a las RRSS directamente desde las cuentas asociadas.<br/><br/>
                También tendrás disponible los ficheros y contratos que quiera centralizar para que tu <b>Vitalángel</b> pueda recogerlos en el momento que ya no estés, para proceder a su baja en las RRSS, tener disponibles tus passwords, testamentos, seguros y cuentas bancarias.<br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>  
            <h3 onClick={()=>this.mostrar('hideShowParraf3', hideShowParraf3)}>¿Qué puedo lograr?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf3 ? 'show-class' : '' }`}>
                  Cuando una persona fallece, su recuerdo pasa de generación en generación de una manera más o menos real, 
                  pero con el tiempo todos acabamos formándonos una imágen o recuerdo de nuestros familiares o amigos que no se adecúa a la realidad que pretendíamos que tuvieran de nosotros. 
                  <br/><br/>La tecnología actual nos permite acumular mucha información a lo largo de nuestra vida y así mismo personalizar cómo queremos que se nos recuerde. <br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>          
            <h3 onClick={()=>this.mostrar('hideShowParraf4', hideShowParraf4)}>¿Cómo puedo empezar?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf4 ? 'show-class' : '' }`}>
                Regístrate en nuestra página de inicio, rellena el formulario con
                tus datos, escoge tu plan gratuito o de pago y empieza a confeccionar
                tus recuerdos. Puedes crear tantos Vitalbooks como desees, uno para cada uno de
                tus seres queridos. También podrás hacer un Vitalbook para regalar en un cumpleaños, el día
                del padre o de la madre, a un amigo, etc... <br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf5', hideShowParraf5)}>¿Qué es un Vitalángel?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf5 ? 'show-class' : '' }`}>
                Un Vitalangel es la persona de confianza a la que asignas toda tu información el día que ya no estés.
                Puedes tener un Vitalangel para cada Vitalbook creado, y otro o el mismo para
                la información más importante como testamento, últimas voluntades o tus contraseñas.<br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf6', hideShowParraf6)}>¿Quien puede ser Vitalángel?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf6 ? 'show-class' : '' }`}>
                Cualquier persona, asociación, entidad o empresa podrá ser tu Vitalangel,
                no obstante, te aconsejamos que sea una persona con mayoría de edad ya que
                por ley no podremos entregarle tu legado hasta que no haya cumplido la mayoría de edad. <br/><br/>
              </div>
            </dd>
          </div>
          
          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf7', hideShowParraf7)}>¿Qué pasa cuando mi Vitalángel ya no esté?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf7 ? 'show-class' : '' }`}>
                Cuando tu Vitalangel fallezca como usuario de Vitalbook, tu información quedará
                en su cuenta de usuario y serán los Vitalangels que él haya decidido los que
                se harán con su titularidad.<br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf8', hideShowParraf8)}>¿Puedo invitar a mi familia y amigos a visitar mi Vitalbook?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf8 ? 'show-class' : '' }`}>
                Por supuesto, estaremos encantados de que se unan a la comunidad más grande del
                mundo. Queremos preservar los recuerdos vitales, ideas e historias de miles de millones de
                personas. Piensa en ello como una biblioteca que tiene personas en lugar de libros, o una
                historia interactiva de las generaciones actuales y futuras. <br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>
      
            <h3 onClick={()=>this.mostrar('hideShowParraf9', hideShowParraf9)}>¿Quién tendrá acceso a mis archivos?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf9 ? 'show-class' : '' }`}>
                Solo los <b>Vitalángel</b> que hayas elegido tendrán acceso a tus archivos y serán ellos los custodios de tu vida digital. <br/><br/>
              </div>
            </dd>
          </div>

          <div className='container-faq-1'>
            <h3 onClick={()=>this.mostrar('hideShowParraf10', hideShowParraf10)}>¿Se pueden descargar estos archivos?</h3>
            <dd>
              <div className={`container-faq-2 ${hideShowParraf10 ? 'show-class' : '' }`}>
                  Solo tú o tu Vitalangel (una vez que ya no
                  estés), podréis realizar descarga de archivos. Siempre que verifiquemos que la petición es
                  correcta.<br/><br/>
                </div>
              </dd>
            </div>
          
            <div className='container-faq-1'>
              <h3 onClick={()=>this.mostrar('hideShowParraf11', hideShowParraf11)}>¿Qué tipo de archivos puedo guardar?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf11 ? 'show-class' : '' }`}>
                  Puedes guardar tanta información como quieras, según el plan en el que
                  estés registrado. Pueden ser Vitalbooks,
                  vídeos, contraseñas, documentos, testamento, actos de
                  ultimas voluntades, donaciones, escrituras, seguros, cuentas corrientes, tu árbol genealógico,
                  etc. Todo lo que para ti sea importante legar.<br/><br/><br/>
                </div>
              </dd>
            </div>      

            <div className='container-faq-1'>
              
              <h3 onClick={()=>this.mostrar('hideShowParraf12', hideShowParraf12)}>¿Puedo confeccionar mi testamento?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf12 ? 'show-class' : '' }`}>
                  Tienes la opción mediante nuestra herramienta de documentos, de confeccionar
                  un testamento legal en el que una vez completado con tus datos y deseos podrás descargar,
                  imprimir y seleccionar uno de
                  los notarios que tenemos en nuestro directorio para formalizar y darle legitimidad.
                  El notario aparte de darte una copia impresa, te dará el documento digitalizado para que
                  lo añadas a tus archivos de Vitalbook..<br/><br/>
                </div>
              </dd>
            </div>

            <div className='container-faq-1'>
              
              <h3 onClick={()=>this.mostrar('hideShowParraf13', hideShowParraf13)}>¿Puedo confeccionar mis últimas voluntades?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf13 ? 'show-class' : '' }`}>
                  En la carpeta Mis Documentos, tienes toda la documentación relativa a
                  este proceso. Puedes descargarte gratuitamente nuestra "Guía para la utilización del acto de
                  ultimas voluntades" y realizar todos los pasos para dejarlo listo. <br/><br/>
                </div>
              </dd>
            </div>

            <div className='container-faq-1'>
            
              <h3 onClick={()=>this.mostrar('hideShowParraf14', hideShowParraf14)}>¿Qué servicios me ofrece Vitalbook?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf14 ? 'show-class' : '' }`}>  
                  En Vitalbook ofrecemos también servicios extra con los que completar tu legado digital:
                  Reputación Online
                  Programación de mensajes
                  Baja en redes sociales
                  Testamento y Testamento Ológrafo
                  Directorio de funerarias
                  Generación de Urls para la descarga de tus Vitalbooks
                  Comparamos tus seguros actuales ofreciéndote la mejor cobertura posible
                  Obtención de tu árbol genealógico<br/><br/>
                </div>
              </dd>
            </div>

            <div className='container-faq-1'>
              <h3 onClick={()=>this.mostrar('hideShowParraf15', hideShowParraf15)}>¿Qué pasa cuando ya no esté?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf15 ? 'show-class' : '' }`}>  
                  Cuando ya no estés tu <b>Vitalángel</b> debe ponerse en contacto con nosotros para autentificar tu defunción y aportar toda la documentación que se le requiera. 
                  Una vez comprobada la misma, por nuestro departamento legal tu información será traspasada al mismo en un periodo máximo de 15 días. <br/><br/>
                </div>
              </dd>
            </div>

            <div className='container-faq-1'>
              <h3 onClick={()=>this.mostrar('hideShowParraf16', hideShowParraf16)}>¿Puedo nombrar a más de un Vitalangel?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf16 ? 'show-class' : '' }`}>  
                  Sí claro, podrás nombrar uno por cada Vitalbook o documento. El único requisito es que
                  este registrado como usuario en Vitalbook.
                </div>
              </dd>
            </div>

            <div className='container-faq-1'>
              
              <h3 onClick={()=>this.mostrar('hideShowParraf17', hideShowParraf17)}>¿Es seguro vitalbook?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf17 ? 'show-class' : '' }`}>  
                  Sin duda es el lugar más seguro del mundo para guardar todos tus datos para siempre. La
                  "nube" son miles de servidores, físicos y virtuales, dedicados a conservar y proteger tus datos,
                  creando copias de seguridad diarias para garantizar que jamás pierdas un sólo byte de
                  información. Nuestra tecnología encriptará todos tus archivos y los guardará de forma
                  descentralizada. Solo tú y tus Vitalangels tendréis acceso. Guardar tus datos en tus dispositivos
                  es poco recomendable ya que cualquier incidencia te borraria toda la información.<br/>  <br/><br/>
                </div>
              </dd>
            </div>

            <div className='container-faq-1'>
              <h3 onClick={()=>this.mostrar('hideShowParraf18', hideShowParraf18)}>¿Cómo protegemos tus datos?</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf18 ? 'show-class' : '' }`}>  
                  Nuestra plataforma web está dotada de las máximas garantías necesarias y exigidas, además
                  de otras complementarias que ofrece Vitalbook como: certificado SSL de
                  seguridad, encriptación de datos, codificación y decodificación de la transmisión de los datos
                  entre usuario y web... Nuestros procesos de seguridad están sometidos a los mismos
                  parámetros que los de la banca internacional.
                  Vitalbook está obligado por ley a usar los datos facilitados exclusivamente con el propósito de
                  ejecutar la voluntad del cliente, y no está autorizado a transmitir ninguna información a terceras
                  partes.
                  Además, nos regimos por la nueva ley de protección de datos (LOPD 2018), que prevé todo un
                  armazón jurídico que garantiza la confidencialidad de los datos que se desean preservar y que
                  regula los protocolos a seguir para la cesión, uso, publicación, derecho al olvido y
                  consentimiento, a la hora de regular el patrimonio digital personal. Las condiciones para el
                  consentimiento se han fortalecido, y las empresas ya no podrán usar términos y condiciones
                  indescifrables repletos de jerga legal, ya que la solicitud de consentimiento debe darse en una
                  forma inteligible y de fácil acceso. El propósito es que sea tan simple y rápido retirar el
                  consentimiento como darlo.
                </div>
              </dd>
            </div>


            <div className='container-faq-1'>
              
              <h3 onClick={()=>this.mostrar('hideShowParraf19', hideShowParraf19)}>¿Qué pasa con mis archivos si dejo de pagar?</h3>
              <dd>
              <div className={`container-faq-2 ${hideShowParraf19 ? 'show-class' : '' }`}>  
                Tus datos nunca se pierden, no podrás añadir ni cambiar nada de lo que hayas creado hasta la fecha de impago.<br/><br/>
                Custodiaremos tus datos y llegado el momento traspasaremos la información a tus legítimos herederos.<br/><br/>
                Es posible que dependiendo del volumen y del tiempo que guardemos estos datos tengan que pagar un extra por su descarga si quieren recuperarlos.<br/><br/>
              </div>
            </dd>
            </div>

            <div className='container-faq-1'>
              
              <h3 onClick={()=>this.mostrar('hideShowParraf20', hideShowParraf20)}>¿Puedo cambiar de Plan?</h3>
              <dd>
              <div className={`container-faq-2 ${hideShowParraf20 ? 'show-class' : '' }`}>  
                Siempre podrás pasar a un plan superior o inferior en base al volumen de datos que tengas.<br/> 
                Si es a uno inferior nuestro sistema te avisará si es factible realizar el cambio y que supone este cambio.<br/><br/> 
            </div>
            </dd>
            </div>

            <div className='container-faq-1'>
              
              <h3 onClick={()=>this.mostrar('hideShowParraf21', hideShowParraf21)}>¿Como puedo pagar?</h3>
              <dd>
              <div className={`container-faq-2 ${hideShowParraf21 ? 'show-class' : '' }`}> 
                Nuestros planes de pago están diseñados para que puedas pagar una pequeña cuota mensual
                o una anual con descuento. El pago se realiza siempre mediante tarjeta de crédito a través
                de nuestra pasarela de pago
                segura Redsys. Cualquier cargo por contratación de otros servicios será cargado en la misma.
              </div>
            </dd>
            </div>


            <div className='container-faq-1'>
              <h3 onClick={()=>this.mostrar('hideShowParraf22', hideShowParraf22)}>Política de cancelación</h3>
              <dd>
                <div className={`container-faq-2 ${hideShowParraf22 ? 'show-class' : '' }`}> 
                  Podrás cancelar tu suscripción siempre que quieras, en nuestro espacio web.
                  Para ello solo tendrás que comunicarlo a través del correo vitalbook@vitalbook.me.  <br/><br/>
                </div>
              </dd>
            </div>

          </dl>
        </div>
      </div>
    )
  }

  returnHome = () => {
    this.props.router.history.push('/myapps', {token: this.props.router.location.state.token})
  }
}

export default Faqs;
