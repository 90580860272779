import React, { Component } from 'react';
import * as Service from '../../../Utilities/Services'
import * as Utils from '../../../Utilities/Utils'
import * as Icons from '../../../Utilities/ImgConfig'
import SlideMenuOptions from './SlideMenuOptions'
import FlotingDialog from '../../../Dialogs/FlotingDialog' 
import * as moment from 'moment'
import { FormattedMessage } from 'react-intl';

export default class MenuVistaFriend extends Component {

    componentWillMount() {
        const { type, userData } = this.props;
        const isVitalAngel = type === 'myVitalAngels';
        const isMyContact = type === 'myContacts';
        const img = (isVitalAngel || isMyContact) ? Utils.getProfileImageOfMyConnection(userData.id, Utils.getToken()) : Utils.getProfileImageOfMyConnectionRq(userData.id, Utils.getToken())
        this.setState({userImgProfile: img})
    }
	
	state = {
        showMenuOptions: null,
        msgError: null,
        typeMessage: null,
        showDialog: null,
        userImgProfile: null
    };
 
	render() {
        const { showMenuOptions, showDialog, msgError, typeMessage, userImgProfile } = this.state
        const { type, userData, updateUsers, lang } = this.props
        const MenuShowMenuOptions = showMenuOptions ? <SlideMenuOptions updateUsers={updateUsers}
                                                                        userData={userData}
                                                                        type={type}
                                                                        setMessageDialog={this.setMessageDialog}
                                                                        closeMenuOptns={this.closeMenuOptns}
                                                                        lang={lang}
                                                       /> : null
        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null

        const isVitalAngel = type === 'myVitalAngels'
        const isMyContact = type === 'myContacts'
        const isPendinAccept = type === 'pendingAccept'
        const isRequest = type === 'requests'

        return (
            <div className="main-menu-vista-friend" >
                {previewFlotingDialog}
                {MenuShowMenuOptions}
                { isVitalAngel || isMyContact ? 
                    <div className="menu-vista-friend-btns">
                        {userData.myVitalangel ? <div className="menu-vista-friend-btns-vital"><img src={Icons.Diamant_2}/></div> : <div className="menu-vista-friend-btns-vital"/>}
                        <div className="menu-vista-friend-btns-slide"><img src={Icons.Ellipsis}  onClick={this.showHideMenuOptns} /></div>
                    </div> :
                    <div className="menu-vista-friend-btns">
                        <div className="menu-vista-friend-btns-delete" onClick={this.deleteInvite}><FormattedMessage id="DELETE_BTN" /></div>
                    </div>
                }
                { userData.profilePicture ?
                    <img className='menu-vista-friend-img' src={userImgProfile} />:
                    <div className='menu-vista-friend-img'>
                        <div className='profile-container-picture-first-letter-name'>{this.getLeterUser(userData.email)}</div>
                    </div>
                }
                <div className="menu-vista-friend-data">
                    <div className="menu-vista-friend-name-user">{`${userData.name ? userData.name : ''} ${userData.surname ? userData.surname : ''}` }</div>
                    { isVitalAngel || isMyContact ? <label className="menu-vista-friend-name-mail">{this.getOnlyName(userData.email)}</label> : null }
                    { isPendinAccept ? <div className="menu-vista-friend-date-last-send">{this.formatDate(userData.connectionRequestDate)}</div> : null }
                    { isPendinAccept ?  <div className="menu-vista-friend-date-send" onClick={this.resendRequest}>
                                            <img src={Icons.Mail} />
                                            <FormattedMessage id="RESEND_REQUEST" />
                                        </div> : null }
                    { isRequest ? <div className="menu-vista-friend-accept-btn" onClick={this.acceptInvite}><FormattedMessage id="ACCEPT_BTN" /></div> : null }
                </div>
            </div>
		)
    }

    getOnlyName = (data) => {
        return data.split('@')[0]
    }

    formatDate = (data) => {
        const formatDate = moment(data).format('DD/MM/YYYY')
        return <div>{formatDate}</div>
    }

    setMessageDialog = (msg, typeMsg) => {
        this.setState({
            showDialog: true, 
            msgError: msg, //error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error',
            typeMessage: typeMsg //'error' or 'ok'
        }, ()=>Utils.clearError(this))
    }

    showHideMenuOptns = () => {
        this.setState({
            showMenuOptions: !this.state.showMenuOptions
        })
    }
    closeMenuOptns = () => {
        this.setState({
            showMenuOptions: false
        })
    }
    
    getLeterUser = (data) => {
        return data[0].toUpperCase()
    }

    getInfo = () => {
        Service.getImageProfileImageOfMyConnection(/*id*/)
    }

    resendRequest = () => {
        const { userData, updateUsers } = this.props
        
        const request = {
            email: userData.email,
            language: "ES",
            name: userData.name,
            surname: userData.surname
        }
        Service.sendEmailToInvite(request)
            .then(response => {
                const msgOk = 'Invitacion enviada'
                this.setMessageDialog(msgOk, 'ok')
                updateUsers()
            })
            .catch(error => {
                this.setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                console.error('SEND_EMAIL_TO_INVITE_ERROR ->', error)
            })
    }

    acceptInvite = () => {
        const { userData, updateUsers } = this.props
        const request = {
            id: userData.id,
            status: "ACCEPTED"
        }
        Service.acceptRequestInvite(request)
            .then(response => {
                updateUsers()
            })
            .catch(error=> {
                this.setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                console.error("ACCEPT_REQUEST_INVITE_ERROR -> ", error)
            })
    }
    
    deleteInvite = () => {
        const { userData, type, updateUsers } = this.props
        
        if (userData.anInvitation) {
            Service.deleteRequestInvite(userData.id)
                .then(response => {
                    updateUsers()
                })
                .catch(error=> {
                    this.setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                    console.error("DELETE_REQUEST_INVITE_ERROR -> ", error)
                })
        } else {
            Service.deleteConnectionRequest(userData.id)
                .then(response => {
                    updateUsers()
                })
                .catch(error=> {
                    this.setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                    console.error("DELETE_CONNECTION_REQUEST_ERROR -> ", error)
                })
        }
        if(type === 'myContacts') {
            Service.deleteContact(userData.id)
                .then(response => {
                    updateUsers()
                })
                .catch(error=> {
                    this.setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                    console.error("DELETE_CONTACT_ERROR -> ", error)
                })
        }
    }
}
