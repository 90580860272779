import React, { Component } from 'react';
import * as Services from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import DocumentPreviewMiniature from './DocumentPreviewMiniature'
import SlideMenuSection from './SlideMenuSection'


export default class SectionDocs extends Component {

	state = {
        showMenuSection: null,
        showSectionContent: null,
        documentsSection: this.props.documents.filter(k => k.tags[0].name===this.props.itemTag.name)
    };

	render() {
        const { showMenuSection, showSectionContent, documentsSection } = this.state
        const { lang, title, itemTag, reloadInfo, showFlotingMsg } = this.props

        return (
            <div className={`main-section`}>
                <div className='section-header'>
                    <div className='section-header-title' onClick={this.dropDownSection} >{`${itemTag.name} (${(documentsSection && (documentsSection.length > 0)?documentsSection.length:0)})`}</div>
                    <div className='section-header-img'>
                        <img className={`section-header-img-arrow ${showSectionContent ? 'section-header-img-arrow-show':''}`} src={Icons.ShortArrowDown} onClick={this.dropDownSection}/>
                        <img className='section-header-img-elipsis' src={Icons.EllipsisBlue} onClick={this.showHideMenuSection}/>
                    </div>
                    <div className={`section-slide-menu ${showMenuSection ? 'section-slide-menu-visible': ''}`}>
                        <SlideMenuSection closeMenuSection={this.closeMenuSection} lang={lang} id={itemTag.id} reloadInfo={reloadInfo} showFlotingMsg={showFlotingMsg} />
                    </div>
                </div>
                <div className={`section-content ${showSectionContent ? 'section-content-show':''}`}>
                    {showSectionContent ?
                        documentsSection && (documentsSection.length > 0) && documentsSection.map(itemDoc => {
                                if (itemDoc.tags[0].name === itemTag.name) {
                                    return (
                                        <div key={`preview-${title}-${itemDoc.id}`}>
                                            <DocumentPreviewMiniature title={title} lang={lang} elementsDoc={itemDoc} reloadInfo={reloadInfo} showFlotingMsg={showFlotingMsg} />
                                        </div>
                                    )
                                }
                        })
                        : 
                        null
                    }
                </div>
            </div>
        )
    }

    closeMenuSection = () => {
        this.setState({showMenuSection: false})
    }
    showHideMenuSection = () => {
        this.setState({showMenuSection: !this.state.showMenuSection})
    }
    dropDownSection = () => {
        this.setState({showSectionContent: !this.state.showSectionContent})
    }

}
