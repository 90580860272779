import React, { Component } from 'react';
import * as Services from '../../Utilities/Services'
import Tabs from '../../components/Tabs'
import Testamento from './Testamento'
import Voluntades from './Voluntades'
import Otros from './Otros'
import Seguros from './Seguros'
import MedicationPlan from './MedicationPlan'
import { injectIntl } from 'react-intl';

class DocumentsTabs extends Component {

    componentWillMount() {
        this.getInfo()
    }
   
	state = {
        testamentos: null,
        voluntades: null,
        documents: null,
        insurance: null,
        medicaton: null
	};

	render() {
        const { testamentos, voluntades, documents, allTags, insurance, medicaton } = this.state
        const { showFlotingMsg, showLoaderSpinner, intl } = this.props
        
        return (
            <Tabs>
                <Testamento title={intl.formatMessage({id:'TAB_TESTAMENT'})} 
                            testamentos={testamentos} 
                            idTag={allTags && allTags.find(k => k.name.toUpperCase() === "TESTAMENTO")}
                            reloadInfo={()=>this.getInfo()}
                            showFlotingMsg={showFlotingMsg} 
                            openShowLoader={showLoaderSpinner}
                />
                <Voluntades title={intl.formatMessage({id:'TAB_WILL'})}
                            voluntades={voluntades}
                            idTag={allTags && allTags.find(k => k.name.toUpperCase() === "VOLUNTADES")}
                            reloadInfo={()=>this.getInfo()}
                            showFlotingMsg={showFlotingMsg}
                            openShowLoader={showLoaderSpinner}
                />
                <Seguros title={intl.formatMessage({id:'TAB_INSURANCE'})}
                         insurance={insurance}
                         idTag={allTags && allTags.find(k => k.name.toUpperCase() === "SEGUROS")}
                         reloadInfo={()=>this.getInfo()}
                         showFlotingMsg={showFlotingMsg} 
                         openShowLoader={showLoaderSpinner}
                />
                <MedicationPlan title={intl.formatMessage({id:'TAB_PLAN_MEDICACION'})}
                                medicaton={medicaton}
                                idTag={allTags && allTags.find(k => k.name.toUpperCase() === "PLAN_MEDICACION")}
                                reloadInfo={()=>this.getInfo()}
                                showFlotingMsg={showFlotingMsg} 
                                openShowLoader={showLoaderSpinner}
                />
                <Otros title={intl.formatMessage({id:'TAB_OTHER'})}
                       documents={documents}
                       tags={allTags && allTags.filter(k => (k.name.toUpperCase() !== "TESTAMENTO" && k.name.toUpperCase() !== "VOLUNTADES" && k.name.toUpperCase() !== "SEGUROS" && k.name.toUpperCase() !== "PLAN_MEDICACION"))}
                       reloadInfo={()=>this.getInfo()}
                       showFlotingMsg={showFlotingMsg}
                       openShowLoader={showLoaderSpinner}
                />
            </Tabs>
		)
    }
    
    getInfo = () => {
        this.getAllUserTags()
        this.getAllUserDocuments()
    }
    getAllUserDocuments = () => {
        Services.getAllDocuments()
            .then(response=>{
                this.orderDocuments(response.data)
            })
            .catch(error=>{
                console.error('GET_ALL_DOCUMENTS_ERROR -> ', error)
            })
    }
    getAllUserTags = () => {
        Services.getAllMyTags()
            .then(response=>{
                this.setState({allTags: response.data})
            })
            .catch(error=>{
                console.error('GET_ALL_DOCUMENTS_ERROR -> ', error)
            })
    }
    isEmpty = (content) => {
        let element = []
        if (typeof content === "undefined") {
            return null
        } else {
            if(Array.isArray(content)) {
                return element = content
            } else {
                element.push(content)
                return element
            }
        }
    }
    orderDocuments = (allDocs) => {
        const allTestaments = this.isEmpty(allDocs.filter(k => k.tags[0].name.toUpperCase() === "TESTAMENTO"))
        const allWills = this.isEmpty(allDocs.filter(k => k.tags[0].name.toUpperCase() === "VOLUNTADES"))
        const allInsurance = this.isEmpty(allDocs.filter(k => k.tags[0].name.toUpperCase() === "SEGUROS"))
        const allMedicaton = this.isEmpty(allDocs.filter(k => k.tags[0].name.toUpperCase() === "PLAN_MEDICACION"))
        const allOtherDocs = this.isEmpty(allDocs.filter(k => (
            k.tags[0].name.toUpperCase() !== "TESTAMENTO" && 
            k.tags[0].name.toUpperCase() !== "VOLUNTADES" &&
            k.tags[0].name.toUpperCase() !== "SEGUROS" &&
            k.tags[0].name.toUpperCase() !== "PLAN_MEDICACION")))

        this.setState({
            testamentos: allTestaments,
            voluntades: allWills,
            insurance: allInsurance,
            documents: allOtherDocs,
            medicaton: allMedicaton
        })
    }
}
    
export default injectIntl(DocumentsTabs)