import React, { Component } from 'react';


export default class upload extends Component {

  componentWillMount(){
    
  }

  render() {
    
    const protocol = 'https'
    const url = 'vitalbook.s3-eu-west-1.amazonaws.com/index.html'
    const userId = ''
    
    return (
        <div className='main-family-tree'>
            <iframe src={`${protocol}://${url}`} />
        </div>
    );
  }

}
