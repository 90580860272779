import React, { Component } from 'react';
import Dialog from './GenericDialog'
import FlotingDialog from '../Dialogs/FlotingDialog'
import CustomInput from '../components/CustomInput'
import * as Utils from '../Utilities/Utils'
import * as Services from '../Utilities/Services'


export default class DialogNewColection extends Component {

    NEW_COLECTION = 'crea una nueva coleccion'
    WHAT_COL_NAME = '¿Cómo la vas a llamar?'
    COL_PH = 'Escribe aqui su nombre'

    state = {
        showDialog: null,
        msgError: null,
        typeMessage: null,
        nameAlbumInput: null
    }

	render() {
        const { open, close } = this.props
        const { showDialog, msgError, typeMessage, nameAlbumInput } = this.state 
        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null
       
		return (
			<Dialog open={open} close={close} accept={this.accept} cross={true} customStyles='main-dialog-create-new-collection'>
                {previewFlotingDialog}
                <div className="create-new-collection-title">
					<div>{this.NEW_COLECTION}</div>
					<label className="dialog-list-new-album-sub-title">{this.WHAT_NAME}</label>
					<CustomInput addClassName={`home-content-login-input ${nameAlbumInput ? '': 'home-content-login-input-empty'} ${showDialog ? `custom-input-element-error` :''}`} 
								 placeholder={this.COL_PH} height='5vh' methodToRun={(data) => this.setState({nameAlbumInput: data})} />
				</div>
			</Dialog>
		)
    }

    accept = () => {
        const { nameAlbumInput } = this.state 
        this.createNewColection(nameAlbumInput)
    }

    createNewColection = (nameColection) => {
        const { owner, close, getAllCollections } = this.props
        const { nameAlbumInput } = this.state 
        const collectionRequest = {
            name: nameColection,
            owner: {
                id: owner.id
            }
        }
        if( nameColection === '' || nameColection === null ){ 
            this.setState({
                msgError: `hay que rellentar el/los campos de ${nameAlbumInput ? '' : 'album' }`, 
                typeMessage: 'error' , showDialog: true
            }, Utils.clearError(this))
        } else {
            Services.createNewCollection(collectionRequest)
                .then(response => {
                    getAllCollections()
                    close()
                })
                .catch(error => {
                    console.error('DELETE_REPO_ITEM_ERROR -> ', error)
                })
        }
    }

}
