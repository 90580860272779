import React, { Component } from 'react';
import * as Service from '../../Utilities/Services'
import * as Utils from '../../Utilities/Utils'
import * as Imgs from '../../Utilities/ImgConfig'
import MenuVistaFriend from './MenuVistaFriend/MenuVistaFriend'
import { FormattedMessage } from 'react-intl';

export default class UserVitalAngels extends Component {

	state = {
		myVitalAngels: null
	};

	render() {
		const { myVitalAngels, lang } = this.props

		return (
			<div className='main-user-vitalangels'>
				<div className='user-vitalangels-header'>
					<div className='user-vitalangels-header-title'><FormattedMessage id="TITLE_VITALANGEL" /></div>
					<img src={Imgs.Info} />
				</div>
				{ (myVitalAngels && myVitalAngels.length > 0) ?
					<div className='user-vitalangels-friends'>
						{myVitalAngels && myVitalAngels.map((item, k)=>{
							return (
								<div className='box-user-firend' key={`item-user-friend_${k}`}>
									<MenuVistaFriend type="myVitalAngels" userData={item} lang={lang}/>
								</div>
							)
						})}
					</div> :
					<div className='user-vitalangels-friends-empty'><FormattedMessage id="EMPTY_VITALANGELS" /></div>
				}

				{(myVitalAngels && myVitalAngels.length <= 0) && <div className='user-vitalangels-help'><FormattedMessage id="DESC_MAKE_VITAL_ANGEL" /></div>}
			</div>
		)
	}

}
	