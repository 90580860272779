import React, { Component } from 'react';

export default class LoadImgLocal extends Component {
	
	state = {
		imgSelected: []
	}

	render() {
		const { id, customClassName, multiple, nameInput, icon } = this.props

		return (
				<div className={customClassName ? customClassName : "upp-image-btn-local"}>
					{icon ? <img className="profile-container-button-icons-local" src={icon} /> : null}
					<label htmlFor={`input-${id}`} className="custom-input-text">{nameInput}</label>
					<input id={`input-${id}`} className="custom-input-none" data-multiple-caption="{count} files selected" type="file" multiple={multiple} onChange={this.loadFile} />
				</div>
			);
	}

	loadFile = (elem) => {
		const file = elem.target.files
    	this.props.getLocalImg(file);
	}
}
