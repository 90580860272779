import React, { Component } from 'react';
import DraggableSpace from '../DraggableSpace'
import * as Utils from '../../Utilities/Utils'

export default class Template7 extends Component {
  
    render() {
      const { items, page, pagePosition, stateGemerics, methodToRun, reloadActualPages, isPreview } = this.props
      const ordedElements = Utils.orderArrayByParam(this.props.page.elements)

      return(
        <div key={`template-7-${pagePosition}-id_${page.id}`} className="main-template-horizontal">
          <DraggableSpace stateGemerics={stateGemerics} 
                          items={(ordedElements[0].pictureElement.repositoryItemId ? [ordedElements[0].pictureElement] : null)}
                          page={page}
                          pagePosition={`${pagePosition}-${ordedElements[0].id}`}
                          methodToRun={methodToRun}
                          reloadActualPages={reloadActualPages}
                          isPreview={isPreview}
                          editSize={{height:400,width:153}}
                          classNamesEmpty={'dropContxt-empty-img-template7-full'}
            />
            <div className='template-7-img-img'>
              <div style={{margin:' 0 0 0.75vh 0'}}>
                <DraggableSpace stateGemerics={stateGemerics}
                                sizeImg={'100%'}
                                items={(ordedElements[1].pictureElement.repositoryItemId ? [ordedElements[1].pictureElement] : null)}
                                page={page}
                                pagePosition={`${pagePosition}-${ordedElements[1].id}`}
                                methodToRun={methodToRun}
                                reloadActualPages={reloadActualPages}
                                isPreview={isPreview}
                                editSize={{height:400,width:300}}
                                classNamesEmpty={'dropContxt-empty-img-template7-half'}
                />
              </div>
              <div>
                <DraggableSpace stateGemerics={stateGemerics}
                                sizeImg={'100%'}
                                items={(ordedElements[2].pictureElement.repositoryItemId ? [ordedElements[2].pictureElement] : null)}
                                page={page}
                                pagePosition={`${pagePosition}-${ordedElements[2].id}`}
                                methodToRun={methodToRun}
                                reloadActualPages={reloadActualPages}
                                isPreview={isPreview}
                                editSize={{height:400,width:300}}
                                classNamesEmpty={'dropContxt-empty-img-template7-half'}
                />
              </div>
            </div>
        </div>
      );
    }
}
