import React, { Component } from 'react';


export default class HomeFamilyTree extends Component {

  componentWillMount(){
    
  }

  render() {
    
    const protocol = 'https'
    const url = 'arbol.vitalbook.me'
    const userId = ''
    
    return (
        <div className='main-family-tree'>
            <iframe src={`${protocol}://${url}/users/${this.props.router.history.location.state.idUser}`} referrerPolicy="unsafe-url"  />
        </div>
    );
  }

}
