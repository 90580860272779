import React, { Component } from 'react';
import Sidebar from "react-sidebar";
import Faqs from '../UserApplications/Faqs';
import { FormattedMessage } from 'react-intl';

const sidebarStyles = {
    sidebar: {
      background: '#ffffff',
      width: '40vw',
      overflowY: 'none'
    },
}

export default class FaqSidebar extends Component {
  
  state = {
    sidebarOpen: false
  }

  render() {
    const { isAlbum } = this.props
    const { sidebarOpen } = this.state

    return(
      <div className={`help-btn ${isAlbum ? `header-menu-help-btn-album` : `header-menu-help-btn`}`} >
        <Sidebar
          sidebar={<Faqs/>}
          pullRight={true}
          open={sidebarOpen}
          onSetOpen={()=>this.showHideFaqs(!sidebarOpen)}
          styles={sidebarStyles}
          children={<div/>}
        />
        <button onClick={this.showHideFaqs}><FormattedMessage id="HELP_BTN" /></button>
      </div>
    );
  }

  showHideFaqs = (open) => {
    this.setState({ sidebarOpen: open });
  }

}
