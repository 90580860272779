import React, { Component } from 'react';
import * as Icons from '../Utilities/ImgConfig'


export default class FlotingDialog extends Component {

	render() {
        const { open, close, accept, msg, type } = this.props
		
		return (
			<div className={`main-floting-dialog ${msg ? 'slideDown' : ''} ${type === 'error' ? 'error-dialog' : 'ok-dialog'}`} >
                <img src={type === 'error' ? Icons.Danger : Icons.Verified}></img>
                <div>{msg}</div>
			</div>
		)
	}
}
	