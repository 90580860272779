import React from 'react';
import '../styles/App.css';
import { FormattedMessage } from 'react-intl';
import * as Image from '../Utilities/ImgConfig';

class KoBuy extends React.Component {

  render() {
    return (
      <div className="ko-redirect-page" >
        <h4>
          <FormattedMessage id="REDIRECT_PAGE_KO" />
        </h4>
        <img src={Image.Close}></img>
      </div>
    );
  }
}

export default KoBuy;
