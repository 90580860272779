import React, { Component } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import * as Icon from '../Utilities/ImgConfig'
import * as Service from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import MyEditorImage from '../components/MyEditorImage'

const getListStyle = (sizeImg) => ({
   background: `url(${Icon.Image}) center center / 100% 10% no-repeat rgb(171, 174, 193)`,
   height: sizeImg ? '' : 'inherit',
   minHeight: sizeImg ? sizeImg : '',
   display: 'flex',
   flex: 1
});

const getItemStyle = (sizeImg) => ({
   background: 'rgba(151, 151, 151, 0)', 
   flex: 1, 
   height: `${sizeImg ? sizeImg : 'inherit'}`, 
   minHeight: `${sizeImg ? sizeImg : ''}`
})

export default class DraggableSpace extends Component {
   
   render() {
      const { items, page, pagePosition, isPreview, classNames, classNamesEmpty, sizeImg, editSize } = this.props
      const token = Utils.getToken()

      return !isPreview ? (
         <Droppable key={`droppable-main-id_${page.number}`} id={`droppable-main-id_${page.number}`}  droppableId={`droppableAlbumPage-${pagePosition}`}>
            {(provided, snapshot) => (
               items ? <div
                     key={`dropable-div-main-id_${page.number}`}
                     id={`dropContxt-${page.number}`}
                     ref={provided.innerRef}
                     style={getItemStyle(sizeImg)}
                  >
                  {items.map(item => { 
                     return (
                        <div key={`draggable-img-container-${item.id}`} className={`draggable-container ${classNames ? classNames : ''}`}>
                           <img
                              key={`id-photo-page_${page.number}-id_${item.id}`}
                              id={`id-photo-page_${page.number}-id_${item.id}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(provided.draggableProps)}
                              className={`draggable-img-container`}
                              src={item.origin==="facebook" ? item.content : Utils.getPhoto(item.repositoryItemId || item.id, token, false)}
                           />
                           <div className="menu-edit-img">
                              <MyEditorImage image={Utils.getPhoto(item.repositoryItemId || item.id, token, false)}
                                             token={token}
                                             editSize={editSize}
                                             methodToRun={this.methodToRun}
                                             pictureElementId={item.id} 
                                             repoId={item.repositoryItemId}
                              />
                           </div>
                        </div>
                     )
                  })}
                  {provided.placeholder}
               </div> : 
               <div key={`dropable-div-main-id_${page.number}`}
                    id={`dropContxt-${page.number}`}
                    ref={provided.innerRef}
                    style={getListStyle(sizeImg)}
                    className={`dropContxt-empty-img ${classNamesEmpty ? classNamesEmpty : ''}`}
               >
                  <div>{'Arrastra aqui tu imagen'}</div>
               </div>
            )}
         </Droppable>
      ): <div className="main-content-dialog-preview-element" 
              style={getItemStyle(sizeImg)}
         >
            {items && items.map(item => {
               return (
                  <img key={`id-photo-preview-${item.id}`}
                       className="main-content-dialog-preview-img"
                       src={item.origin==="facebook" ? item.content : Utils.getPhoto(item.repositoryItemId || item.id, token, false)} />
               )
            })}
         </div>
   }

   methodToRun = (base64File, idPicture ) => {
      this.props.methodToRun(base64File, idPicture);
   }

   isMyElement = () => {
      const { page } = this.props
      const selectedElement = [page.elements.find(k => k.elementType === "PICT")]
      return selectedElement;
   }

   isMyItem = () => {
      const { items } = this.props
      const selectedElement = this.isMyElement();
      const selectedImg = selectedElement.find(k => k.pictureElement.repositoryItemId === items[0].id)
      return selectedImg
   }

   removeImageFromElement = () => {
      const { reloadActualPages, stateGemerics } = this.props
      const selectedImg = this.isMyItem()

      const request = {
         id: selectedImg.pictureElement.id,
         repositoryItem: {
            id: 0
         }
      }

      Service.updateElementSetImg(request)
         .then(response=>{
            reloadActualPages()
         })
         .catch(error=>{
            console.error("UPDATE_ZERO_ELEMENT_ERROR -> ", error)
         })
   }
}
