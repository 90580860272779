import React, { Component } from 'react';
import * as Service from '../../Utilities/Services'
import { FormattedMessage, injectIntl } from 'react-intl';
import { transform } from '@babel/core';

class SlideMenuOptionsDocs extends Component {
 
	render() {
        const { closeMenuOptns, elementsDoc, openCloseRenameDialog } = this.props

        return (
            <div key={`menu-${elementsDoc.id}`} className="main-slide-menu-options-docs" onClick={closeMenuOptns}>
                <div className="slide-menu-options-context">
                    <div onClick={openCloseRenameDialog}><FormattedMessage id="MENU_OPTNS_DOCS_RENAME" /></div>
                    <div onClick={this.dowload}><FormattedMessage id="MENU_OPTNS_DOCS_DOWLOAD" /></div>
                    <div onClick={this.delete}><FormattedMessage id="MENU_OPTNS_DOCS_DELETE" /></div>
                </div>
            </div>
        )
    }

    delete = () => {
        const { elementsDoc, intl, reloadInfo, showFlotingMsg } = this.props

        Service.deleteDocument(elementsDoc.id)
            .then(response=>{
                reloadInfo()
                showFlotingMsg('ok', intl.formatMessage({id:'MSG_DOC_DELETED'}))
            })
            .catch(error=>{
                console.error('DELETE_DOCUMENT_ERROR -> ', error)
                showFlotingMsg('error', error)
            })
    }

    dowload = () => {
        const { elementsDoc } = this.props

        Service.dowloadDocument(elementsDoc.id)
            .then(response=>{
                this.saveByteArray(elementsDoc.filename, response.data)
            })
            .catch(error=>{console.error('DOWLOAD_DOCUMENT_ERROR -> ', error)})
    }

    saveByteArray = (reportName, byte) => {
        var blob = new Blob([byte], {type: "application/pdf"});
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        document.body.appendChild(link)
        link.click();
    };

   
     
}

export default injectIntl(SlideMenuOptionsDocs)
