import React, { Component } from 'react'
import * as Icons from './Utilities/ImgConfig'
import * as Utils from './Utilities/Utils'
import './styles/App.css'
import Login from './homeViews/Login'
import RecoveryPass from './homeViews/RecoveryPass'
import ChangePassword from './homeViews/ChangePassword'
import { FormattedMessage } from 'react-intl';

const sidebarStyles = {
   sidebar: {
      backgroundColor: 'white',
      width:"30vw",
      minWidth: "300px"
   },
   root: {
      position: "initial"
   },
   content: {
      position: "initial"
   }
}

class Home extends Component {
   
   componentWillMount() {
      Utils.getAllUrlParams(this)
      // this.goToLogin()
   }

   state = {
      contexView: <div/>,
      sidebarView: false
   }
   
  
   render() {
      const { contexView, sidebarView, lang } = this.state
      
      return (
         <div className="main-home">
            {/*
            <Sidebar
               sidebar={contexView}
               open={sidebarView}
               onSetOpen={this.hideShowSidebar}
               styles={sidebarStyles}
               children={<div/>}
            />
            */}
            <div className="home-content" style={{background: `url(${Icons.Bg_header_1}) center center / 100% 100% no-repeat`}}>
               {/* <WelcomeCont lang={lang}  nav={[this.hideShowSidebar, this.goToLogin]} /> */}
               {/* {contexView} */}
               <div className="home-content-info-content">
                  <div className="home-content-info-element-text">
                     {contexView}
                     {/*
                        <div className="home-content-info-content-welcome"><FormattedMessage id="WELCOM_VITAL" /></div>
                        <div className="home-content-info-content-title"><FormattedMessage id="TITLE_INFO" /></div>
                        <div className="home-content-info-content-info"> <FormattedMessage id="INFO_CONTENT" /></div>
                     */}
                  </div>
                  <div className="home-content-info-element-img">
                     <img src={Icons.LogoHeaderVitalbookBig}/>
                  </div>
               </div>
            </div>
         </div>
      )
   }  

   hideShowSidebar = () => {this.setState({sidebarView: !this.state.sidebarView})}

   goToLogin = (param1, userMail, confirmYourAccount, specialCode) => {
      this.setState({
         contexView: <Login pushHistory={this.props.router.history}
                            goToRecoveryPass={this.goToRecoveryPass}
                            closeSidebar={this.hideShowSidebar}
                            param1={param1}
                            userMail={userMail}
                            whereProceed={confirmYourAccount}
                            specialCode={specialCode} 
                     />
      })
   }

   goToChangePass = (param1, userMail, confirmYourAccount) => {
      this.setState({
         contexView: <ChangePassword param1={param1}
                                     userMail={userMail}
                                     closeSidebar={this.goToLogin}
                                     whereProceed={confirmYourAccount}
                     />
      })
   
   }
   goToRecoveryPass = () => {
      this.setState({
         contexView: <RecoveryPass closeSidebar={this.goToLogin} />
      })
   }
}

export default Home;
