import React, { Component } from 'react';
import * as Service from '../../Utilities/Services'
import { injectIntl, FormattedMessage} from 'react-intl'

class SlideMenuSection extends Component {

	render() {
        const { id } = this.props

        return (
            <div key={`drop-menu-section-${id}`} /*className="main-slide-menu-section"*/>
                <div className="slide-menu-section-context">
                    <div onClick={()=>null}><FormattedMessage id="MENU_SECTION_RENAME" /></div>
                    <div onClick={this.deleteSection}><FormattedMessage id="MENU_SECTION_DELETE" /></div>
                </div>
            </div>
        )
    }

    deleteSection = () => {
        const { id, reloadInfo, showFlotingMsg, intl, closeMenuSection } = this.props

        Service.deleteTag(id)
            .then(response=>{
                closeMenuSection()
                reloadInfo()
                showFlotingMsg('ok', intl.formatMessage({id:'MSG_DELETE_TAG'}))
            })
            .catch(error=>{
                console.error('DELETE_DOCUMENT_ERROR -> ', error)
                showFlotingMsg('error', error)
            })
    }

    renameSection = () => {
        const { reloadInfo, showFlotingMsg, intl } = this.props
        const request = {}

        Service.renameTag(request)
            .then(response => {
                reloadInfo()
                showFlotingMsg('ok', intl.formatMessage({id:'MSG_DELETE_TAG'}))
            })
            .catch(error => {
                console.error('DELETE_DOCUMENT_ERROR -> ', error)
                showFlotingMsg('error', error)
            })
    }
     
}

export default injectIntl(SlideMenuSection)
