import React, { Component } from 'react'
import * as Services from '../../Utilities/Services'
import * as Utils from '../../Utilities/Utils'
import { FormattedMessage, injectIntl } from 'react-intl';
import DialogBuyPrinter from '../../Dialogs/DialogBuyPrinter'

class PrinterMenu extends Component {

    state = {
        buyPrinter: null
    }

    render() {
        const { buyPrinter } = this.state
        const dialogBuyPrinter = buyPrinter ? <DialogBuyPrinter close={this.hideShowBuyPrinter}
                                                                open={buyPrinter}
                                            /> : null
        return (
            <div className="printerMenu-main">
                {dialogBuyPrinter}
                <div className="printerMenu-main-content">
                    {`Generando álbum para imprimir.`}
                    <button onClick={this.hideShowBuyPrinter}>{'comprar impression de album'}</button>
                </div>
                <div className="printerMenu-main-btn" onClick={this.hideShowBuyPrinter}>
                    <FormattedMessage id="PRINTER_MENU_BTN_NAME" />
                </div>
            </div>
        )
    }

    hideShowBuyPrinter = () => {    
        this.setState({buyPrinter: !this.state.buyPrinter})
    }
}

export default injectIntl(PrinterMenu);
