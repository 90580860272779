import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogHeader = withStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { classes, onClose } = props;
  return onClose ? (
    <IconButton
      aria-label="Close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  ) : null;
});

const Bottonera = props => {
  const { close, accept } = props;
  return (
    <div className="bottonera-dialog">
      {accept && (
        <button className="ok-button" type="button" onClick={accept}>
          {'Aceptar'}
        </button>
      )}
      {close && (
        <button className="ko-button" type="button" onClick={close}>
          {'Cerrar'}
        </button>
      )}
    </div>
  );
};

export default class GenericDialog extends Component {
  state = {};

  render() {
    const {
      children,
      close,
      open,
      accept,
      customStyles,
      cross,
      showButtons = true
    } = this.props;

    return (
      <Dialog
        className={`main-content-custom-dialog scrollbar ${
          customStyles ? customStyles : ''
        }`}
        onClose={close || cross}
        open={open}
      >
        {cross && (
          <DialogHeader id="customized-dialog-title" onClose={close || cross} />
        )}
        <div className="main-content-custom-dialog-children">{children}</div>
        {showButtons ? <Bottonera accept={accept} close={close} /> : null}
      </Dialog>
    );
  }
}
