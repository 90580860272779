import React, { Component } from 'react';
import CustomInput from '../components/CustomInput'
import * as Service from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import * as Icons from '../Utilities/ImgConfig'
import FlotingDialog from '../Dialogs/FlotingDialog'
import { FormattedMessage, injectIntl } from 'react-intl';


class RecoveryPass extends Component {
    
    state = {
        email: null,
        msgError: null,
        showDialog: false,
        typeMessage: null,
        valueUserMail: null
    }

    render() {
        const { closeSidebar, intl } = this.props
        const { showDialog, msgError, typeMessage, email } = this.state
        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage}/> : null

        return (
            <div className="home-content-register">
                {previewFlotingDialog}
                <div className='home-content-login-close' onClick = {closeSidebar} >
                    <img src={Icons.BackArrow} alt="X" />
                </div>
                <label className="home-content-login-start"><FormattedMessage id="START_VITAL" /></label>
                <div className="home-content-recover-text"><FormattedMessage id="TEXT_RECOVERY" /></div>
                <h2><FormattedMessage id="TITLE_RECOVERY" /></h2>
                <CustomInput addClassName={`home-content-login-input ${email ? '': 'home-content-login-input-empty'} ${showDialog ? `custom-input-element-error` :''}`}
                             title={'Dirección de email'}
                             placeholder={intl.formatMessage({id:'PLACE_HOLDER'})}
                             height='5vh'
                             width='-webkit-fill-available'
                             methodToRun={this.getInputData}
                />
                <button className="home-content-register-button" onClick={this.sendMailToRecovery}><FormattedMessage id="RECOVERY_BUTTON" /></button>
            </div>
        )
    }
   
    getInputData = (inputEmail) => {
        this.setState({email: inputEmail});
    }

    sendMailToRecovery = () => {
        const { intl } = this.props
        
        const request = {
            email: this.state.email,
            language: "ES"
        }

        Service.sendMailResetPassword(request)
            .then(response=>{
                this.setState({msgError: intl.formatMessage({id:'MSG_SEND'}), showDialog: true, typeMessage: 'ok'}, ()=>Utils.clearError(this))
            })
            .catch(error=>{
                console.error("Send_Mail_Reset_Password_ERROR -> ", error)
                this.setState({msgError: 'error'+ error.response.data.error, showDialog: true, typeMessage: 'error'}, ()=>Utils.clearError(this))
            })
    }
}

export default injectIntl(RecoveryPass);
