import React, { Component } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { FormattedMessage } from 'react-intl';

const  getListStyle = () => ({
    margin: 'auto',
    fontSize: '14px',
    color: '#b3b3b3'
});

export default class DraggableSpaceTemplate extends Component {
   
   render() {
      const { pagePosition, isPreview } = this.props
      return !isPreview ?
         <Droppable droppableId={`droppableAlbumPage-template-${pagePosition}`}>
            {(provided, snapshot) => (
               <div
                  id={`dropContxt-${pagePosition}`}
                  ref={provided.innerRef}
                  style={getListStyle()}>
                  {/* <div styles={`margin: auto 0xp, height: fit-content, width: 50%`}></div> */}
                  <FormattedMessage id="EMPTY_PAGE" />
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
         : 
         <div style={getListStyle(false)}>
            <div styles={`margin: auto 0xp, height: auto, width: 50%`} />
         </div>
   }
}
