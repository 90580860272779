import React, { Component } from 'react';
import * as Services from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import DocumentPreviewMiniature from './DocumentPreviewMiniature'
import { FormattedMessage, injectIntl } from 'react-intl';
import FileDrop from 'react-file-drop'


class MedicationPlan extends Component {
    
	render() {
        const { intl, medicaton, title, reloadInfo, showFlotingMsg } = this.props

        return (
            <div className="Testamento-content-type-docs-tabs-content">
                <div className="Testamento-content-type-docs-tabs-content-import">
                    <div className="Testamento-content-drag-drop-element">
                        <FileDrop onDrop={this.handleDrop}>
                            <div className="Testamento-content-type-docs-tabs-content-drop-file">
                                <img src={Icons.DragDrop}/>
                                <div><p><FormattedMessage id="DROP_FILES_HERE" /></p></div>
                                <label><FormattedMessage id="ONLY_PDF_FILES" /></label>
                            </div>
                        </FileDrop>
                    </div>
                    <div className="Testamento-content-type-docs-tabs-content-select-file">
                        <label><FormattedMessage id="TITLE_REMEMBER" /></label>
                        <div>
                            <div><FormattedMessage id="TEXT_REMEMBER_1" /></div>
                            <div><FormattedMessage id="TEXT_REMEMBER_2" /></div>
                        </div>
                        <div className='Testamento-content-type-docs-tabs-content-select-file-btn'>
                            <label htmlFor={`input-${title}`} ><FormattedMessage id="BTN_IMPORT_FILE" /></label>
                            <input id={`input-${title}`}
                                   className="custom-input-none"
                                   data-multiple-caption="{count} files selected"
                                   type="file"
                                   onChange={this.loadFile} 
                                   accept="application/pdf, application/vnd.ms-excel"
                            />
                        </div>
                    </div>
                </div>
                <div className="Testamento-content-type-docs-tabs-content-files">
                    <div className="Testamento-content-type-docs-tabs-content-file-title">{`${intl.formatMessage({id: 'TITLE_ALL_MEDICATION_PLANS'})} (${(medicaton && (medicaton.length > 0)) ? medicaton.length : 0})`}</div>
                    <div className="Testamento-content-type-docs-tabs-content-files-carrousel scrollbar">
                        {medicaton && (medicaton.length > 0) && medicaton.map(item => {
                            return (
                                <div key={`documents-vital-${title}-${item.id}`} >
                                    <DocumentPreviewMiniature title={title} elementsDoc={item} reloadInfo={reloadInfo} showFlotingMsg={showFlotingMsg} />
                                </div>
                            )

                        })}
                    </div>
                </div>
            </div>
        )
    }

    loadFile = (elem) => {
        const {openShowLoader} = this.props
        openShowLoader()
		const file = elem.target.files
        let multipart = new FormData();
        multipart.append('file', file[0]);
        this.uploadNewDocument(multipart)
    }
    handleDrop = (files, event) => {
        const {openShowLoader} = this.props
        openShowLoader()
        let multipart = new FormData();
        multipart.append('file', files[0]);
        this.uploadNewDocument(multipart)
    }
    uploadNewDocument = (formData) => {
        const { intl,  idTag, reloadInfo, showFlotingMsg, openShowLoader, title } = this.props
        let request = formData
        request.append('tagId', idTag.id);
        document.getElementById(`input-${title}`).value = "";
        Services.uploadDocument(request)
            .then(response=>{
                openShowLoader()
                reloadInfo()
                showFlotingMsg('ok', intl.formatMessage({id:"MSG_FILE_UPLOAD"}))
            })
            .catch(error=>{
                openShowLoader()
                showFlotingMsg('error', error)
            })
    }
}

export default injectIntl(MedicationPlan)
