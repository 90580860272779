import {
  DOCUMENT_FORM_UPDATE_FORM_VALUE,
  DOCUMENT_FORM_SET_STEP,
  DOCUMENT_FORM_FETCH_CONFIG,
  DOCUMENT_FORM_STORE_VALUES,
  DOCUMENT_FORM_INIT_FORM_STATE,
  DOCUMENT_FORM_REMOVE_STORE_KEY,
  DOCUMENT_FORM_REMOVE_STORE,
  DOCUMENT_FORM_FETCH_DOCUMENT
} from '../types';

const initialState = {
  title: '',
  description: '',
  stepIndex: 0,
  values: {},
  store: {},
  fields: []
};

export default (state = initialState, { type, payload = {} }) => {
  const { store } = state;
  const { md, config, values, key } = payload;

  switch (type) {
    case DOCUMENT_FORM_INIT_FORM_STATE:
      return { ...initialState, store };
    case DOCUMENT_FORM_FETCH_CONFIG:
      return {
        store,
        step: config.fields[0].id,
        stepIndex: 0,
        md: state.md,
        ...config,
        values
      };
    case DOCUMENT_FORM_FETCH_DOCUMENT:
      return {
        ...state,
        md
      };
    case DOCUMENT_FORM_UPDATE_FORM_VALUE:
      return {
        ...state,
        values: { ...state.values, ...payload }
      };
    case DOCUMENT_FORM_STORE_VALUES:
      return {
        ...state,
        store: { ...state.store, ...payload }
      };
    case DOCUMENT_FORM_REMOVE_STORE:
      return {
        ...state,
        store: {}
      };
    case DOCUMENT_FORM_REMOVE_STORE_KEY:
      delete store[key];
      return {
        ...state,
        store
      };
    case DOCUMENT_FORM_SET_STEP:
      const { step } = payload;
      const stepIndex = state.fields
        .filter(({ extension }) => !extension)
        .findIndex(({ id }) => step === id);

      return {
        ...state,
        step,
        stepIndex
      };
    default:
      return state;
  }
};
