const enviroment = "PRODUCCION"
let url = getEnvirontment();
const config = {
  API: {
    ROOT_URL: url,
    ENVIROMENT: enviroment,
    CALLS: {
      ACCESS: ``,
      ACTION: `/action_endpoint`,
      CONTENT_TYPE: `application/json;`
    }
  },
  URL_SHOP: 'https://shop.vitalbook.me'
};

function getEnvirontment() {
  switch (enviroment) {
    case 'LOCAL':
      return (url = ``);
    case 'TEST':
      return (url = ``);
    case 'PRODUCCION':
      return (url = 'https://api.vitalbook.me');
  }
}

export default config;
