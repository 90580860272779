import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import DocumentsTabs from './DocumentsTabs';
import DialogCreateDoc from '../../Dialogs/DialogCreateDoc';
import DialogLoader from '../../Dialogs/DialogLoader'
import ProgressBar from '../../components/ProgressBar';
import FlotingDialog from '../../Dialogs/FlotingDialog'
import { fetchFormConfig, fetchDocument } from '../../Redux/Actions/documentFormActions';
import { FormattedMessage, injectIntl } from 'react-intl';
import SuscriptionDialog from '../../Dialogs/SuscriptionDialog'
import * as Utils from '../../Utilities/Utils';
import * as Service from '../../Utilities/Services';

class DocumentsHome extends Component {

  documentOptionKeys = {
    TESTAMENT: 1,
    LAST_WILL: 2
  };

  componentWillMount() {
    this.getInfo();
  };
  
  state = {
    showCreateDocument: null,
    selectedDocumentTemplate: 0,
    lang: null,
    allTags: null,
    showDialog: null,
    msgError: null,
    typeMessage: null,
    showLoadDoc: false
  };

  render() {
    const {       
      selectedDocumentTemplate, 
      showCreateDocument, 
      typeMessage, 
      msgError, 
      showDialog,
      showSuscription,
      userData,
      showLoadDoc
    } = this.state;
    const { intl } = this.props

    const dialogCreateDocument = showCreateDocument ? (
      <DialogCreateDoc
        open={showCreateDocument}
        close={this.hideDialogTestamentPreviousQuestions}
      />
    ) : null;
    const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null
    const dialogSuscription = showSuscription ? <SuscriptionDialog open={showSuscription} cross={this.showHideSuscription} userData={userData} /> : null
    const dialogLoadDoc = showLoadDoc ? <DialogLoader open={showLoadDoc} close={this.showLoaderSpinner} accept={false} cross={false}></DialogLoader> : null

    return (
      <div className='main-DocumentsHome'>
        {previewFlotingDialog}
        {dialogCreateDocument}
        {dialogSuscription}
        {dialogLoadDoc}
        <h3><FormattedMessage id="TITLE_DOCUMENTS" /></h3>
        <div className="DocumentsHome-content">
          <DocumentsTabs showFlotingMsg={this.showFlotingMsg} showLoaderSpinner={this.showLoaderSpinner}/>
          <div className="DocumentsHome-content-template-info">
            <div className="DocumentsHome-content-template">
              <h3><FormattedMessage id="TITLE_OUR_TEMPLATES" /></h3>
              <label><FormattedMessage id="DESCRIPTION_SELECT" /></label>
              <select value={selectedDocumentTemplate} onChange={e => this.setState({selectedDocumentTemplate: parseInt(e.target.value)})}>
                {[{label: 'SELECT_TEMPLATE', value: 0},
                  { label: 'SELECT_TEMPLATE_TESTAMENT', value: this.documentOptionKeys.TESTAMENT }, //NO BORRAR
                  { label: 'SELECT_TEMPLATE_LAST_WILL', value: this.documentOptionKeys.LAST_WILL }
                ].map(({ label, value }) => (
                  <option {...{ value }}>
                    {intl.formatMessage({ id: label })}
                  </option>
                ))}
              </ select>
              <button
                onClick={this.startCreateDocument}
                disabled={!selectedDocumentTemplate}
              >
                <FormattedMessage id="BTN_CREATE_DOC" />
              </button>
            </div>
            <div className="DocumentsHome-content-info">
              <div>
                <h4><FormattedMessage id="TEXT_SPACE_USED" /></h4>
                <ProgressBar maxBar={true} />
                <div className='DocumentsHome-content-info-text'><FormattedMessage id="TEXT_SPACE_USED_INFO" /></div>
                <button className='profile-container-sidebar-btn-renew' onClick={this.showHideSuscription} >
                  <FormattedMessage id="BTN_NAME_RENOVE" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  startCreateDocument = async () => {
    switch (this.state.selectedDocumentTemplate) {
      case 1:
        this.showDialogTestamentPreviousQuestions();
        break;
      case 2:
        await this.loadFillableDocument('last_will');
        break;
      default:
        return;
    }
  };
  loadFillableDocument = async id => {
    const { router, fetchFormConfig, fetchDocument } = this.props;

    await fetchDocument(`${id}.md`);
    fetchFormConfig(id);

    router.history.push('/fillabledocument');
  };
  showDialogTestamentPreviousQuestions = () => {
    this.props.fetchFormConfig('previous');
    this.setState({ showCreateDocument: true });
  };
  hideDialogTestamentPreviousQuestions = () => {
    this.setState({ showCreateDocument: false });
  }
  getInfo() {
    let userData = {};
    Service.getInfoLoggedUser(localStorage.getItem('userMail'))
        .then((response)=>{
            userData = { ...response.data.content[0] };
            return Service.getUserStorageSummary(userData.id);
        })
        .then(response => {
            this.updateStateUserData({...userData, ...response.data})
        })
        .catch((error)=>{
            console.error("GET_INFO_USER_DATA_ERROR -> ", error)
        })
  }

  updateStateUserData = (response) => {
    let date = new Date(response.birthday && response.birthday.substr(0,10));
    let userInfo = {
        'cardIdentifier': response.cardIdentifier,
        'credit': response.credit,
        'name': response.name,
        'surname': response.surname,
        'birthday': date,
        'email': response.email,
        'id': response.id,
        'numAlbums': response.numAlbums || '0',
        'numContacts': response.numContacts || '0',
        'numVitalangels': response.numVitalangels || '0',
        'usedStorage': response.usedStorage || 0,
        'maxStorageAllowed': response.maxStorageAllowed || 0,
        'totalUsedStorage': response.totalUsedStorage || 0,
        'totalUnusedFilesSize': response.totalUnusedFilesSize || 0,
        'currentSubscription': response.currentSubscription || { plan: {} }
    };
    this.setState({userData : userInfo});
  }

  showHideSuscription = () => {
    if (Utils.userAcceptPolicyRules())
        this.setState({showSuscription: !this.state.showSuscription})
    else
        this.props.openPolicyRules()
  }

  showLoaderSpinner = () => {
    this.setState({showLoadDoc: !this.state.showLoadDoc})
  }

  showFlotingMsg = (typeMessage, error) => {
    this.setState({
      showDialog: true, 
      msgError: typeMessage === 'ok' ? error : error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 
      typeMessage: typeMessage
    }, 
      ()=>Utils.clearError(this))
  }
}

export default injectIntl(
  withRouter(
    connect(
      ({ documentForm: { id } }) => ({ id }),
      { fetchFormConfig, fetchDocument }
    )(DocumentsHome)
  )
);
