import React, { Component } from 'react';
import Datepicker from '../components/Datepicker';

const lightStyle = {
  border: '1px solid black',
  outlineStyle: 'solid',
  outlineColor: '#1860B2',
  outlineWidth: 'thin'
}
export default class CustomInputSidebar extends Component {

  constructor(props) {
      super(props);
      this.state = {
        isDisabled : true,
        open: false,
         ...props
      }
  }
  componentDidUpdate(prevProps) {
    if (this.props.inputValue !== prevProps.inputValue) {
      this.setState({inputValue:this.props.inputValue});
    }
  }
  render() {
    const { placeHolder, open } = this.state
    const { isEditable, styles } = this.props

    return(
      <div className={isEditable ?  'profile-container-custom-input-editable' : 'profile-container-custom-input'}  style = {this.state.isDisabled ? {} : lightStyle} >
        {
          this.props.type == 'date' ? 
            <div className= 'profile-container-datepicker' onClick={(isEditable && !open) ? ()=>this.openDatePicker(true) : ()=>null}>
              <Datepicker 
                openDatePicker={this.openDatePicker}
                className='profile-container-datepicker-inputDate' 
                startDate={this.state.inputValue}
                disabled={!isEditable}
                open={open}
                blurFunctionDate={this.blurFunctionDate}
              />
            </div>
          :
            <input onBlur={this.blurFunction}
              style={styles}
              className = 'profile-container-custom-input-element'
              placeholder={placeHolder}
              value={this.state.inputValue || ''}
              onChange={evt => this.updateInputValue(evt)}
              disabled={!isEditable}
            />
        }
      </div>
    );
  }

  openDatePicker = (hideShow) => {
    this.setState({open: hideShow})
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value
    });
  }
  blurFunction = () => {
    this.props.methodToRun(this.props.name, this.state.inputValue);
    this.setState({
      isDisabled: true
    });
  } 

  blurFunctionDate = (date) => {
    this.props.methodToRun(this.props.name, date);
    this.setState({
      open:false,
      isDisabled: true
    });
  }
}
