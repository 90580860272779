import React, { Component } from 'react';
import * as Services from '../Utilities/Services'

const totalUsedStorageColor = '#7e187a';
const albumsUsedStorageColor = '#1f285a';
const units = 'MB';

class ProgressBar extends Component {

    componentWillMount() {
        this.getInfo()
        if(this.props.maxBar) {
            this.setState({bars:
                [
                    {
                        color: totalUsedStorageColor,
                        progress: 0
                    }
                ]
            })
        } else {
            this.setState({bars: 
                [
                    {
                        color: totalUsedStorageColor,
                        progress: 0
                    },{
                        color: albumsUsedStorageColor,
                        progress: 0
                    }
                ]
            })
        }
    }

    state = {
        showTotal: true,
        maxStorageAllowed: 0,
        bars: null
    }
    
    render(){
        const { showTotal, maxStorageAllowed, bars } = this.state

        return (
            <div className="size-progress-bar">
                {bars
                    .sort((a, b) => b.progress - a.progress)
                    .map(({ color, progress }, index) => (
                        <div
                            key={color}
                            style={{
                                backgroundColor: color,
                                width: `${(progress / maxStorageAllowed) * 100}%`
                            }}
                            className={`size-progress-bar-${index + 1}`}
                        >
                            <span
                                style={{
                                    color,
                                    [index % 2 === 0 ? 'top' : 'bottom']: '-12px'
                                }}
                            >
                                {`${progress.toFixed(2)}${units}`}
                            </span>
                        </div>
                    ))}
                {showTotal ? <span>{`${maxStorageAllowed}${units}`}</span> : null}
            </div>
        )
    }

    getInfo = () => {
        this.getUserDataSumary()
    }

    getUserDataSumary = () => {
        let userData = {};
        Services.getInfoLoggedUser(localStorage.getItem('userMail'))
            .then((response)=>{
                userData = { ...response.data.content[0] };

                return Services.getUserStorageSummary(userData.id);
            })
            .then(response => {
                this.updateStateUserData({...userData, ...response.data})
            })
            .catch((error)=>{
                console.error("GET_INFO_USER_DATA_ERROR -> ", error)
        })
    }

    updateStateUserData = (response) => {

        if(this.props.maxBar) {
            this.setState({bars:
                [
                    {
                        color: totalUsedStorageColor,
                        progress: response.totalUsedStorage || 0
                    }
                ],
                maxStorageAllowed: response.maxStorageAllowed || 0
            })
        } else {
            this.setState({
                bars: [
                    {
                        color: totalUsedStorageColor,
                        progress: response.totalUsedStorage || 0
                    },
                    {
                        color: albumsUsedStorageColor,
                        progress: (response.totalUsedStorage || 0) - (response.totalUnusedFilesSize || 0)
                    }
                ],
                maxStorageAllowed: response.maxStorageAllowed || 0
            })
        }
    }
}

export default ProgressBar
