import React, { Component } from 'react';
import AppOption from '../components/AppOption';
import * as Icons from '../Utilities/ImgConfig';
import { injectIntl } from 'react-intl';


class ApplicationList extends Component {

  render() {
    const { albumList, intl, allUserInfo } = this.props
    const isFree = allUserInfo?.currentSubscription?.plan ?.name == "FREE"

    return (
      <div className = 'listapps-container-options'>
        <div className="listapps-container-options-lists">
          <AppOption functClick={albumList} title={intl.formatMessage({id: 'APLICATION_LIST_TITLE_VITALBOOK'})} icon = {Icons.Llibre_1} text={intl.formatMessage({id: 'TEXT_VITALBOOK'})}/>
          <AppOption functClick={()=>this.navigation('vitalangels')} title={intl.formatMessage({id: 'APLICATION_LIST_TITLE_VITALANGEL'})} icon={Icons.Diamant_2} text={intl.formatMessage({id: 'TEXT_VITALANGEL'})}/>
          <AppOption functClick={()=>this.navigation('familytree')} title={intl.formatMessage({id: 'APLICATION_LIST_TITLE_GENEALOGICAL'})} icon={Icons.Cercle} text={intl.formatMessage({id: 'TEXT_GENTREE'})} />
        </div>
        <div className="listapps-container-options-lists">

          {/* <AppOption functClick={this.goToFaqs} title={intl.formatMessage({id: 'APLICATION_LIST_TITLE_FAQS'})} icon={Icons.FAQS} text={intl.formatMessage({id: 'TEXT_FAQS'})} /> */}
        </div>
      </div>
    );
  }

  navigation = (direction) => {
    const { push } = this.props
    push(`/${direction}`, {idUser: this.props.allUserInfo.id})
  }
}

export default injectIntl(ApplicationList);