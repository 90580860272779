import React, { Component } from 'react';
import * as Services from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import SectionDocs from './SectionDocs'
import NewSection from '../../Dialogs/DialogNewSection'
import UploadDocOnSection from '../../Dialogs/DialogUploadDocOnSection'
import { injectIntl, FormattedMessage } from 'react-intl';
import FileDrop from 'react-file-drop'

class Otros extends Component {
       
	state = {
        showNewSection: null,
        sections: null,
        showUploadDocOnSection: null
    };

	render() {
        const { showNewSection, showUploadDocOnSection } = this.state
        const { title, tags, documents, reloadInfo, showFlotingMsg, openShowLoader } = this.props
        const dialogNewSecton = showNewSection ? <NewSection accept={this.createNewSection} open={showNewSection} close={this.showNewSection} reloadInfo={reloadInfo} showFlotingMsg={showFlotingMsg} /> : null
        const dialogUploadDocOnSection = showUploadDocOnSection ? <UploadDocOnSection title={title} open={showUploadDocOnSection} close={this.hideShowUploadDocOnSection} tags={tags} reloadInfo={reloadInfo} showFlotingMsg={showFlotingMsg} openShowLoader={openShowLoader}/> : null

        return (
            <div className="Otros-main">
                {dialogUploadDocOnSection}
                {dialogNewSecton}
                <div className="Testamento-content-type-docs-tabs-content-import-others">
                    <div className="Testamento-content-drag-drop-element" style={{opacity: "0.3"}}>
                        <FileDrop onDrop={()=>null}>
                            <div className="Testamento-content-type-docs-tabs-content-drop-file">
                                <img src={Icons.DragDrop}/>
                                <div><p><FormattedMessage id="DROP_FILES_HERE" /></p></div>
                                <label><FormattedMessage id="ONLY_PDF_FILES" /></label>
                            </div>
                        </FileDrop>
                    </div>
                    <div className="Testamento-content-type-docs-tabs-content-select-file">
                        <label><FormattedMessage id="TITLE_REMEMBER" /></label>
                        <div>
                            <div><FormattedMessage id="TEXT_REMEMBER_1" /></div>
                            <div><FormattedMessage id="TEXT_REMEMBER_2" /></div>
                        </div>
                        <div className='Testamento-content-type-docs-tabs-content-select-file-btn' onClick={this.hideShowUploadDocOnSection}>
                            <label><FormattedMessage id="BTN_IMPORT_FILE" /></label>
                        </div>
                    </div>
                </div>
                <div className="Testamento-content-type-docs-tabs-content-files scrollbar">
                    {
                        tags && (tags.length > 0) && tags.map(itemTag => {
                            return (
                                <div key={`sections-${title}-${itemTag.id}`}>
                                    <SectionDocs title={title} documents={documents} itemTag={itemTag} reloadInfo={reloadInfo} showFlotingMsg={showFlotingMsg} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="Testamento-content-type-add-section" onClick={this.showNewSection}>
                    <img src={Icons.AddLogo} />
                    <div><FormattedMessage id="ADD_SECTION" /></div>
                </div>
            </div>
        )
    }

    showNewSection = () => {
        this.setState({showNewSection: !this.state.showNewSection})
    }

    createNewSection = (nameSection) => {
        const { intl, reloadInfo, showFlotingMsg } = this.props
        Services.createNewTag(nameSection)
            .then(response=>{
                reloadInfo()
                showFlotingMsg('ok', intl.formatMessage({id:"MSG_SECTION_CREATED"}))
                this.setState({showNewSection: false})
            })
            .catch(error=>{
                console.error("CREATE_NEW_TAG_ERROR -> ", error)
                showFlotingMsg('error', error)
            })
    }

    hideShowUploadDocOnSection = () => {
        this.setState({showUploadDocOnSection: !this.state.showUploadDocOnSection})
    }
}

export default injectIntl(Otros)
