import React, { Component } from 'react'
import { injectIntl, FormattedMessage} from 'react-intl'
import { connect } from 'react-redux'
import * as Service from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import * as Icons from '../Utilities/ImgConfig';
import { setLocale } from '../Redux/Actions/localeActions';
import { fetchDocument } from '../Redux/Actions/documentFormActions';
import LoadImgLocal from './LoadImgLocal'
import CustomInputSidebar from './CustomInputSidebar'
import classnames from 'classnames'
import ProgressBar from '../components/ProgressBar'
import * as moment from 'moment'

const capitalizeFirst = str => {
  return( str.charAt(0).toUpperCase() + str.slice(1))
}

class ProfileInformation extends Component {

  totalUsedStorageColor = '#7e187a'
  albumsUsedStorageColor = '#1f285a'

  constructor(props) {
    super(props);
    this.state = {
      isEditable: false
    }
  }

  render() {
    const { userData, profileFilename, closeSidebar, logoutApp, lang, intl, showHideSuscription, waitingSub, removeMyWaitingSubscription } = this.props;
    const { isEditable } = this.state
    return(
      <div className='main-profile-container-sidebar'>
        <div className='profile-container-sidebar-user-data' style={{background: `url(${Icons.Bg_user}) center center / 100% 100% no-repeat, linear-gradient(134deg, #891558, #7e187a 52%, #1f285a)`}} >
          <div className='profile-container-sidebar-close' onClick={closeSidebar} >
            <img src={Icons.CloseXWhite} alt="X" />
          </div>
          <div className='profile-container-sidebar-picture-icon'>
            { profileFilename ?
              <img className='profile-container-sidebar-picture' src={Utils.getUserPhoto(Utils.getToken(), Utils.getFacebookToken())} /> : 
              <div className='profile-container-sidebar-nonpicture'>
                <img src={Icons.CombinedShape} />
              </div>
            }
            <LoadImgLocal id="upload-profile-photo" customClassName="profile-container-sidebar-picture-change" getLocalImg={this.uploadProfileImg} multiple={false} icon={Icons.PhotoCamera}/>
          </div>
          <CustomInputSidebar inputValue={userData.name || '' } name='name' placeHolder={intl.formatMessage({id:'INPUT_NAME'})} methodToRun={this.changeUserData} enableInputs={this.enableInputs} isEditable={isEditable} styles={{fontSize:'20px'}}/>
          <CustomInputSidebar inputValue={userData.birthday || '' } name='birthday' placeHolder={intl.formatMessage({id:'INPUT_BIRTH_DATE'}) } methodToRun={this.changeUserData} enableInputs={this.enableInputs} isEditable={isEditable} type='date'/>
          <CustomInputSidebar inputValue={userData.email || ''} placeHolder={intl.formatMessage({id:'INPUT_MAIL'})}  methodToRun={this.changeUserData} enableInputs={this.enableInputs} isEditable={false}/>
          <button className='profile-container-sidebar-btn-edit' onClick={this.enableInputs}>{isEditable ?<FormattedMessage id="SAVE_BTN" />:<FormattedMessage id="EDIT_BTN" />}</button>
        </div>
        <div className="profile-container-sidebar-counter-info">
            <div>
              <label className="profile-container-sidebar-counter-info-name"><FormattedMessage id="USER_ALBUMS" /></label>
              <label className="profile-container-sidebar-counter-info-data">{userData.numAlbums || '0'}</label>
            </div>
            <div>
              <label className="profile-container-sidebar-counter-info-name"><FormattedMessage id="USER_NUM_CONTACTS" /></label>
              <label className="profile-container-sidebar-counter-info-data">{userData.numContacts || '0'}</label>
            </div>
            <div>
              <label className="profile-container-sidebar-counter-info-name"><FormattedMessage id="USER_NUM_VITALANGELS" /></label>
              <label className="profile-container-sidebar-counter-info-data">{userData.numVitalangels || '0'}</label>
            </div>
        </div>
        <div className="profile-container-sidebar-user-info scrollbar">
          <div className="profile-container-fiscal">
            <div>{userData.currentSubscription && userData.currentSubscription.plan && userData.currentSubscription.plan.name !== "FREE" ? (userData.cardIdentifier ? <FormattedMessage id="USER_CREDIT_CARD" /> : <FormattedMessage id="USER_NO_CREDIT_CARD" />) : null} </div>
            <div className="profile-container-fiscal-money">{`${userData.credit ? intl.formatMessage({id:"USER_EXTRA_CREDITS"})+  userData.credit+' €' : '' }`}</div>
          </div>
          <div className="profile-container-sidebar-plan">
            <h4><FormattedMessage id="TITLE_PLAN" /></h4>
            <div className="profile-container-sidebar-plan-name">
              {userData.currentSubscription && userData.currentSubscription && userData.currentSubscription.plan && userData.currentSubscription.plan.name && capitalizeFirst(userData.currentSubscription.plan.name.toLowerCase())}
            </div>
            {waitingSub &&
              <div className="profile-container-sidebar-plan">
                <h4><FormattedMessage id="TITLE_NEXT_PLAN" /></h4>
                <div className="profile-container-sidebar-plan-name">
                  {`${this.formatDate(waitingSub.initialDate)} - ${waitingSub.plan && waitingSub.plan.name && capitalizeFirst(waitingSub.plan.name.toLowerCase())}`}
                </div>
                <button className="profile-container-sidebar-remove-next-plan" onClick={removeMyWaitingSubscription}><FormattedMessage id="BTN_REMOVE_NEXT_PLAN" /></button>
              </div>
            }
            <div className="profile-container-sidebar-size"><FormattedMessage id="USED_SIZE" /></div>
            <ProgressBar maxBar={true}/>
            <div className="profile-container-sidebar-plan-description"></div>
          </div>
          <button className='profile-container-sidebar-btn-renew' onClick={showHideSuscription}><FormattedMessage id="BTN_RENEW_PLAN" /></button>
          <div className="profile-container-sidebar-plan-separator"/>
          <div className="profile-container-sidebar-plan-language">
            <h3><FormattedMessage id="TITLE_LANG" /></h3>
            <div className="profile-container-sidebar-plan-language-buttons">
              {
                ['EN', 'ES', 'CA']
                  .map(l => 
                    <label 
                      key={l}
                      className={classnames({ selected: l.toUpperCase() === lang && lang.toUpperCase()})}
                      onClick={()=>this.changeLangUser(l)}>
                      {l.toUpperCase()}
                    </label>
                  )
              }
            </div>
          </div>
          <div className="profile-container-sidebar-plan-separator"/>
          <div className='profile-container-sidebar-btn-legal-info' onClick={this.navToLegalInfo}>
            <div><FormattedMessage id="ALL_LEGAL_INFO" /></div>
          </div>
        </div>
        <div className='profile-container-logout-btn' onClick={logoutApp}>
          <img src={Icons.LogOut} />
          <label><FormattedMessage id="LOG_OUT" /></label>
        </div>
      </div>
    );  
  }

  formatDate = (dataFile) => {
    const formatDate = moment(dataFile).format('DD/MM/YYYY')
    return formatDate
  }
 
  changeLangUser = (l) => {
    const { fileName, md } = this.props.documentForm;
    let user = {
      id: this.props.userData.id,
      language: l
    }
    this.props.setLocale(l);
    if (fileName && md) {
      this.props.fetchDocument(fileName);
    }
    this.setState({locale: l})
    Service.changeLanguage(user)
      .then(response=> {
        window.location.reload();
      })
      .catch(error=>{
        console.error("USER_CHANGE_LANGUAGE_ERROR -> ", error);
      })
  }

  enableInputs = () => {
    this.setState({isEditable: !this.state.isEditable})
  }

  navToLegalInfo = () => {
    const { push, lang } = this.props
    push('/legalinfo', {lang: lang})
  }
  /* ####### Get basic facebook info  #######*/

  changeUserData = (nameAttributeChanged, value) => {
    let user = { id: this.props.userData.id}
    user[nameAttributeChanged] = value;
    this.updateUser(user);
  }

  updateUser = (user) => {
    user.birthday = moment(user.birthday).format('YYYY-MM-DD')
    Service.updateUser(user)
        .then((response)=>{
          this.props.updateStateUserData(response.data);
        })
        .catch((error)=>{
          console.error("Update_State_User_Data_ERROR ->", error)
        })
  }

  uploadProfileImg = (data) => {
    this.uploadNewProfileImage(data);
  }

  uploadNewProfileImage = (file) => {
    const fileTemp = file[0];
    let multipart = new FormData();
    let nameUploadedFile = '';
    multipart.append('file', fileTemp);
    Service.changeProfileImage(this.props.userData.id, multipart)
      .then((response)=>{
        nameUploadedFile = response.data.profileImage;
        this.getBase64(fileTemp, (result) => {
          this.props.changeProfileImage(result, nameUploadedFile); // cambiamos la imagen del componente padre
        });
      })
      .catch((error)=>{
          console.error("Change_Profile_Image_ERROR -> ", error)
      })
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.error('Error: ', error);
    };
  }
}

export default injectIntl(connect(({/*locale,*/ documentForm}) => ({/*...locale,*/ documentForm}), { setLocale, fetchDocument })(ProfileInformation))
