import React, { Component } from 'react';
import Dialog from './GenericDialog'
import * as Imgs from '../Utilities/ImgConfig'

export default class LoadImgsPreviewDialog extends Component {

	render() {

        const { open, close, accept, data, iteratorLoadImgs, iteratorLoadImgsError, cross } = this.props
                
        let percent = 0;

        if (data.length > 0) {
            percent = ((iteratorLoadImgs / data.length) * 100);
        }

		return (
			<Dialog open={open} close={close} accept={accept} cross={cross} customStyles='main-dialog-notify-reset'>
                <label className={iteratorLoadImgsError>0 ? `load-bar-img-error` : ''}>{iteratorLoadImgs+ ' / '+ data.length}</label><br/>
                {iteratorLoadImgsError>0 ? <label className={`load-bar-img-error`}>{'error: ' + iteratorLoadImgsError}</label>: null}
                <div className="load-bar-img" style={{minWidth: '200px', marginTop: '6px'}}>
                    {data.length > 0 && (
                    <>
                        <div style={{width: percent + '%', textAlign: 'center', backgroundColor: '#dddddd' }}></div>
                        <div style={{position: 'absolute', width: '100%', left: 0, textAlign: 'center', color: '#7e187a', fontWeight: 'bold'}}>{percent}%</div>
                    </>
                    )}
                </div>
                {data.length > 0 && iteratorLoadImgs == data.length && iteratorLoadImgsError==0 && (
                    <div style={{minWidth: '200px', textAlign: 'center'}}>
                        <svg className="animated_checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="animated_checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="animated_checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                    </div>
                )}
			</Dialog>
		)
    }
    
    loadBar = (i) =>{
        const { data, iteratorLoadImgs } = this.props
        if(iteratorLoadImgs <= data.length) {
            setTimeout(() => {
            }, 100)
        }
    }

}
