import React, { Component } from 'react';
import Dialog from './GenericDialog'
import FlotingDialog from './FlotingDialog'
import ShopPolicyRules from '../components/ShopPolicyRules'
import * as Service from '../Utilities/Services'
import * as Icons from '../Utilities/ImgConfig'
import * as Utils from '../Utilities/Utils'
import Config from '../Utilities/Config'
import { FormattedMessage, injectIntl } from 'react-intl';

class DialogContentTypeSub extends Component {

    state = {
        shopRules: null,
        isAcceptShopRules: false,
        shopRules: null,
        msgError: null,
        typeMessage: null,
        showDialog: null,
        suscriptionAlert: null,
        contentSubAlert: null,
        isAnualSub: null
    }

	render() {
        const { open, cross, planSelected, intl } = this.props
        const { shopRules, isAcceptShopRules, msgError, typeMessage, showDialog, contentSubAlert, suscriptionAlert } = this.state
        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null
        const dialogShopRules = shopRules ?
                                <Dialog open={shopRules} accept={this.acceptShopRules} close={this.hideShowShopRules} cross={false} customStyles='main-dialog-notify-reset'>
                                    <ShopPolicyRules />
                                </Dialog> : null

        const dialogSuscriptionAlert = suscriptionAlert ?
                                <Dialog open={suscriptionAlert} accept={this.sendSubscription} close={this.hideAlertSub} cross={false} customStyles='main-dialog-notify-reset'>
                                    {contentSubAlert}
                                </Dialog> : null
        
		return (
            <Dialog open={open} cross={cross} customStyles='main-dialog-type-suscription'>
                {previewFlotingDialog}
                {dialogSuscriptionAlert}
                {dialogShopRules}
                <h1 className='dialog-type-suscription-title'><FormattedMessage id="TITLE_TYPE_SUB" /></h1>
                <div>
                    <div className='dialog-type-suscription-plan-selected'>{`${intl.formatMessage({id:"PLAN_SELECTED"})} ${planSelected.name}`}</div>
                    <img src={Icons.freeMonth} className="dialog-type-suscription-discound-icon" />
                </div>
                <div className='dialog-type-suscription-type-sub'>

                    <div>
                        <label><FormattedMessage id="TYPE_SUB_YEAR" /></label>
                        <div className='dialog-type-suscription-type-sub-price'>
                            <label className='dialog-type-suscription-text'><FormattedMessage id="PRICE_TEXT" /></label>
                            <label>{`${(planSelected.price).toFixed(2)} € *`}</label>
                        </div>
                        <button onClick={()=>this.acceptAlertSub(true, isAcceptShopRules)}><FormattedMessage id="BUTTON_BUY_TEXT" /></button>
                    </div>
                </div>
                <div className='dialog-type-suscription-method'>
                    <label><FormattedMessage id="METHOD_PAYMENT" /></label>
                    <img src={Icons.CreditCardIcons} />
                </div>
                <br></br>
                <label className='dialog-type-suscription-exception'><FormattedMessage id="TOTAL_GARANTE_KNOW_MORE" /></label>
                <div className="dialog-type-suscription-policy-rules">
                    <input type="checkbox" checked={isAcceptShopRules} onClick={this.checkShopRules}/>
                    <label><FormattedMessage id="ACCEPT_SHOP_POLICY_RULES" /></label><label className="dialog-type-suscription-policy-rules-link" onClick={this.hideShowShopRules}><FormattedMessage id="SHOP_POLICY_RULES" /></label>
                </div>
            </Dialog>
		)
    }

    infoAboutChangePlan = (isAnnual) => {
        const { idPlanSelected } = this.props
        
		Service.getInfoAboutChangePlan(idPlanSelected, isAnnual)
			.then((response)=>{
                this.setState({
                    contentSubAlert: response.data.information,
                    isAnualSub: isAnnual,
                    suscriptionAlert: !this.state.suscriptionAlert                   
                })
			})
			.catch((error)=>{
				console.error('get_all_plans_ERROR -> ', error)
			})
    }
    
    hideAlertSub = () => {
		this.setState({
			suscriptionAlert: !this.state.suscriptionAlert
		})
    }

    acceptAlertSub = (isAnnual, policyShopIsAccept) => {
        const { intl } = this.props

        if(policyShopIsAccept) {
            this.infoAboutChangePlan(isAnnual)
        } else {
            this.setState({
                showDialog: true,
                msgError: intl.formatMessage({id:'MSG_ACCEPT_POLICY_RULES'}),
                typeMessage: 'error'
            }, ()=>Utils.clearError(this))
        }
    }

    acceptShopRules = () => {
		this.setState({
			isAcceptShopRules: true,
			shopRules: !this.state.shopRules
		})
    }

    checkShopRules = () => {
        this.setState({isAcceptShopRules: !this.state.isAcceptShopRules})
    }
    
    hideShowShopRules = () => {
		this.setState({shopRules: !this.state.shopRules})
    }
    
    sendSubscription = () => {
        const { idPlanSelected,  cross,  } = this.props
        const { isAnualSub, isAcceptShopRules } = this.state
        
        Service.createSubscription(idPlanSelected, isAnualSub, isAcceptShopRules)
            .then(response => {
                if(response.data && response.data.paymentDetailsId) {
                    window.open(`${Config.URL_SHOP}/?orderNumber=${response.data.paymentDetailsId}`, '_blank');
                } else {
                    cross()
                }
            })
            .catch(error=>{
                this.setState({
                    showDialog: true,
                    msgError: error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error',
                    typeMessage: 'error'
                }, ()=>Utils.clearError(this))
                console.error('CREATE_SUBSCRIPTION_ERROR -> ', error)
            })
	}
}
export default injectIntl(DialogContentTypeSub)
	