
/* IMPORT IMGS */
    import AddLogoI from '../icons/AddLogo.svg'
    import AddLogoDegradedI from '../icons/AddLogoDegraded.svg'
    import AddLogoWiteI from '../icons/AddLogoWite.svg'
    import BookLogoI from '../icons/BookLogo.svg'
    import Bg_headerI from '../icons/Bg_header.jpg'
    import Bg_header_1I from '../icons/Bg_header_1.jpg'
    import Bg_header_2I from '../icons/Bg_header_2.jpg'
    import CheckCircleI from '../icons/CheckCircle.svg'
    import FacebookI from '../icons/Facebook.svg'
    import FlechaPassaPaginaI from '../icons/FlechaPassaPagina.svg'
    import HardDriveI from '../icons/HardDrive.svg'
    import HomeLogoI from '../icons/HomeLogo.svg'
    import HomeLogoGreyI from '../icons/HomeLogoGrey.svg'
    import IGLogoI from '../icons/IGLogo.svg'
    import ImportarLogoI from '../icons/ImportarLogo.svg'
    import MisFotosLogoI from '../icons/MisFotosLogo.svg'
    import SettingsI from '../icons/Settings.svg'
    import VerLibroLogoI from '../icons/VerLibroLogo.svg'
    import LogoVitalbookBigI from '../icons/LogoVitalbookBig.svg'
    import LogoVitalbookHorizI from '../icons/LogoVitalbookHoriz.svg'
    import LogoVitalbookWhiteI from '../icons/LogoVitalbookWhite.svg'
    import LogoHeaderVitalbookBigI from '../icons/LogoHeaderVitalbookBig.svg'
    import EditI from '../icons/Edit.svg'
    import EditBlueI from '../icons/EditBlue.svg'
    import ImageI from '../icons/Image.svg'
    import LogOutI from '../icons/logOut.svg'
    import CloseXGreyI from '../icons/CloseXGrey.svg'
    import CloseXWhiteI from '../icons/CloseXWhite.svg'
    import CloseXBlueI from '../icons/CloseXBlue.svg'
    import CloseXPurpleI from '../icons/CloseXPurple.svg'
    import TrashI from '../icons/Trash.svg'
    import TrashWhiteRedBgI from '../icons/TrashWhiteRedBg.svg'
    import TrashTransparentI from '../icons/TrashTransparent.svg'
    import TrashWhiteTransparentI from '../icons/TrashWhiteTransparent.svg'
    import Diamant_1I from '../icons/Diamant_1.svg'
    import Diamant_2I from '../icons/Diamant_2.svg'
    import CervellI from '../icons/Cervell.svg'
    import CercleI from '../icons/Cercle.svg'
    import ArxiusI from '../icons/Arxius.svg'
    import MissatgesI from '../icons/Missatges.svg'
    import Llibre_1I from '../icons/Llibre_1.svg'
    import FAQSI from '../icons/FAQS.svg'
    import NoTemplateI from '../icons/NoTemplate.svg'
    import PauseI from '../icons/Pause.svg'
    import PlayI from '../icons/Play.svg'
    import FullScreenI from '../icons/FullScreen.svg'
    import SpeakerLowI from '../icons/SpeakerLow.svg'
    import SpeakerUpI from '../icons/SpeakerUp.svg'
    import SpeakerMaxI from '../icons/SpeakerMax.svg'
    import DangerI from '../icons/Danger.svg'
    import VerifiedI from '../icons/Verified.svg'
    import GoogleLogI from '../icons/GoogleLog.svg'
    import FacebookLogI from '../icons/FacebookLog.svg'
    import ShortArrowDownI from '../icons/ShortArrowDown.svg'
    import ShortArrowDownWhiteI from '../icons/ShortArrowDownWhite.svg'
    import CombinedShapeI from '../icons/CombinedShape.svg'
    import PrinterI from '../icons/Printer.svg'
    import Bg_userI from '../icons/Bg_user.png'
    import PhotoCameraI from '../icons/PhotoCamera.svg'
    import EllipsisI from '../icons/Ellipsis.svg'
    import EllipsisBlueI from '../icons/EllipsisBlue.svg'
    import AjustesI from '../icons/Ajustes.svg'
    import EditarI from '../icons/Editar.svg'
    import PrinterSmallI from '../icons/PrinterSmall.svg'
    import PresentacionI from '../icons/Presentacion.svg'
    import SelectArribaI from '../icons/SelectArriba.svg'
    import ImportarI from '../icons/Importar.svg'
    import MiniAddI from '../icons/MiniAdd.svg'
    import LeftArrowI from '../icons/LeftArrow.svg'
    import VideoI from '../icons/Video.svg'
    import BorrarBlancoI from '../icons/BorrarBlanco.svg'
    import EyeI from '../icons/Eye.svg'
    import RotateLeftButtonI from '../icons/RotateLeftButton.svg'
    import RotateRightButtonI from '../icons/RotateRightButton.svg'
    import ArrowNextI from '../icons/ArrowNext.svg'
    import ArrowBackI from '../icons/ArrowBack.svg'
    import ArrowFullWhiteI from '../icons/ArrowFullWhite.svg'
    import BluePintetBGCrossI from '../icons/BluePintetBGCross.svg'
    import EliminarPagesI from '../icons/EliminarPages.svg'
    import MailI from '../icons/Mail.svg'
    import InfoI from '../icons/Info.svg'
    import FavouriteCircularI from '../icons/FavouriteCircular.svg'
    import CreditCardIconsI from '../icons/CreditCardIcons.png'
    import DragDropI from '../icons/DragDrop.svg'
    import IconPdfI from '../icons/IconPdf.svg'
    import IconPlantillaI from '../icons/IconPlantilla.svg'
    import freeMonthI from '../icons/freeMonth.png'
    import BackArrowI from  '../icons/backArrow.svg'
    import CheckI from '../icons/Check.svg'
    import CloseI from '../icons/Close.svg'
    /* skins */
    import BlackBookI from '../icons/skins/Llibre_obert_NEGRE.png'
    import BlueBookI from '../icons/skins/Llibre_obert_BLAU.svg'
    import PurpleBookI from '../icons/skins/Llibre_obert_PURPLE.svg'
    /* videos */
    import vitalbook_1I from '../icons/bgMenu/vitalbook_1.mp4'
    /* spiner */
    import miniSpinnerI from '../icons/miniSpinner.gif'
    import spinnerI from '../icons/Spinner.gif'
/* IMPORT IMGS */

/* EXPORT IMGS */
    export const AddLogo = AddLogoI
    export const AddLogoDegraded = AddLogoDegradedI
    export const AddLogoWite = AddLogoWiteI
    export const BookLogo = BookLogoI
    export const Bg_header  = Bg_headerI
    export const Bg_header_1  = Bg_header_1I
    export const Bg_header_2  = Bg_header_2I
    export const CheckCircle = CheckCircleI
    export const Facebook = FacebookI
    export const FlechaPassaPagina = FlechaPassaPaginaI
    export const HardDrive = HardDriveI
    export const HomeLogo = HomeLogoI
    export const HomeLogoGrey = HomeLogoGreyI
    export const IGLogo = IGLogoI
    export const ImportarLogo = ImportarLogoI
    export const MisFotosLogo = MisFotosLogoI
    export const Settings = SettingsI
    export const VerLibroLogo = VerLibroLogoI
    export const LogoVitalbookBig = LogoVitalbookBigI
    export const LogoVitalbookHoriz = LogoVitalbookHorizI
    export const LogoVitalbookWhite = LogoVitalbookWhiteI
    export const LogoHeaderVitalbookBig = LogoHeaderVitalbookBigI
    export const Edit = EditI
    export const EditBlue = EditBlueI
    export const Image = ImageI
    export const LogOut = LogOutI
    export const CloseXGrey = CloseXGreyI
    export const CloseXWhite = CloseXWhiteI
    export const CloseXBlue = CloseXBlueI
    export const CloseXPurple = CloseXPurpleI
    export const Trash = TrashI
    export const TrashWhiteRedBg = TrashWhiteRedBgI
    export const TrashTransparent = TrashTransparentI
    export const TrashWhiteTransparent = TrashWhiteTransparentI
    export const Diamant_1 = Diamant_1I
    export const Diamant_2 = Diamant_2I
    export const Cervell = CervellI
    export const Cercle = CercleI
    export const Arxius = ArxiusI
    export const Missatges = MissatgesI
    export const Llibre_1 = Llibre_1I
    export const FAQS = FAQSI
    export const NoTemplate = NoTemplateI
    export const Pause = PauseI
    export const Play = PlayI
    export const FullScreen = FullScreenI
    export const SpeakerLow = SpeakerLowI
    export const SpeakerUp = SpeakerUpI
    export const SpeakerMax = SpeakerMaxI
    export const Danger = DangerI
    export const Verified = VerifiedI
    export const GoogleLog = GoogleLogI
    export const FacebookLog = FacebookLogI
    export const Printer = PrinterI
    export const ShortArrowDown = ShortArrowDownI
    export const ShortArrowDownWhite = ShortArrowDownWhiteI
    export const CombinedShape = CombinedShapeI
    export const Bg_user = Bg_userI
    export const PhotoCamera = PhotoCameraI
    export const Ellipsis = EllipsisI
    export const EllipsisBlue = EllipsisBlueI
    export const Ajustes = AjustesI 
    export const Editar = EditarI 
    export const PrinterSmall = PrinterSmallI
    export const Presentacion = PresentacionI
    export const SelectArriba = SelectArribaI
    export const Importar = ImportarI
    export const MiniAdd = MiniAddI
    export const LeftArrow = LeftArrowI
    export const Video = VideoI
    export const BorrarBlanco = BorrarBlancoI
    export const Eye = EyeI
    export const RotateLeftButton = RotateLeftButtonI
    export const RotateRightButton = RotateRightButtonI
    export const ArrowNext = ArrowNextI
    export const ArrowBack = ArrowBackI
    export const ArrowFullWhite = ArrowFullWhiteI
    export const BluePintetBGCross = BluePintetBGCrossI
    export const EliminarPages = EliminarPagesI
    export const Mail = MailI
    export const Info = InfoI
    export const FavouriteCircular = FavouriteCircularI
    export const CreditCardIcons = CreditCardIconsI
    export const DragDrop = DragDropI
    export const IconPdf = IconPdfI
    export const IconPlantilla = IconPlantillaI
    export const freeMonth = freeMonthI
    export const BackArrow = BackArrowI
    export const Check = CheckI
    export const Close = CloseI
    /* skins */
    export const BlackBook = BlackBookI
    export const BlueBook = BlueBookI
    export const PurpleBook = PurpleBookI
    /* skins */
    export const vitalbook_1 = vitalbook_1I
    /* spinner */
    export const spinner = spinnerI
    export const miniSpinner = miniSpinnerI
/* EXPORT IMGS */
