import React, { Component } from 'react'
import CustomInput from '../../components/CustomInput'
import * as Services from '../../Utilities/Services'
import * as Imgs from '../../Utilities/ImgConfig'
import * as Utils from '../../Utilities/Utils'
import Dialog from '../../Dialogs/GenericDialog'
import FlotingDialog from '../../Dialogs/FlotingDialog'
import { FormattedMessage, injectIntl } from 'react-intl';

class Settings extends Component {

    state={
        showNotifyDialog: false,
        inputContent: null,
        inputRes: '',
        inputError: false,
        inputNameAlbum: null,
        optionColectionSelected: null,
        showDialog: null,
        typeMessage: null,
        msgError: null,
        lang: null
    }

    render() {
        const { showNotifyDialog, inputError, showDialog, msgError, typeMessage } = this.state
        const { allColectionsUser, intl } = this.props
        const notifyDialog = showNotifyDialog ?
            <Dialog open={showNotifyDialog} accept={this.delVitalBook} close={this.closeDialog} cross={true} customStyles='main-dialog-notify-reset' >
                <label className="dialog-notify-reset-label-text" ><FormattedMessage id="SETTINGS_TEXT_DELETE" /></label>
                <div className="dialog-notify-reset-label-text-to-write"><FormattedMessage id="SETTINGS_TETXT_CONFIRM_DEL" /> </div>
                <CustomInput addClassName={`settings-main-input-delete ${inputError ? 'settings-main-input-error':''}`} width='15vw' height='4vh' placeholder={intl.formatMessage({id:'SETTINGS_PLACE_HOLDER_DEL'})} methodToRun={this.getImputCont}/>
            </Dialog> : null

        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null
    
        return (
            <div className="settings-main" >
                {notifyDialog}
                {previewFlotingDialog}
                <div className="settings-main-content scrollbar">
                    <CustomInput title={intl.formatMessage({id:'SETTINGS_TITLE_INPUT'})} addClassName="settings-main-input" height='4vh' placeholder={intl.formatMessage({id:'PLACE_HOLDER_INP'})} methodToRun={this.saveInput} />
                    <div className="settings-main-btn-save-name" onClick={this.changeNameAlbum}><FormattedMessage id="SETTINGS_BTN_SAVE_NAME" /></div>
                    
                    <h4><FormattedMessage id="SETTINGS_TITLE_DEL_COLECTION" /></h4>
                    <label className="settings-main-text-del-colection"><FormattedMessage id="SETTINGS_TEXT_DEL_COLECTION" /></label>
                    <select className="settings-select-colection" onClick={this.selectOption}>
                        <option key={`option-${0}`} value={'-1'}>{'N/A'}</option>
                            {allColectionsUser.map((item, k)=>{
                                return <option key={`option-${k}`} value={item.id}>{item.name}</option>
                            })}
                    </select>
                    <div className="settings-btn-del-coletion" onClick={this.deleteRepo}><FormattedMessage id="SETTINGS_BTN_DEL" /></div>

                    <h4><FormattedMessage id="SETTINGS_TITLE_RESTORE_ALBUM" /></h4>
                    <label className="settings-main-text-reset"><FormattedMessage id="SETTINGS_TEXT_RESET_ALBUM" /><b><FormattedMessage id="SETTINGS_TEXT_CONFIRM_RES" /></b></label>
                    <CustomInput addClassName="settings-main-input" height='4vh' placeholder={intl.formatMessage({id:'SETTINGS_PLACE_HOLDER_RES'})} methodToRun={this.saveInputRes} />
                    <div className="settings-main-btn-reset" onClick={this.restoreVitalBook}><FormattedMessage id="SETTINGS_BTN_ACCEPT" /></div>
                </div>
                <div className="settings-main-delete" onClick={()=>this.displayNotifyDialog()}>
                    <img src={Imgs.BorrarBlanco}/>
                    <label><FormattedMessage id="SETTINGS_DEL_VITALBOOK" /></label>
                </div>
            </div>
        )
    }

    selectOption = (data) => {
        const { value } = data.target
        this.setState({optionColectionSelected: value})
    }

    deleteRepo = () => {
        const { getAllCollections, intl } = this.props
        const { optionColectionSelected } = this.state

        if(!(optionColectionSelected === '-1' || optionColectionSelected === null)) {
            Services.eliminarColection(parseInt(optionColectionSelected))
                .then(response => {
                    getAllCollections()
                    this.setState({
                        showDialog: true,
                        msgError: intl.formatMessage({id:'SETTINGS_MSG_COL_DELETED'}),
                        typeMessage: 'ok'
                    }, ()=>Utils.clearError(this))
                })
                .catch(error => {
                    console.error('DELETE_REPO_ITEM_ERROR -> ', error)
                    this.setState({
                        showDialog: true,
                        msgError: error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 
                        typeMessage: 'error'
                    }, ()=>Utils.clearError(this))
                })
        } else {
            this.setState({
                showDialog: true,
                msgError: intl.formatMessage({id:'SETTINGS_NOT_SELECTET'}) || 'Error', 
                typeMessage: 'error'
            }, ()=>Utils.clearError(this))
        }
    }

    errorInput = () => {
        this.setState({inputError: true}, ()=>setTimeout(()=>{this.setState({inputError: false})}, 3000))
    }

    saveInput = (data) => {
        this.setState({inputNameAlbum: data})
    }
    saveInputRes = (data) => {
        this.setState({inputRes: data})
    }

    closeDialog = () => {this.displayNotifyDialog()}
    delVitalBook = () => {
        const { inputContent } = this.state
        const { token, album, back, intl } = this.props
        if(inputContent.toUpperCase() === intl.formatMessage({id:'SETTINGS_PLACE_HOLDER_DEL'}).toUpperCase()){
            Services.deleteAlbum(album.id)
                .then(response=>{
                    back()
                })
                .catch(error=>{console.error('Delete_Album_error: ', error)})
        } else {
            this.errorInput()
        }
    }

    displayNotifyDialog = () => {
        this.setState({showNotifyDialog: !this.state.showNotifyDialog})
    }
    getImputCont = (value) => {this.setState({inputContent: value})}
    restoreVitalBook = () => {
        const { inputRes } = this.state
        const { album, reloadActualPagesTest, intl } = this.props
        
        if(inputRes.toUpperCase() === intl.formatMessage({id:'SETTINGS_CONFIRM_RES'}).toUpperCase()){
            Services.restoreAlbum(album.id)
                .then(response => {
                    reloadActualPagesTest()
                })
                .catch(error=>{console.error('Restore_Album_error: ', error)})
        } else {
            this.errorInput()
        }
    }

    changeNameAlbum = () => {
        const newName = this.state.inputNameAlbum
        if(newName && (newName !== "") ) {
            this.props.updateDataAlbum(null, newName)
        }
    }
}

export default injectIntl(Settings);
