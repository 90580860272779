import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Popup from "reactjs-popup";
import * as Icons from '../Utilities/ImgConfig';

/*
  Editor de imágenes.
  Para utilizar este componente debemos pasarle a través de las props:
  token -> dado que al clicar aceptar la imagen editada se subirá al servidor automáticamente.
  image -> URL o imagen en base64
  methodToRun -> Método a ejecutar después de haber clicado aceptar. Al invocar a dicho método el editor pasará
  por parámetro la imagen editada en base64 y el nombre de la imagen que acaba de ser subida al servidor

  Ejemplo:
  <MyEditorImage token = {token}
  methodToRun={this.updateEditedPictureElement}
  image = "url or image"/>
*/

export default class MyEditorImage extends Component {
  
  TITLE_EDITOR_IMG = 'Ajustar la imagen'
  BTN_ACCEPT = 'Aceptar'
  BTN_CANCEL = 'Cancelar'
  BTN_ROTATE_RIGHT = 'Rota derecha'

  state = {
    sliderValue: 0,
    zoom: 1,
    modifiedImageURL: '',
    image: '',
    editSize: {
      width: this.props.editSize ? this.props.editSize.width : 400,
      height: this.props.editSize ? this.props.editSize.height : 400,
    },
    rotate: 0
  }

  componentDidUpdate(prevProps) {
    if (this.props.image !== prevProps.image) {
      this.setState({image:this.props.image});
    }
  }

  setEditorRef = (editor) => this.editor = editor

  render() {

    const { rotate, zoom, sliderValue, editSize } = this.state
    const { image } = this.props
    
    return(
      <Popup trigger={<img className = 'image-editor-edit' src={Icons.EditBlue} alt="edit" />} 
             modal contentStyle={{width: '40vw', height: 'auto', borderColor: '#7e187a', borderWidth: '4px', borderStyle: 'solid', borderRadius:'8px'}}>
        {close => (
          <div className="image-editor-modal">
            <div className="image-editor-header"> {this.TITLE_EDITOR_IMG} </div>
            <div className="image-editor-content">
              <AvatarEditor
                ref={this.setEditorRef}
                image={image}
                width={editSize.width}
                height={editSize.height}
                border={50}
                color={[255, 255, 255, 0.6]}
                scale={zoom}
                rotate={rotate}
                crossOrigin="anonymous"
                className="image-editor-content-canvas-img"
              />
              <div>
                <div className="image-editor-cancel-button" >
                  <img src={Icons.RotateLeftButton} onClick={()=>this.rotate(-90)}/>
                  <img src={Icons.RotateRightButton} onClick={()=>this.rotate(90)}/>
                </div>
              </div>
              <div className ='image-editor-picture-slider'>
                <img style = {{width:'20px'}} src={Icons.Image} alt="img" />
                <input
                  id="typeinp"
                  type="range"
                  min={1} max={2}
                  value={sliderValue}
                  onChange={this.handleChange}
                  step={0.01}
                  className= 'imagen-editor-slider'/>
                <img src={Icons.Image} alt="img" />
              </div>
            </div>
            <div className="bottonera-dialog">
              <button className="ok-button" onClick={() => {this.onClickSave(); this.closePopUp(close)}} >{this.BTN_ACCEPT}</button>
              <button className="ko-button" onClick={() => {this.closePopUp(close)}}>{this.BTN_CANCEL}</button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
  closePopUp = (close) => {
    this.setState({
      sliderValue: 0,
      zoom: 1,
      modifiedImageURL: '',
      image: '',
      rotate: 0
    })
    close()
  }

  rotate = (addRotation) => {
    const { rotate, editSize } = this.state
    this.setState({
      editSize: {
        width: editSize.height,
        height: editSize.width
      },
      rotate: rotate + addRotation
    })
  }

  onClickSave = () => {
    const { pictureElementId, repoId } = this.props
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      let canvas = this.editor.getImage();
      var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
      this.props.methodToRun(fullQuality, pictureElementId, repoId); // el componente padre se encarga de procesar la imagen
    }
  }

  handleChange = (event) => {
    let value = parseFloat(event.target.value);
    this.setState(
      {sliderValue: event.target.value, zoom: value}
    );
  }
}
