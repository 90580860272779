import React, { Component } from 'react';
import * as Utils from '../../Utilities/Utils'

export default class Template8 extends Component {

   render() {
      const { page, pagePosition, isPreview } = this.props
      const ordedElements = Utils.orderArrayByParam(this.props.page.elements)
      
      return(
         <div key={`template-8-${ordedElements[0].id}-${pagePosition}-id_${page.id}`} className="main-template">
            <textarea key={`template-8-${pagePosition}-text-area-id_${page.id}`} className={`template8-title-1 scrollbar ${isPreview ? 'template-preview' : ''}`}
                      defaultValue={ordedElements[0] && ordedElements[0].textElement && Utils.emptyText(ordedElements[0]).textElement.text === "Escribe aquí tu texto ..." ? "" : Utils.emptyText(ordedElements[0]).textElement.text }
                      disabled={isPreview ? true : false} maxLength={15} onBlur={(data)=>this.compileAllText(data, ordedElements[0])} placeholder="Escribe aquí tu texto ..." />
            <textarea key={`template-8-${ordedElements[1].id}-${pagePosition}-text-area-id_${page.id}`} className={`template1-text-2 scrollbar ${isPreview ? 'template-preview' : ''}`}
                      defaultValue={ordedElements[1] && ordedElements[1].textElement && Utils.emptyText(ordedElements[1]).textElement.text}
                      disabled={isPreview ? true : false} onBlur={(data)=>this.compileAllText(data, ordedElements[1])} />
         </div>
      );
   }

   compileAllText = (data, element) => {
      const { updateText } = this.props
      updateText(data, element)
   }
}
