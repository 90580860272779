import axios from 'axios';
import config from './Config'
import { getToken, clearSession, redirectToLogin } from './Utils';

axios.defaults.baseURL = config.API.ROOT_URL
axios.defaults.headers.post['Content-Type'] = config.API.CONTENT_TYPE;
axios.defaults.headers.common['Authorization'] = "";

const documentsAxios = axios.create();
documentsAxios.defaults.baseURL = 'https://api.vitalbook.me';
documentsAxios.defaults.headers.common['Content-Type'] = 'text/plain';

const secureAxios = axios.create();

secureAxios.interceptors.request.use(config => {
   const token = getToken();
   if (token) {
      config.headers.common.token = token;
   }

   return config;
});

secureAxios.interceptors.response.use(null, error => {
   const { status } = error && error.response;

   if (status !== 401) {
      throw error;
   }

   if ([
         'server.token.notfound',
         'server.session.notactive',
         'server.session.expired'
      ].includes(error.response.data.message)) {
      clearSession();
      redirectToLogin();
   }
   
});

/** REQUEST PARAMETRIZADA
*  @param token token para validar la request
*  @param defineMethod tipo de metodo de la request GET, POST, PUT, ...
*  @param requestURL sin la URL base el valor de la URL del endPoint
*  @param defineResponseType tipo de respuesta JSON, TEXT, BLOB, ...
*  @param data contenido que pueda tener segunt el tipo de request
*/

const makeCall = (defineMethod, requestURL, data, defineResponseType) => {
   return (
      axios({
         method: `${defineMethod || 'get'}`,
         url: `${config.API.ROOT_URL}${requestURL}`,
         responseType: `${defineResponseType || 'json'}`,
         headers: {
            token: getToken()
         },
         data: data
      })
   )
}

export const loginCall = ({ baseURL, data }) =>
   axios.post(`${baseURL}/auth/login`, data);

export const loginFacebook = ({ baseURL, data }) =>
   axios.post(`${baseURL}/auth/loginWithFacebook`, data);

export const logoutCall = ({ baseURL }) =>
   axios.get(`${baseURL}/auth/logout`)

export const register = ({ baseURL, data }) =>
   axios.post(`${baseURL}/registration/register`, data);

export const getTemplates = () =>
   secureAxios.get('/backend/formatPage');

export const getAllInfoTemplates = () => 
   secureAxios.get('/formatPage/getAllTemplateInfo');

export const getInfoLoggedUser = (email) =>
   secureAxios.get(`/users?q=email::${email}`)

export const uploadToBase64 = (filename) =>
   secureAxios.get(`/upload?filename=${filename}`)

export const deleteProfileImage = (filename) =>
   secureAxios.delete(`/upload?filename=${filename}`)

export const changeProfileImage = (userId, fileToUpload) =>
   secureAxios.post(`/users/${userId}/changeProfileImage`, fileToUpload, {headers: {'Content-Type': 'multipart/form-data'}})

export const updateUser = (user) =>
   secureAxios.put(`/users`, user, 'json')

export const changeLanguage = (user) =>
   secureAxios.put(`/users`, {id: user.id, languageSelected: user.language}, 'json')

export const logoutApplication = () =>
   secureAxios.get(`/auth/logout`)

export const getAlbumById = (idAlbum) => 
   secureAxios.get(`/album/${idAlbum}`)
   
export const getUserAlbums = (email) =>
   secureAxios.get(`/album?q=owner.email::${email}`)

export const createAlbum = (album ) =>
   secureAxios.post(`/album`, album, 'json')

export const updateDataAlbum = (album) =>
   secureAxios.put(`/album`, album, 'json')

export const restoreAlbum = (id) =>
   secureAxios.put(`/album/${id}/resetAlbum`)

export const deleteAlbum = (id) =>
   secureAxios.delete(`/album/${id}`)

/*Get all the pages of specific album*/
export const insertTwoPagesOnSpecificPage = (idAlbum, bookPageNumber) =>
   secureAxios.post(`/bookPage/insertBookPages?albumId=${idAlbum}&bookPageNumber=${bookPageNumber}`)

export const getAllAlbumPages = (idAlbum) =>
   secureAxios.get(`/bookPage?q=album.id:${idAlbum}`)

export const createBookPage = (bookPage) =>
   secureAxios.post(`/bookPage`, bookPage,'json')

export const updateBookPage = (bookPage) =>
   secureAxios.put(`/bookPage`, bookPage, 'json')

export const postUserRepoImgs = () => 
   secureAxios.get(`/upload`)

export const fetchFillableDocument = (name, lang) => 
   documentsAxios.get(`/documents/${lang}/${name}`)

export const createtNextTwoPages = (albumId) => 
   secureAxios.post(`/bookPage/createTheNextTwoPages?albumId=${albumId}`)

export const getNextTwoPages = (albumId, bookPageNumber) => 
   secureAxios.get(`/bookPage/getTheNextTwoPages?albumId=${albumId}&bookPageNumber=${bookPageNumber}`)

export const deleteTwoPages = (pageId) => 
   secureAxios.delete(`/bookPage/${pageId}/deleteTwoPages`)

export const setMySelectImgs = () => 
   secureAxios.set(`/repositoryItem`)

export const createElementPage = (request) => 
   secureAxios.post(`/element`, request)

export const updateElementSetImg = (request) => 
   secureAxios.put(`/pictureElement`, request)

export const insertRepositoryItems = (request) => 
   secureAxios.post(`/repositoryItem/insertSeveralRepoItems`, request)
   
export const getAllRepositoryItems = (collectionId) => 
   secureAxios.get(`/repositoryItem/getAllFromCollection/${collectionId}`)

export const getLocalRepositoryItems = (repoId) => 
   secureAxios.get(`/repositoryItem/${repoId}/getRepoItemImage`)

export const uploadLocalImgItem = (collectionId, fileToUpload) => 
   secureAxios.post(`/repositoryItem/createRepoItemFromFile/${collectionId}`, fileToUpload, 'multipart')

export const isUserRepoItem = (id) => 
   secureAxios.get(`/repositoryItem/isUsed/${id}`)

export const deleteRepoItem = (id) => 
   secureAxios.delete(`/repositoryItem/${id}`)
   
export const getImageFromId = (id) => 
   secureAxios.get(`/pictureElement?q=element.id:${id}`)

export const getAllElementsPicFromAlbum = (idAlbum) => 
   secureAxios.get(`/pictureElement?q=element.page.album.id:${idAlbum}`)   

export const getAllSkinsBook = () => 
   secureAxios.get(`/skin/getAllSkinInfo`)

export const createElementOnPage = (request) => 
   secureAxios.post(`/element/createAnEntireElement`, request)

export const updateEditedPictureElement = (pictureElementId, fileToUpload) =>
   secureAxios.put(`/pictureElement/${pictureElementId}/updateEditedPictureElement`, fileToUpload, 'multipart')

export const getTextElementById = (id) => 
   secureAxios.get(`/textElement?q=element.id:${id}`)
   
export const updateTextElement = (request) => 
   secureAxios.put(`/textElement`, request)

export const createNewCollection = (request) => 
   secureAxios.post(`/collection`, request)

export const getAllUserCollections = (idUser) => 
   secureAxios.get(`/collection?q=owner.id:${idUser}`)

export const eliminarColection = (idCollection) => 
   secureAxios.delete(`/collection/${idCollection}`)

export const updateAudioElement = (idAlbum, fileToUpload) => 
   secureAxios.post(`/album/${idAlbum}/associateMusic`, fileToUpload, 'multipart')

export const getAlbumAudioElement = (idAlbum) => 
   secureAxios.get(`/album/${idAlbum}/getAlbumMusic`)

/* user validations */
export const sendMailResetPassword = (request) => 
   secureAxios.post(`/sendEmailToResetThePassword`, request) 

export const resetUserPassword = (request) => 
   secureAxios.put(`/users/resetPassword`, request)

export const sendMailConfirmationAccount = (request) => 
   secureAxios.post(`/sendEmailToConfirmAccount`, request) 

export const acceptPrivacyPolicy = (request) => 
   secureAxios.put(`/users/acceptPrivacyPolicy`, request)

export const activateUser = (request) => 
   secureAxios.put(`/users/activateUser`, request) 

export const getAllPlans = () => 
   secureAxios.get(`/plan`)

export const isUserSubscribed = () =>
   secureAxios.get(`/subscription/getCurrentSubscription`)
   
export const getUserStorageSummary = userId =>
   secureAxios.get(`/users/${userId}/getUserStorageSummary`)

export const sendEmailToInvite = (request) =>
   secureAxios.post(`/sendEmailToInviteSomeone`, request)

export const getMyVitalAngels = (email) =>
   secureAxios.get(`/vitalangel?q=ownerUser.email::${email}`)

export const convertUserToVitalAngel = (request) =>
   secureAxios.post(`/vitalangel/markAsVitalangel`, request)

export const unmarkUserFromVitalAngel = (request) =>
   secureAxios.delete(`/vitalangel/unmarkAsVitalangel`, {data:request})

export const getSendInvites = (userId) => 
   secureAxios.get(`/connectionRequest?q=origin.id::${userId}`)

export const getRecivedInvites = (userId) => 
   secureAxios.get( `/connectionRequest?q=receiver.id::${userId}`)

export const accessAuth = (request) => 
   secureAxios.put( `/accessAuthorization`, request)

export const accessAuthInstagram = (code) => 
   secureAxios.get( `/accessAuthorization/getInstagramBasicInformation/${code}`)

export const getAllMyConnectionRequest = () =>
   secureAxios.get( `/connectionRequest/getAllMyConnectionRequest`)

export const getMyConnections = () => 
   secureAxios.get( `/connection/getAllMyConnections`)

export const getImageProfileImageOfMyConnection = (id) => 
   secureAxios.get(`/connectionRequest/${id}/getAllMyConnectionRequest`)

export const acceptRequestInvite = (request) => 
   secureAxios.put(`/connectionRequest`, request)

export const deleteConnectionRequest = (id) => 
   secureAxios.delete(`/connectionRequest/${id}`, null)
   
export const getProfileImageOfMyConnection = (id) => 
   secureAxios.get(`/connection/${id}/getProfileImageOfMyConnection`)

export const deleteRequestInvite = (id) => 
   secureAxios.delete(`/invitation/${id}`)

export const deleteContact = (id) => 
   secureAxios.delete(`/connection/${id}`)

export const getSizeOfSelectedColection = (id) => 
   secureAxios.get(`/upload/getSizeOfMyImages?collectionId=${id}`)

export const createSubscription = (idPlan, isAnnual, policyAcceptance) => 
   secureAxios.post(`/subscription/changeMyCurrentPlan/${idPlan}?isAnnualPayment=${isAnnual}&policyAcceptance=${policyAcceptance}`)

export const getAllDocuments = () =>
   secureAxios.get(`/document/getAllMyDocuments`)

export const renameFilenameDoc = (idDoc, newNameFile) =>
   secureAxios.put(`document/${idDoc}/changeFilename?filename=${newNameFile}`)

export const deleteDocument = (idDoc) =>
   secureAxios.delete(`/document/${idDoc}`)

export const uploadDocument = (request) => 
   secureAxios.post(`/document/upload`, request, {headers: {'Content-Type': 'multipart/form-data'}})

export const dowloadDocument = (id) => 
   secureAxios.get(`/document/${id}/download`, {responseType: 'arraybuffer'})
   
export const getAllMyTags = () => 
   secureAxios.get('/tag/getAllMyTags')

export const createNewTag = (name) =>
   secureAxios.post(`/tag?name=${name}`)

export const deleteTag = (idTag) =>
   secureAxios.delete(`/tag/${idTag}`)

export const renameTag = (request) =>
   secureAxios.put(`/tag`, request)

export const deleteAudioAlbum = (idAlbum) => 
   secureAxios.delete(`/album/${idAlbum}/deleteMusic`)

export const getWaitingSubscription = () => 
   secureAxios.get(`/subscription/getWaitingSubscription`)

export const deleteMyWaitingSubscription = () =>
   secureAxios.delete(`/subscription/deleteMyWaitingSubscription`)

export const isValidInstagramSession = () => 
   secureAxios.get(`/accessAuthorization/isValidTheInstagramToken`)

export const getInfoAboutChangePlan = (idPlan, isAnnual) => 
   secureAxios.get(`/subscription/getInformationAboutChangeMyCurrentPlan/${idPlan}?isAnnualPayment=${isAnnual}`)

export const getInstagramTokenIsValidConn = () =>
   secureAxios.get('/accessAuthorization/getInstagramToken')

export const videoCreator = (albumId, timeTransiton) =>
   secureAxios.post(`/videoCreator/${albumId}/createVideo?timeOfTransition=${timeTransiton}`)

export const getVideo = (albumId) =>
   secureAxios.get(`/videoCreator/${albumId}/getVideo`, {responseType: 'arraybuffer'})

export const deleteVideo = (albumId) =>
   secureAxios.delete(`/videoCreator/${albumId}/deleteVideo`)
   
   
export const importFromGoogleDrive = (collectionId, file, token) =>
   secureAxios.post(`/repositoryItem/createRepoItemFromGoogleDrive/${collectionId}?name=${file.name}&id=${file.id}&size=${file.sizeBytes}&token=${token}`)


