import React, { Component } from 'react';
import MenuVistaContact from '../MenuVistaFriend/MenuVistaFriend'

export default class Requests extends Component {
		
	render() {
		const { personList, updateUsers, lang } = this.props
		
		return (
            <div className="main-requests">
				{personList && (personList.length>0) && personList.map((item, k)=>{
					return (
						<div className='box-user-firend' key={`item-user-contact_${k}`}>
							<MenuVistaContact updateUsers={updateUsers} type="requests" userData={item} lang={lang}  />
						</div>
					)
				})}
            </div>
        );
	}
}
