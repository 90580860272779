import React, { Component } from 'react';
import * as Utils from '../../Utilities/Utils'

export default class Template2 extends Component {
    render() {
      const { pagePosition, page, isPreview } = this.props
      const textContent = Utils.getElementsText(page, "TEXT")

      return(
        <div key={`template-2-${pagePosition}-id_${page.id}`} className="main-template">
          {/* {isPreview ? 
               <label className="template1-text-1">{textContent && textContent.textElement && textContent.textElement.text}</label> : */}
               <textarea key={`template-2-${pagePosition}-text-area-id_${page.id}`} className={`template1-text-2 scrollbar ${isPreview ? 'template-preview' : ''}`}
                         defaultValue={textContent && textContent.textElement && textContent.textElement.text} onBlur={(data)=>this.compileAllText(data, textContent)}
                         disabled={isPreview ? true : false} />
            {/* } */}
        </div>
      );
    }

    compileAllText = (data, element) => {
      const { updateText } = this.props
      updateText(data, element)
   }
}
