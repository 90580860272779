import React, { Component } from 'react'
import * as Services from '../../Utilities/Services'
import * as Imgs from '../../Utilities/ImgConfig'
import TabBarImg from './TabBarImg'
import UploadPhotos from './UploadPhotos'
import DialogNewColection from '../../Dialogs/DialogNewColection'
import Progressbar from '../../components/ProgressBar'
import { FormattedMessage, injectIntl } from 'react-intl';

let nameColectionSelected
const defaultColectionSelected = (collection, defaultCollectionId, context) => {
    if(collection.id === defaultCollectionId){
        nameColectionSelected = collection.name
        return 'user-photos-title-selected'
    } else {
        return ''
    }
}

class SeeAllPhotos extends Component {

	state = {
        showUploadDialog: null,
        showNewColectionDialog : null,
        nameColectionSelected: null,
        googleDriveToken: ''
    };
    
    _collections = (coll) => {
        const { selectCollection, defaultCollectionId } = this.props

        let elements = []
        coll.map((item, k) => {
            elements.push(
                <div key={`name-colection-${k}`} className="main-user-photos-title">
                    <div className={defaultColectionSelected(item, defaultCollectionId, this)} onClick={()=>selectCollection(item.id)} >{item.name}</div>
                    <img src={Imgs.Trash}
                         onClick={()=>this.deleteRepo(item.id)} />
                </div>
            )
        })

        return (
            <div className="main-colections">
                <div className="colections-title">
                    <div className="colections-title-text"><FormattedMessage id="MY_COLECTIONS" /></div><img className="colections-title-img" src={Imgs.MiniAdd} onClick={this.showHideColectionDialog}/>
                </div>
                {elements}
            </div>
        )
    }

	render() {
        const { allFbImgSelected, allInstagramImg, allLocalImg, classNameList, owner, filterActive, templatesData, activeFilter, allSharedphotos,
            showListImg, callGetAllRepositoryItems, defaultCollectionId, allColectionsUser, getAllCollections, intl, displayNotifyDialog, allGoogleDriveImg } = this.props
        const { showUploadDialog, showNewColectionDialog, googleDriveToken } = this.state
        
        const uploadDialog =
            showUploadDialog ?
                <UploadPhotos   customStyles='main-dialog-notify-reset'
                                close={this.showHideUploadDialog}
                                open={showUploadDialog}
                                defaultCollectionId={defaultCollectionId}
                                allInstagramImg={allInstagramImg}
                                allFbImgSelected={allFbImgSelected}
                                callGetAllRepositoryItems={callGetAllRepositoryItems}
                                gdToken={googleDriveToken}
                                gdOnAuth={(auth) => {
                                    this.onGoogleDriveAuth(auth)
                                }}
                /> : null

        const newColectionDialog = showNewColectionDialog ? <DialogNewColection open={showNewColectionDialog} close={this.showHideColectionDialog} owner={owner} getAllCollections={getAllCollections} /> : null

		return (
            <div className="main-user-photos">
                {newColectionDialog}
                {uploadDialog}
                <div className="user-photos-all-view hidden-scrollbar">
                    <h4 className="user-photos-all-view-title" >{`${intl.formatMessage({id:'TITLE_TEMPLATES'})} (${templatesData.length})`}</h4>
                    <TabBarImg imgTest={templatesData}  name={'droppableTemplate'} />
                    <div className="user-photos-title-selected-collection">{nameColectionSelected}</div>
                    <Progressbar />
                    <div className="user-photos-selector-buttons">
                        <div className={`user-photos-selector-filter ${filterActive == 'usedImg' ? 'user-photos-selector-filter-active' : ''}`}
                             onClick={()=>activeFilter('usedImg', true)}
                        >
                            {`${intl.formatMessage({id:'USED_IMG'})} (${this.counterUsedImg(true)})`}
                        </div>
                        <div className={`user-photos-selector-filter ${filterActive == 'nonUsedImg' ? 'user-photos-selector-filter-active' : ''}`}
                             onClick={()=>activeFilter('nonUsedImg', false)}
                        >
                            {`${intl.formatMessage({id:'UNUSED_IMG'})} (${this.counterUsedImg(false)})`}
                        </div>
                    </div>
                    <div className="main-user-photos-title" 
                        onClick={()=>showListImg('main-user-photos-local')}>
                        <label>{`Local (${allLocalImg ? allLocalImg.length : 0})`}</label>
                        <img className={classNameList == 'main-user-photos-local' ? `main-user-photos-title-arrow` : ''}
                            src={Imgs.SelectArriba}/>
                    </div>
                    {allLocalImg && allLocalImg.length > 0 && classNameList == 'main-user-photos-local' ?
                        <TabBarImg imgTest={allLocalImg} name={'droppableLocal'} displayNotifyDialog={displayNotifyDialog} defaultCollectionId={defaultCollectionId} callGetAllRepositoryItems={callGetAllRepositoryItems} /> 
                        : null}

                    <div className="main-user-photos-title" 
                        onClick={()=>showListImg('main-user-photos-googledrive')}>
                        <label>{`Google Drive (${allGoogleDriveImg ? allGoogleDriveImg.length : 0})`}</label>
                        <img className={classNameList == 'main-user-photos-googledrive' ? `main-user-photos-title-arrow` : ''}
                            src={Imgs.SelectArriba}/>
                    </div>
                    {allGoogleDriveImg && allGoogleDriveImg.length > 0 && classNameList == 'main-user-photos-googledrive' ?
                        <TabBarImg imgTest={allGoogleDriveImg} name={'droppableGoogleDrive'} displayNotifyDialog={displayNotifyDialog} defaultCollectionId={defaultCollectionId} callGetAllRepositoryItems={callGetAllRepositoryItems} /> 
                        : null}

                    {/* DISABLED FACEBOOK ON 2022-03-09. Only show if user has photos before disabled. */}
                    {allFbImgSelected && allFbImgSelected.length > 0 && (
                    <>
                    <div className="main-user-photos-title" 
                        onClick={()=>showListImg('main-user-photos-fb')}>
                        <label>{`Facebook (${allFbImgSelected ? allFbImgSelected.length : 0})`}</label>
                        <img className={classNameList == 'main-user-photos-fb' ? `main-user-photos-title-arrow` : ''}
                            src={Imgs.SelectArriba}/>
                    </div>
                    {allFbImgSelected && allFbImgSelected.length > 0 && classNameList == 'main-user-photos-fb' ?
                        <TabBarImg imgTest={allFbImgSelected} name={'droppableFacebook'} displayNotifyDialog={displayNotifyDialog} defaultCollectionId={defaultCollectionId} callGetAllRepositoryItems={callGetAllRepositoryItems} /> 
                        : null}
                    </>
                    )}

                    {/* TODO DEVELOV API FACEBOOK INSTAGRAM  PROBABLY 02/03/2020 */}
                    {/*
                    <div className="main-user-photos-title"
                        onClick={()=>showListImg('main-user-photos-instagram')}>
                        <label>{`Instagram (${allInstagramImg ? allInstagramImg.length : 0})`}</label>
                        <img className={classNameList == 'main-user-photos-instagram' ? `main-user-photos-title-arrow` : ''}
                            src={Imgs.SelectArriba}/>
                    </div>
                    
                   
                    {allInstagramImg && allInstagramImg.length > 0 && classNameList == 'main-user-photos-instagram' ?
                        <TabBarImg imgTest={allInstagramImg} name={'droppableInstagram'} displayNotifyDialog={displayNotifyDialog} />
                        : null}
                    */}
                    <div className="main-user-photos-title"
                        onClick={()=>showListImg('main-user-photos-shared')}>
                        <label>{`${intl.formatMessage({id:'MENU_OPTNS_SHARE'})} (${allSharedphotos ? allSharedphotos.length : 0})`}</label>
                        <img className={classNameList == 'main-user-photos-shared' ? `main-user-photos-title-arrow` : ''}
                            src={Imgs.SelectArriba}/>
                    </div>
                    
                    {allSharedphotos && allSharedphotos.length > 0 && classNameList == 'main-user-photos-shared' ?
                        <TabBarImg imgTest={allSharedphotos} name={'droppableInstagram'} displayNotifyDialog={displayNotifyDialog} defaultCollectionId={defaultCollectionId} callGetAllRepositoryItems={callGetAllRepositoryItems} />
                        : null}

                    {allColectionsUser && this._collections(allColectionsUser)}
                </div>
                <div className="user-photos-import" 
                    onClick={this.showHideUploadDialog}>
                        <img className="user-photos-import-img"  src={Imgs.Importar} />
                    <FormattedMessage id="BTN_IMPORT_IMG" />
                </div>
            </div>
        )
    }

    showHideColectionDialog = () => {
        this.setState({showNewColectionDialog: !this.state.showNewColectionDialog})
    }
    
    showHideUploadDialog = () => {
        const { callGetAllRepositoryItems } = this.props
        callGetAllRepositoryItems()
        this.setState({showUploadDialog: !this.state.showUploadDialog})
    }

    counterUsedImg = (isUsed) => {
        const { allItemImg } = this.props
        let allFiltredImg = []
        allFiltredImg = allItemImg.filter(k => k.used === isUsed )
        
        return allFiltredImg.length        
    }

    deleteRepo = (idRepo) => {
        const { getAllCollections } = this.props
        Services.eliminarColection(idRepo)
            .then(response => {
                getAllCollections()
            })
            .catch(error => {console.error('DELETE_REPO_ITEM_ERROR -> ', error)})
    }

    onGoogleDriveAuth = (auth) => {
        this.setState({googleDriveToken: auth.access_token})
    }
}

export default injectIntl(SeeAllPhotos);