import React, { Component } from 'react';
import * as Icons from '../../Utilities/ImgConfig'
import * as Service from '../../Utilities/Services'
import * as Utils from '../../Utilities/Utils'
import CustomInput from '../../components/CustomInput'
import FlotingDialog from '../../Dialogs/FlotingDialog'
import { injectIntl, FormattedMessage } from 'react-intl';


class InviteFriends extends Component {
	
	state = {
		valueMail: null,
		valueName: null,
		valueSurname: null,
		userVals: [{'mail':null,'name':null,'surname':null}],
		listPersonToInvite: 1,
		showDialog: null,
		typeMessage: null,
		msgError: null
	};

	_listInvites = () => {
		const { listPersonToInvite, userVals } = this.state
		const { lang, intl } = this.props
		let listArray = []
		for (let i=0;i<listPersonToInvite;i++) {
			listArray.push(
				<div key={`number-invite-${i}`} className='invite-friends-form'>
					<CustomInput addClassName={`home-content-login-input ${this.state.userVals[i] && this.state.userVals[i].mail ? '': 'home-content-login-input-empty'} ${this.state.userVals[i] && this.state.userVals[i].error ? `custom-input-element-error` :''}`} 
								 placeholder={intl.formatMessage({id: 'PH_MAIL'})}
								 width='-webkit-fill-available'
								 height='4vh'
								 methodToRun={(data)=>this.setDataForm(data, 'mail', i)}
								 margin='auto 15px auto 0px'
					/>
					<CustomInput addClassName={`home-content-login-input ${userVals[i] && userVals[i].name ? '': 'home-content-login-input-empty'} `}
								 width='-webkit-fill-available'
								 height='4vh'
								 placeholder={intl.formatMessage({id: 'PH_NAME'})} 
								 methodToRun={(data)=>this.setDataForm(data, 'name', i)}
								 margin='auto 15px auto 0px'
					/>
					<CustomInput addClassName={`home-content-login-input ${userVals[i] && userVals[i].surname ? '': 'home-content-login-input-empty'} `} 
								 placeholder={intl.formatMessage({id: 'PH_SURNAME'})}
								 width='-webkit-fill-available'
								 height='4vh'
								 methodToRun={(data)=>this.setDataForm(data, 'surname', i)}
								 margin='auto 0px auto 0px'
					/>
					{(listPersonToInvite > 1) && 
						<div className="invite-friends-form-close">
							<img src={Icons.BluePintetBGCross} onClick={()=>this.deleteReqInvite(i)} />
						</div>
					}
				</div>
			)
		}

		return (
			<div className='invite-friends-form-list scrollbar'>
				{listArray}
			</div>
		)
	}

	render() {
		const { msgError, typeMessage, showDialog } = this.state
		const { lang } = this.props
		const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null
		
        return (
			<div className='main-invite-friends'>
				{previewFlotingDialog}
				<div className='invite-friends-title'><FormattedMessage id="TITLE_INVITE_FRIENDS" /></div>
				<label className='invite-friends-complement'><FormattedMessage id="TITLE_COMPLEMENT" /></label>
				{this._listInvites()}
				<div className='invite-friends-add-list'>
					<div className='invite-friends-add-list-more-people' onClick={this.addOneInvite}>
						<img src={Icons.AddLogo}/>
						<label><FormattedMessage id="ADD_INVITE" /></label>
					</div>
					<div className='invite-friends-add-list-google'>
						{/* maquetado para el link de conexion con contactos de google */}
						{/* <img src={Icons.GoogleLog}/>
						<label>{this.CONNECT}</label>
						<label className='invite-friends-add-list-google-click' onClick={()=>null}>{this.CONNECT_GOOGLE}</label> */}
					</div>
				</div>
				<div className='invite-friends-google-and-btn'>
					{/* maquetado para el link de conexion con contactos de facebook */}
					{/* <label className='invite-friends-conect-users-fb'>{this.CONECT_FB_CONTACTS}</label>
					<label className='invite-friends-conect-users-fb-click' onClick={()=>null}>{this.FACEBOOK}</label> */}
					<button className='invite-friends-btn' onClick={this.sendInvite}><FormattedMessage id="BTN_SEND" /></button>
				</div>
			</div>
		)
	}
	deleteReqInvite = (k) => {
		let { userVals, listPersonToInvite } = this.state
		listPersonToInvite=listPersonToInvite-1
		userVals.splice(k, 1)
		this.setState({
			listPersonToInvite: listPersonToInvite,
			userVals: userVals
		})
	}
	addOneInvite = () => {
		this.state.userVals.push({'mail':null,'name':null,'surname':null})
		this.setState({listPersonToInvite: ++this.state.listPersonToInvite})
	}

	setDataForm = (data, keyVal, i) => {
		const { userVals } = this.state
		userVals[i][keyVal] = data
		this.setState({userVals: userVals})
	}
	setOkOrKoOnMailIput = (k, stateInput, msgError, error) => {
		const { userVals } = this.state
		userVals[k].error = stateInput
		this.setState({
			userVals: userVals,
			showDialog: true, 
			msgError: msgError,
			typeMessage: error
		}, ()=>Utils.clearError(this))
	}

	sendInvite = () => {
		const { userVals } = this.state
		const { updateUsers, intl } = this.props
		if(userVals.length > 0) {
			userVals.map((item, k) => {
				if(item.mail && item.mail !== '') {
					const request = {
						email: item.mail,
						language: "ES",
						name: item.name,
						surname: item.surname
					}
					Service.sendEmailToInvite(request)
						.then(response => {
							updateUsers()
							const msgOk = intl.formatMessage({id: 'MGS_INVITATION_SEND'})
							this.setOkOrKoOnMailIput(k, false, msgOk, 'ok')
						})
						.catch(error => {
							this.setOkOrKoOnMailIput(k, true, error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
							console.error('SEND_EMAIL_TO_INVITE_ERROR ->', error)
						})
				} else {
					const error = intl.formatMessage({id: 'MSG_EMPTY_MAIL'})
					this.setOkOrKoOnMailIput(k, true, error, 'error')
				}
			})
		}
	}
}
	
export default injectIntl(InviteFriends)