import { LOCALE_SET_LANGUAGE, GET_LANGUAGE } from '../types';
import moment from 'moment';
import * as Service from '../../Utilities/Services'

export const setLocale = lang => dispatch => {
  moment.locale(lang);
  dispatch({ type: LOCALE_SET_LANGUAGE, payload: { lang } });
};

export const getLang = () => dispatch => {
    let lang = (navigator.language || navigator.userLanguage).split('-')[0].toUpperCase();
    if(localStorage.getItem('userMail')){
      Service.getInfoLoggedUser(localStorage.getItem('userMail'))
        .then((response)=>{
          lang = response.data.content[0].languageSelected
          dispatch({ type: GET_LANGUAGE, payload: { lang } });
        })
        .catch((error)=>{
          
          dispatch({ type: GET_LANGUAGE, payload: { lang } });
          console.error("GET_INFO_USER_DATA_ERROR -> ", error)
        })
    } else {
      dispatch({ type: GET_LANGUAGE, payload: { lang } });
    }
}