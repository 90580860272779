import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Icons from '../../Utilities/ImgConfig'


export default class BarImgSelector extends Component {

   state = {
      pageOption: 0
   }

   render() {
      const { pageOption } = this.state;
      const { titles, imgMenu, elements, menuOpt, closeMenu } = this.props

      return (
         <div className="component-bar-selector">
            { !menuOpt ?
               <div className="component-bar-selector-menu">
                  { titles.map((item, k)=>{
                        return (
                           <div className="component-bar-selector-options" key={`bar-selector-titles-${k}`} onClick={()=>this.changeCont(k)}>
                              <img src={`${imgMenu[k]}`} />
                           </div>
                        )
                     })
                  }
               </div>
               :
               <div className={`component-bar-slide-menu`}>
                  { elements.map((item, k)=>{
                        return (
                           pageOption === k && (
                              <div key={`bar-selector-elements-${k}`} className="component-bar-selector-option-chose">
                                 <img src={Icons.CloseXBlue} className="close-button" onClick={closeMenu}/>
                                 <h4>{titles[k]}</h4>
                                 <div className="component-bar-selector-option-content">
                                    {item}
                                 </div>
                              </div>
                           )
                        )
                     })
                  }
               </div>
            }
         </div>
      );
   }

   changeCont = pageSelect => {
      this.props.optionMenu()
      this.setState({pageOption: pageSelect})
   }
}

BarImgSelector.propTypes = {
   elements: PropTypes.array.isRequired
};
