import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as Icons from './Utilities/ImgConfig'
import * as Service from './Utilities/Services'
import * as Utils from './Utilities/Utils'
import ProfileSidebar from './components/ProfileSidebar'
import SuscriptionDialog from './Dialogs/SuscriptionDialog'
import FaqSidebar from './components/FaqSidebar'
import { FormattedMessage } from 'react-intl';
import { setLocale } from './Redux/Actions/localeActions';

class Header extends Component {

    componentWillMount() {
        this.isLoged() && Utils.userAcceptPolicyRules() && this.getInfo()
    }

    state = {
        userData: {},
        profileFilename: null,
        profileImage: null,
        showSuscription: false,
        currentUserSub: null,
        lang: null
    }

    render() {
        const { profileFilename, profileImage, userData, showSuscription, lang } = this.state
        const dialogSuscription = showSuscription ? <SuscriptionDialog open={showSuscription} cross={this.showHideSuscription} userData={userData} /> : null
        const isAlbum = this.props.router.history.location.pathname.toUpperCase() !== '/MYAPPS'

        return this.isLoged() ? (
           <div className={isAlbum ? `header-main-album` : `header-main`}>
                {dialogSuscription}
                <div className="header-logo">
                    <img src={isAlbum ? Icons.LogoVitalbookWhite : Icons.LogoVitalbookHoriz} onClick={this.goHome} />
                </div>
                <div className={isAlbum ? `header-menu-user-album` : `header-menu-user`}>
                    <FaqSidebar isAlbum={isAlbum} />
                    <button onClick={this.showHideSuscription}><FormattedMessage id="SUB_BTN" /></button>
                    <div className="profile-container">
                        <ProfileSidebar
                            lang={lang}
                            userData={userData}
                            isAlbum={isAlbum}
                            profileFilename={profileFilename}
                            profileImage={profileImage}
                            logoutApp={this.logoutApp}
                            showHideSuscription={this.showHideSuscription}
                            changeProfileImage={this.changeProfileImage}
                            updateStateUserData={this.updateStateUserData}
                            getDataLoginFacebook={this.getDataLoginFacebook}
                            push={this.props.router.history.push}
                        />
                    </div>
                </div>
           </div>
        ) : 
        (
            <div className={`header-main-album`} onClick={this.inicialPage}>
                <div className="header-logo">
                    <img src={Icons.LogoVitalbookWhite} />
                </div>
            </div>
        )
    }

    isLoged = () => {
        return localStorage.getItem('token') && localStorage.getItem('userMail')
    }

    showHideSuscription = () => {
        if (Utils.userAcceptPolicyRules())
            this.setState({showSuscription: !this.state.showSuscription})
        else
            this.props.openPolicyRules()
    }
    getInfo = () => {
        let userData = {};
        Service.getInfoLoggedUser(localStorage.getItem('userMail'))
            .then((response)=>{
                userData = { ...response.data.content[0] };
                return Service.getUserStorageSummary(userData.id);
            })
            .then(response => {
                this.updateStateUserData({...userData, ...response.data})
            })
            .catch((error)=>{
                console.error("GET_INFO_USER_DATA_ERROR -> ", error)
            })
    }
    logoutApp = () => {
        if(Utils.userAcceptPolicyRules()) {
            Service.logoutApplication()
                .then(response => {
                        this.removeLocalSesion()
                })
                .catch(error => {
                    console.error("LOGOUT_ERROR -> ", error)
                    this.removeLocalSesion()
                })
        } else {
            this.removeLocalSesion()
        }
    }
    removeLocalSesion = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenFb')
        localStorage.removeItem('userMail')
        this.props.router.history.push('/')
    }
    changeProfileImage = (profileImage, newFileName) => { // Le damos la responsabilidad de cambiar la imagen al padre
        this.setState({profileImage: profileImage, profileFilename: newFileName});
    }
    updateStateUserData = (response) => {
        let date = new Date(response.birthday && response.birthday.substr(0,10));
        let userInfo = {
            'cardIdentifier': response.cardIdentifier,
            'credit': response.credit,
            'name': response.name,
            'surname': response.surname,
            'birthday': date,
            'email': response.email,
            'id': response.id,
            'numAlbums': response.numAlbums || '0',
            'numContacts': response.numContacts || '0',
            'numVitalangels': response.numVitalangels || '0',
            'usedStorage': response.usedStorage || 0,
            'maxStorageAllowed': response.maxStorageAllowed || 0,
            'totalUsedStorage': response.totalUsedStorage || 0,
            'totalUnusedFilesSize': response.totalUnusedFilesSize || 0,
            'currentSubscription': response.currentSubscription || { plan: {} }
        };
        this.setState({
            userData : userInfo,
            profileFilename: response.profileImage,
            currentUserSub: response.currentSubscription,
            lang: response.languageSelected
        });
    }
    getDataLoginFacebook = (response) => {
        this.setState({dataLoginFacebook: response});
    }
    getProfileImage = (profileFilename) => {
        if(profileFilename){
            Service.uploadToBase64(profileFilename)
                .then((response)=>{
                    this.setState({profileImage: `data:image/jpeg;base64,${response.data}`});
                })
                .catch((error)=>{
                    console.error("Get_Profile_Image_ERROR -> ", error)
                })
        }
    }
    goHome = () => {
        if (this.props.router.history.location.pathname === '/myapps')
            return  ()=>null
        else
            return this.props.router.history.push('/myapps')
    }

    inicialPage = () => {
        if(localStorage.getItem('userMail') && localStorage.getItem('token'))
            return this.props.router.history.push('/myapps')
        else
            return this.props.router.history.push('/')
    }
}

export default connect(({locale}) => ({locale}), { setLocale })(Header);
