import React, { Component } from 'react';

export default class AppOption extends Component {
  
  DISABLED_TEXT = 'En construcción, próximamente tendrá acceso a nuevas funcionalidades'

  state = {
    icon: ''
  }

  componentDidUpdate(prevProps) {
    if (this.props.icon !== prevProps.icon) {
      this.setState({icon:this.props.icon});
    }
  }

  render() {
    const { title, icon, functClick, text, isDisabled, isFree } = this.props

    return(
      <div className={`listapps-container-option ${isFree ? 'listapps-container-options-disable' : isDisabled ? 'listapps-container-options-disable' : ''}`}>
        <div className='listapps-container-apps' onClick={isFree ? null : functClick}>
          {icon ? <img src={icon} alt="App" /> : ''}
        </div>
        <div className='listapps-container-titleapps'>{title}</div>
        <div className='listapps-container-titleapps-infotext'> 
          <label>{isFree ? text : isDisabled ? this.DISABLED_TEXT : text }</label>
        </div>
      </div>
    );
  }

}
