import React, { Component } from 'react';
import * as Service from '../../../Utilities/Services'
import * as Utils from '../../../Utilities/Utils'
import { FormattedMessage, injectIntl } from 'react-intl';

class SlideMenuOptions extends Component {

	state = {
    };
 
	render() {
        const { closeMenuOptns } = this.props
        return (
            <div className="main-slide-menu-options" onClick={closeMenuOptns}>
                <div className="slide-menu-options-context">
                    {/* <div>{lang.MENU_OPTNS_SHARE}</div> */}
                    <div onClick={this.convertVitalAngel}><FormattedMessage id="MENU_OPTNS_MY_VITALANGEL" /></div>
                    {/* <div>{lang.MENU_OPTNS_CHANGE_AVATAR}</div> */}
                    <div onClick={this.remove}><FormattedMessage id="MENU_OPTNS_DELETE" /></div>
                </div>
            </div>
        )
    }
    
    convertVitalAngel = () => {
        const { userData, setMessageDialog } = this.props

        const request = {
            id: userData.id
        }
        Service.convertUserToVitalAngel(request)
            .then(response=>{
                this.props.updateUsers()
            })
            .catch(error=>{
                setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                console.error('CONVERT_USER_TO_VITAL_ANGEL_ERROR ->', error)
            })
    }

    removeUserFromVitalAngel = () => {
        const { userData, setMessageDialog } = this.props

        const request = {
            id: userData.id
        }
        Service.unmarkUserFromVitalAngel(request)
            .then(response=>{
                window.location.reload()
            })
            .catch(error=>{
                setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                console.error('UNMARK_USER_FROM_VITAL_ANGEL_ERROR ->', error)
            })
    }

    removeMyContact = () => {
        const { userData, setMessageDialog } = this.props

        Service.deleteContact(userData.id)
            .then(response=>{
                // window.location.reload()
            })
            .catch(error=>{
                setMessageDialog(error && error.response && error.response.data && Utils.formatErrorMsg(error.response.data.message) || 'Error', 'error')
                console.error('DELETE_CONTACT_ERROR ->', error)
            })
    }

    remove = () => {
        const { type } = this.props

        switch(type) {
            case 'myVitalAngels':
                this.removeUserFromVitalAngel()
                break;
            case 'myContacts':
                this.removeMyContact()
                break;
            case '':
                break;
            case '':
                break;
        }
    }
}

export default injectIntl(SlideMenuOptions)
