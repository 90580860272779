import React, { Component } from 'react';
import Dialog from './GenericDialog';
import * as Img from '../Utilities/ImgConfig';


export default class DialogLoader extends Component {

	render() {
        const { open, close, accept } = this.props
		
		return (
			<Dialog open={open} close={false} accept={accept} cross={close} customStyles='main-dialog-loader'>
                <div className='dialog-loader-spinner'>
                    <img src={Img.spinner} />
                </div>
			</Dialog>
		)
	}

}
	