import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Form from './Form';
import Document from './Document';

import {
  fetchFormConfig,
  removeStoreKey,
  loadInitialState
} from '../../Redux/Actions/documentFormActions';

import './FillableDocument.css';

class FillableDocument extends React.Component {
  state = {
    formState: {},
    hash: ''
  };

  goBack = () => this.props.history.push('/docs');
  goBackIfWrongConfig = () => {
    const { extend, store, id } = this.props.documentForm;

    if (!id || extend && (!store || !extend.every(e => store[e]))) {
      this.goBack();
      return true;
    }

    return false;
  };

  componentDidMount() {
    this.goBackIfWrongConfig()
  }

  componentDidUpdate() {
    this.goBackIfWrongConfig();
  }

  render() {
    return (
      <div className="FillableDocument">
        <Form className="FillableDocument__Form" exitEvent={this.goBack} />
        <Document
          className="FillableDocument__Document scrollbar"
          exitEvent={() => {
            this.props.loadInitialState();
            this.goBack();
          }}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ documentForm }) => ({ documentForm }),
    { fetchFormConfig, removeStoreKey, loadInitialState }
  )(FillableDocument)
);
