import * as Service from '../Utilities/Services';

  const ImageEditorActions = {
    convertBase64ToFile: function(image){
      const byteString = atob(image.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      let file = new File([ab], "image.jpg", {type: "image/jpeg"});
      return file;
    }
}

export default ImageEditorActions;