import Config from '../Utilities/Config'
import { injectIntl } from 'react-intl';
    
export function getBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function getUserPhoto(token, tokenFb){
    return Config.API.ROOT_URL+'/users/getProfileImage?token='+token+'&&tokenFb='+tokenFb
}

export function getPhoto(idItemImg, token, isSmall){
    return Config.API.ROOT_URL+'/repositoryItem/'+idItemImg+'/getRepoItemImage?token='+token+'&small='+isSmall
}

export function getSkin(idSkin, token){
    return Config.API.ROOT_URL+'/skin/'+idSkin+'/getSkinImage?token='+token
}

export function getMusic(idAlbum, token){
    return Config.API.ROOT_URL+'/album/'+idAlbum+'/getAlbumMusic?token='+token
}

export function getProfileImageOfMyConnectionRq(id, token){
  return Config.API.ROOT_URL+'/connectionRequest/'+id+'/getProfileImageOfMyConnectionRq?token='+token
}

export function getProfileImageOfMyConnection(id, token){
  return Config.API.ROOT_URL+'/connection/'+id+'/getProfileImageOfMyConnection?token='+token
}

export function getToken() {
  return localStorage.getItem('token')
}

export function clearSession() {
  localStorage.removeItem('token')
  localStorage.removeItem('tokenFb')
  localStorage.removeItem('userMail')
}

export function getFacebookToken() {
  return localStorage.getItem('tokenFb')
}

export function clearError(enviroment){
    setTimeout(()=>{
        enviroment.setState({showDialog: false, msgError: null})
    }, 4000)
}

export function redirectToLogin() {
  window.location.replace(window.location.origin);
}

export function getAllUrlParams(enviroment, url) {
    var arrayParams = ""
    var queryString = url ? url.split('?')[1] : window.location.search.replace('?','');

    if (queryString) {
      queryString = queryString.split('#')[0];
      arrayParams = queryString.split('&');
    }

    enviroment.setState({
        param1: arrayParams && arrayParams[1],
        userMail: arrayParams && arrayParams[2]
    }, goToHomeContext(arrayParams, enviroment))
  }

  function goToHomeContext(arrayParams, enviroment) {
    
    if(arrayParams){
      switch (arrayParams[0]){
          case "resetPassword":
            enviroment.goToChangePass(arrayParams && arrayParams[1], arrayParams && arrayParams[2], arrayParams[0])
            break;
          case "confirmYourAccount":
            enviroment.goToLogin(arrayParams && arrayParams[1], arrayParams && arrayParams[2], arrayParams[0])
            break;
          case "registration":
            enviroment.goToLogin(arrayParams && arrayParams[1], arrayParams && arrayParams[2], arrayParams[0], arrayParams[3])
            break;
          default:
            enviroment.goToLogin()
      }
      enviroment.hideShowSidebar()
    } else {
      enviroment.goToLogin()
    }
  }

  export function formatErrorMsg(errorMsg){
    let message = ''
    message=errorMsg.replace('server','')
    message=message.replace('.',' ')
    message=message.replace('.',' ')
    return message
  }

  export function userAcceptPolicyRules(){
    if (
      localStorage.getItem('token') !== '' &&
      localStorage.getItem('token') !== 'null' &&
      localStorage.getItem('token') !== 'undefined'
    ){
      return true
    }else {
      return false
    }
  }

  export function orderArrayByParam(arr) {
    return arr.sort(comparar)
  }
  export function comparar(a, b) {
    return a.position - b.position;
  }

  export function getElementsText(page, type) {
    let elementType
    elementType = (page.elements.length > 0) && page.elements.find(k => k.elementType === type)
    if(type === "TEXT"){
      return emptyText(elementType)
    } else {
      return elementType
    }
  }

  export function emptyText(itemText) {
    if(itemText.textElement.text === "" ) {
      itemText.textElement.text = "Escribe aquí tu texto ..."
    }

    return itemText
  }
  