import React, { Component } from 'react';
import Dialog from './GenericDialog'
import * as Icons from '../Utilities/ImgConfig'
import * as Utils from '../Utilities/Utils'


export default class SlideShot extends Component {

    componentWillMount() {
        this.timerEvents()
    }

	state = {
        carrousel: null,
        timer: this.props.timeSelected,
        paused: false,
        picCounter: 0
    };

	render() {
        const { open, idAlbum, token} = this.props
        const { carrousel, paused } = this.state

		return (
			<Dialog open={open} close={false} cross={this.close} customStyles='main-slide-shot-dialog'>
                { carrousel }
                <div className="slide-shot-dialog-botonera">
                    <div>
                        {!paused && <div> <img src={Icons.Pause} onClick={this.stopTimer} /> </div>}
                        {paused && <div> <img src={Icons.Play} onClick={this.playCarrousel} /> </div>}
                        <div> <img src={Icons.FullScreen} onClick={this.toggleFullScreen} /> </div>
                        <div> <img src={Icons.SpeakerLow} onClick={this.volumeDw} /> </div>
                        <div> <img src={Icons.SpeakerMax} onClick={this.volumeUp} /> </div>

                        <audio id='music-file' loop autoPlay controls={false}>
                            <source src={Utils.getMusic(idAlbum, token)} type="audio/mpeg"></source>
                            { 'Your browser does not support the audio element.' }
                        </audio>
                    </div>
                </div>
			</Dialog>
		)
    }

    
    close = () => {
        const { close } = this.props
        if (!((document.fullScreenElement !== undefined && document.fullScreenElement === null) || 
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen))
        ) {
                this.toggleFullScreen()
            }
        close()
    }

    stopTimer = () => {this.setState({paused: true}, this.playMusic)}
    playCarrousel = () => {this.setState({paused: false}, this.playEvent)}

    playEvent = () => {
        this.timerEvents()
        this.playMusic()
    }

    timerEvents = () => {
        const { allPhotos } = this.props
        const { picCounter, timer, paused } = this.state
        if(picCounter<=(allPhotos.length-1) && !paused) {
            setTimeout(()=>{
                if (!this.state.paused) {
                    this.nextPic(picCounter)
                }
            }, picCounter === 0 ? 0 : (timer*1000))
        }
    }

    nextPic = (picCounter) => {
        const { allPhotos, token, idAlbum } = this.props
        const { paused } = this.state
        this.setState({
            carrousel:
                <div className="content-slide-shot-dialog">
                        {allPhotos[picCounter].repositoryItem ?
                            <img src={Utils.getPhoto(allPhotos[picCounter].repositoryItem.id, token, false)} />:
                            <div>{'no img'}</div>
                        }                    
                </div>,
            picCounter: paused ? picCounter : picCounter+1
        }, ()=>this.timerEvents())
    }

    volumeUp = () => {
        if(document.getElementById('music-file').volume<1) {
            document.getElementById('music-file').volume+=0.1
        }
    }
    volumeDw = () => {
        if(document.getElementById('music-file').volume>0.1) {
            document.getElementById('music-file').volume-=0.1
        }
    }

    playMusic = () => {
        const { paused } = this.state
        if(paused){
            document.getElementById('music-file').pause()
        } else {
            document.getElementById('music-file').play()
        }
    }
  
    toggleFullScreen = () => {
        const elem = document.body
        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || 
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            if (elem.requestFullScreen) {
                elem.requestFullScreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }
}
	