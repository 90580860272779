import React, { Component } from 'react';
import Dialog from './GenericDialog'
import DialogContentTypeSub from './DialogContentTypeSub'
import * as Service from '../Utilities/Services'
import * as exportPdf from '../OtherResourses/exportPdf'
import * as Icons from '../Utilities/ImgConfig'
import { FormattedMessage } from 'react-intl';

export default class SuscriptionDialog extends Component {

	componentWillMount() {
		this.getAllInfo()
	}

	state = {
		plans: null,
		isSubscribed: null,
		typeSubs: null,
		idPlanSelected: null,
		planSelected: null,
		dataSubscribed: null
	};

	render() {
		const { open, cross, userData  } = this.props
		const { plans, typeSubs, dataSubscribed, planSelected, idPlanSelected } = this.state
		const dialogTypeSubs = typeSubs ? <DialogContentTypeSub cross={this.hideShowTypeDialogSub}
																open={typeSubs}
																userData={userData}
																planSelected={planSelected}
																idPlanSelected={idPlanSelected}
										  /> : null
		return (
			<Dialog open={open} cross={cross} customStyles='main-dialog-suscription'>
				{dialogTypeSubs}
				<div className='suscription-title'><FormattedMessage id="TITLE_DIALOG_SUBSCRIPTION" /></div>
                <div className='suscription-content'>
					{plans && plans.map((item, k)=> {
						return <div key={`suscription-item-${k}`} className={`suscription-element ${item.starred ? `suscription-element-favorite` : ``}`}>
							{item.starred ? <div className="suscription-element-img-favourite"><img src={Icons.FavouriteCircular}/></div>: <div className="suscription-element-img-favourite"/>}
							<div className='suscription-content-type'>{item.name}</div>
							<div className='suscription-content-advice'>{item.shortText}</div>
							<div className='suscription-content-info'>
								{item.description.map((item, k)=>{
									return <div key={`suscription-item-description-${k}`}>{item}</div>
								})}
							</div>
							
							<div className='suscription-content-cost'>
								{item.price}<label>{`€`}</label>
							</div>
							<div className='suscription-content-has-a-iva'>
								<FormattedMessage id="HAS_IVA" />
							</div>
							{dataSubscribed && dataSubscribed.plan &&
								<button className={`suscription-content-subscribe ${item.id === dataSubscribed.plan.id ? 'suscription-content-subscribe-active' : ''} `} 
										onClick={item.id === dataSubscribed.plan.id ? ()=>null : ()=>this.hideShowTypeDialogSub(item)}
								>
									{item.id === dataSubscribed.plan.id ? <FormattedMessage id="YOUR_SUBSCIPTION" /> : <FormattedMessage id="GET_PLAN" /> }
								</button>
							}
						</div>
					})}
				</div>
				<div className='suscription-bottom-text'>
					<div><FormattedMessage id="ALL_PLANS" /></div>
					<div><FormattedMessage id="ALL_PLANS_TEXT" /></div>
				</div>
			</Dialog>
		)
	}

	openPDf = () => {
		window.open(exportPdf.pdf);
	}

	hideShowTypeDialogSub = (planClicked) => {
		if(planClicked) {
			this.setState({
				typeSubs: !this.state.typeSubs,
				idPlanSelected: planClicked.id || null,
				planSelected: planClicked || null
			})
		} else {
			this.setState({typeSubs: !this.state.typeSubs}) 
		}
	}

	getAllInfo = () => {
		Service.getAllPlans()
			.then((response)=>{
				const plans = this.sepateDescription(response.data)
				this.setState({plans: plans})
			})
			.catch((error)=>{
				console.error('get_all_plans_ERROR -> ', error)
			})
		
		Service.isUserSubscribed()
			.then(response => {
				this.setState({
					isSubscribed: true,
					dataSubscribed: response.data
				})
			})
			.catch(error=>{
				console.error('is_user_suscribed_ERROR -> ', error)
			})
	}

	sepateDescription = (allPlans) => {
		allPlans.map((item, k)=>{
			allPlans[k].description = item.description.split('.')
		})
		return allPlans
	}
}
	