import React, { Component } from 'react';
import DraggableSpace from '../DraggableSpace'
import * as Utils from '../../Utilities/Utils'

export default class Template6 extends Component {

    render() {
      const { items, page, pagePosition, stateGemerics, methodToRun, reloadActualPages, isPreview } = this.props
      const ordedElements = Utils.orderArrayByParam(this.props.page.elements)
      
      return(
        <div key={`template-6-${pagePosition}-id_${page.id}`} className="main-template-vertical">
          <div className={`template-6-img-text `}>
            <DraggableSpace stateGemerics={stateGemerics}
                            items={(ordedElements[0].pictureElement.repositoryItemId ? [ordedElements[0].pictureElement] : null)}
                            page={page}
                            pagePosition={`${pagePosition}-${ordedElements[0].id}`}
                            methodToRun={methodToRun}
                            reloadActualPages={reloadActualPages}
                            isPreview={isPreview}
                            editSize={{height:400,width:300}}
                            classNamesEmpty={'dropContxt-empty-img-template6-half'}
            />
            {/* {isPreview ?
               <label className="template1-text-1">{ordedElements[1] && ordedElements[1].textElement && ordedElements[1].textElement.text}</label> : */}
               <textarea key={`template-5-${pagePosition}-text-area-id_${page.id}`} className={`template-6-text scrollbar ${isPreview ? 'template-preview' : ''}`}
                         defaultValue={ordedElements[1] && ordedElements[1].textElement && Utils.emptyText(ordedElements[1]).textElement.text}
                         disabled={isPreview ? true : false} onBlur={(data)=>this.compileAllText(data, ordedElements[1])} />
            {/* } */}
          </div>
          <div className={`template-6-img-text `}>
            {/* {isPreview ? 
              <label className="template1-text-1">{ordedElements[2] && ordedElements[2].textElement && ordedElements[2].textElement.text}</label> : */}
              <textarea key={`template-5-${pagePosition}-text-area-id_${page.id}`} className={`template-6-text scrollbar ${isPreview ? 'template-preview' : ''}`}
                        defaultValue={ordedElements[2] && ordedElements[2].textElement && Utils.emptyText(ordedElements[2]).textElement.text}
                        disabled={isPreview ? true : false} onBlur={(data)=>this.compileAllText(data, ordedElements[2])} />
            {/* } */}
            <DraggableSpace stateGemerics={stateGemerics}
                            items={(ordedElements[3].pictureElement.repositoryItemId ? [ordedElements[3].pictureElement] : null)}
                            page={page}
                            pagePosition={`${pagePosition}-${ordedElements[3].id}`}
                            methodToRun={methodToRun}
                            reloadActualPages={reloadActualPages}
                            isPreview={isPreview}
                            editSize={{height:400,width:300}}
                            classNamesEmpty={'dropContxt-empty-img-template6-half'}
            />
          </div>
        </div>
      );
    }
    compileAllText = (data, element) => {
      const { updateText } = this.props
      updateText(data, element)
   }

}
