import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
import { DragDropContext } from 'react-beautiful-dnd'
import * as Service from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import * as Utils from '../../Utilities/Utils'
import BarImgSelector from './BarImgSelector'
import PageComponent from '../../components/PageComponent'
import Settings from '../AlbumVital/Settings'
import ImageEditorActions from '../../Utilities/ImageEditorActions'
import PreviewDialog from '../../Dialogs/PreviewDialog'
import NotifyDialog  from '../../Dialogs/NotifyDialog'
import ViewMenu from '../AlbumVital/ViewMenu'
import SeeAllPhotos from './SeeAllPhotos'
import HeaderBook from './HeaderBook'
import PrinterMenu from './PrinterMenu'

const move = (source, destination, droppableSource, droppableDestination) => {
   const sourceClone = Array.from(source);
   const destClone = Array.from(destination);
   const indexMovedObject = droppableSource.index;
   const element = sourceClone.find(k => k.id === indexMovedObject);
   const newObject = {
      id: element && element.id || 0,
      content: element && element.source || "D&D NOT WORK"
   };

   destClone.splice(droppableDestination.index, 0, newObject);

   return destClone;
};

class MainVitalBook extends Component {

   componentWillMount() {
      this.getInfo()
   }

   constructor(props) {
      super(props);
      this.state = {
         album: this.props.router.location.state.albumSelected,
         owner: this.props.router.location.state.albumSelected.owner,
         backgroundSkin: this.props.router.location.state.albumSelected.skin,
         defaultCollectionId: this.props.router.location.state.albumSelected.defaultCollection.id,
         imgTest: null,
         allFbImgSelected: null,
         allInstagramImg: null,
         allLocalImg: null,
         allGoogleDriveImg: null,
         pagesAlbum: null,
         menuOpt: false,
         allBackgroundSkins: null,
         classNameList: '',
         showPreviewAlbum: false,
         showNotifyDialog: false,
         message: '',
         acceptFunctDialog: null,
         filterActive: null,
         allColectionsUser: null,
         albumsList: null,
         allItemImg: null,

         //LOGIN STATES
         logFB: false,

         //D&D
         itemLeft: null,
         itemRight: null,
         selected : [],
         templatesData: null,
         templateSelected: [],
         templateLeft: null,
         templateRight: null
      }
   }

   id2List = {
      droppableFacebook: 'allFbImgSelected',
      droppableLocal: 'allLocalImg',
      droppableInstagram: 'allInstagramImg',
      droppableShared: 'allSharedphotos',
      droppableTemplate: 'droppableTemplate',
      droppableGoogleDrive: 'allGoogleDriveImg'
   };

   getList = id => this.state[this.id2List[id]];

   onDragEnd = result => {
      const { templateLeft, templateRight, pagesAlbum } = this.state
      const { source, destination } = result
      if (source.droppableId !== (destination && destination.droppableId)){
         if(source.droppableId === "droppableTemplate" && !(templateLeft && templateRight)) {
            switch(result.destination && result.destination.droppableId){
               case 'droppableAlbumPage-template-left':
                  pagesAlbum && pagesAlbum[0] && this.updateBookPageTemplate(source.index, pagesAlbum[0])
                  break;
               case 'droppableAlbumPage-template-right':
                  pagesAlbum && pagesAlbum[1] && this.updateBookPageTemplate(source.index, pagesAlbum[1])
                  break;
               default:
                  console.error('No se puede sobreescribir el template')
            }
         } else {
            if (!destination) {return; }
            if (source.droppableId !== destination.droppableId) {
               const draggElement = move(
                  this.getList(source.droppableId) || [],
                  this.getList(destination.droppableId) || [],
                  source,
                  destination
               );

               const idElement = parseInt(result.destination.droppableId.match(/\d+/))
               if (result.destination.droppableId.startsWith('droppableAlbumPage-left'))
                  this.setState({itemLeft: draggElement}, ()=>this.insertPictureOnPage(result.source, pagesAlbum[0].elements.find(k => (k.elementType === 'PICT' && k.id === idElement))))
               else if (result.destination.droppableId.startsWith('droppableAlbumPage-right'))
                  this.setState({itemRight: draggElement}, ()=>this.insertPictureOnPage(result.source, pagesAlbum[1].elements.find(k => (k.elementType === 'PICT' && k.id === idElement))))
               else
                  console.error('No se puede sobreescribir la imagen')
            }
         }
      }
   }

   _pageComponentVar = (templateLeft, templateRight, itemLeft, itemRight, pagesAlbum) => {
      const { albumsList, menuOpt, album } = this.state
      const { intl } = this.props
      let tempPages = []
      pagesAlbum.map((item, k) => {
         tempPages.push(
            <div id={`album-context-page-${k%2===0?'left':'right'}`} className='page-component album-context-page' key={`page-album-${k}`}>
               <PageComponent templateSelected={(item.formatPage && item.formatPage.id) || (k===0?templateLeft:templateRight) || null} 
                              pagePosition={k===0?"left":"right"} methodToRun={this.updateEditedPictureElement} createElement={this.createElement}
                              updateBookPageTemplate={this.updateBookPageTemplate} updateText={this.updateText} reloadActualPages={this.reloadActualPages}
                              stateGemerics={this.props.router.location.state} items={k===0?itemLeft:itemRight} page={item} isPreview={false} />
            </div>
         )
      })

      return (
         <div id="album-context-D" className={`album-context`} style={{flex: 9}}>
            <HeaderBook deleteAction={()=>this.displayNotifyDialog(intl.formatMessage({id:'MSG_DEL_PAGES'}), this.deletePages)}
                        addTwoPagesOnAlbum={this.insertTwoPagesOnTarget}
                        displayPreview={this.displayPreview}
                        albumsList={albumsList}
                        albumSelectedId={album.id}
                        changeAlbum={this.changeAlbum}
            />
            <div className={`album-context-pages-paginator ${menuOpt ? 'album-context-album-size-with-menu':'album-context-album-size'} `}>
               <div className='album-context-nav-arrow-back' onClick={pagesAlbum[0] && (pagesAlbum[0].number > 1) ? this.backPage : ()=>null}>
                  <img src={Icons.ArrowFullWhite}/>
               </div>
               <div className='album-context-templates'>
                  <div className='album-context-pages'>
                     <div id='id-album-context-pages'>
                        {tempPages}
                     </div>
                  </div>
                  <div className='album-context-paginator-button'>
                     <div className='album-context-paginator-button-arrow-back'>
                        {pagesAlbum[0] && (pagesAlbum[0].number > 1) && <img src={Icons.ArrowBack} onClick={this.backPage} />}
                     </div>
                     <div className='album-context-paginator-numbers'>   
                        <input placeholder={`${pagesAlbum[0] && pagesAlbum[0].number} - ${(pagesAlbum[1] && pagesAlbum[1].number)}`} 
                               onKeyPress={this.naviagteToPage}/>
                        <label>{` de ${album.totalNumberOfBookPages}`}</label>
                     </div>
                     <div className='album-context-paginator-button-arrow-next'>
                        {!(pagesAlbum[1] && pagesAlbum[1].theLastBookPage) && <img src={Icons.ArrowNext} onClick={this.nextPage} />}
                     </div>
                  </div>
               </div>
               <div className='album-context-nav-arrow-next' onClick={pagesAlbum[1] && pagesAlbum[1].theLastBookPage ? ()=>null : this.nextPage}>
                  <img src={Icons.ArrowFullWhite}/>
               </div>
            </div>
         </div>
      )
   }

   render() {
      const { album, menuOpt, allBackgroundSkins, showPreviewAlbum, showNotifyDialog, pagesAlbum, itemLeft, itemRight,
              backgroundSkin, templateLeft, templateRight, message, acceptFunctDialog, allFbImgSelected, allInstagramImg, allSharedphotos,
              allLocalImg, classNameList, owner, filterActive, templatesData, allColectionsUser, defaultCollectionId, allItemImg, allGoogleDriveImg } = this.state
      const { intl } = this.props;
      const previewAlbum = showPreviewAlbum ? <PreviewDialog open={showPreviewAlbum} close={this.displayPreview} backgroundSkin={backgroundSkin} 
                                                             pagesAlbum={pagesAlbum} itemLeft={itemLeft} itemRight={itemRight} templateLeft={templateLeft} 
                                                             templateRight={templateRight} stateGemerics={this.props.router.location.state}
                                                             backPage={this.backPage} nextPage={this.nextPage} token={Utils.getToken()}
                                                             albumMaxPage={album.totalNumberOfBookPages} /> : null
      const notifyDialog = showNotifyDialog ? <NotifyDialog open={showNotifyDialog} close={this.displayNotifyDialog} accept={acceptFunctDialog} msg={message}/> : null

      return (
         <div>
            <div className="main-content-vital-book"> 
               {notifyDialog}
               {previewAlbum}
               <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                  <div className={`main-vertical-tap-bar ${menuOpt ? 'main-vertical-tap-bar-show':''}`}>
                     <BarImgSelector
                        closeMenu={this.closeMenu}
                        optionMenu={this.menu}
                        menuOpt={menuOpt}
                        titles={[intl.formatMessage({id:'EDIT_CONTENT'}), intl.formatMessage({id:'EDIT_PREVIEW'}), intl.formatMessage({id:'SETTINGS'})/*, intl.formatMessage({id:'PRINTER'})*/]}
                        imgMenu={[Icons.Editar , Icons.Presentacion, Icons.Ajustes/*, Icons.PrinterSmall*/]}
                        elements={[
                           <SeeAllPhotos allFbImgSelected={allFbImgSelected} 
                                         allInstagramImg={allInstagramImg}
                                         allSharedphotos={allSharedphotos}
                                         allLocalImg={allLocalImg}
                                         classNameList={classNameList}
                                         owner={owner}
                                         filterActive={filterActive}
                                         templatesData={templatesData}
                                         activeFilter={this.activeFilter}
                                         showListImg={this.showListImg}
                                         allColectionsUser={allColectionsUser}
                                         getAllCollections={this.getAllCollections}
                                         defaultCollectionId={defaultCollectionId}
                                         callGetAllRepositoryItems={()=>this.callGetAllRepositoryItems(defaultCollectionId)}
                                         selectCollection={this.selectCollection}
                                         allItemImg={allItemImg}
                                         displayNotifyDialog={this.displayNotifyDialog}
                                         allGoogleDriveImg={allGoogleDriveImg}
                           />,
                           <ViewMenu changeSkin={this.changeSkin} 
                                     skins={allBackgroundSkins}
                                     token={Utils.getToken()}
                                     idAlbum={album.id} 
                                     updateDataAlbum={this.updateDataAlbum}
                                     videoName={album.videoName}
                                     music={album.music} 
                                     backgroundSkinSelected={backgroundSkin}
                                     deleteAudioElement={this.deleteAudioElement}
                           />,
                           <Settings allBackgroundSkins={allBackgroundSkins}
                                     changeSkin={this.changeSkin}
                                     updateDataAlbum={this.updateDataAlbum}
                                     backgroundSkinSelected={backgroundSkin}
                                     token={Utils.getToken()} 
                                     album={album}
                                     reloadActualPagesTest={this.reloadActualPagesTest}
                                     allColectionsUser={allColectionsUser}
                                     getAllCollections={this.getAllCollections}
                                     back={this.backHome}
                           />/*,
                           <PrinterMenu />*/
                        ]}
                     />
                  </div>
                  {pagesAlbum && this._pageComponentVar(templateLeft, templateRight, itemLeft, itemRight, pagesAlbum)}
               </DragDropContext>
            </div>
         </div>
      );
   }

   getInfo = () => {
      this.pagination(1)
      this.getAllinfoTemplatesReq()
      this.skinsVitalBook()
      this.callGetAllRepositoryItems(this.state.defaultCollectionId)
      this.getAllCollections()
      this.redirectToAlbumPart()
   }

   deleteAudioElement = () => {
      const { album } = this.state
      Service.deleteAudioAlbum(album.id)
         .then(response=> {
            this.updateDataAlbum()
         })
         .catch(error=> {console.error('DELETE_AUDIO_ELEMENT_ERROR -> ', error)})
   }

   naviagteToPage = (event) => {
      const { album } = this.state
      let pageNum = parseInt(event.target.value)
      if(album.totalNumberOfBookPages >= pageNum){
         if(event.key == 'Enter'){
            if(pageNum%2===0)
               pageNum = pageNum-1
      
            this.goToNumberPage(pageNum)
         }
      } else {
         console.error("Pagina no existente")
      }
   }
   backHome = () => {
      this.props.router.history.push('/myapps')
   }
   changeAlbum =  (selectedAlbum) => {
      this.setState({
         album: selectedAlbum,
         owner: selectedAlbum.owner,
         backgroundSkin: selectedAlbum.skin,
         defaultCollectionId: selectedAlbum.defaultCollection.id,
         imgTest: null,
         allFbImgSelected: null,
         allInstagramImg: null,
         allLocalImg: null,
         allGoogleDriveImg: null,
         allSharedphotos: null,
         pagesAlbum: null,
         menuOpt: false,
         allBackgroundSkins: null,
         classNameList: '',
         showPreviewAlbum: false,
         showNotifyDialog: false,
         message: '',
         acceptFunctDialog: null,
         filterActive: null,
         allColectionsUser: null,
         albumsList: null,
         allItemImg: null,

         //LOGIN STATES
         logFB: false,

         //D&D
         itemLeft: null,
         itemRight: null,
         selected : [],
         templatesData: null,
         templateSelected: [],
         templateLeft: null,
         templateRight: null
      }, ()=>this.getInfo());
   }

   selectCollection = (idRepo) => {
      Service.getAllRepositoryItems(idRepo)
         .then(response => {
            this.callGetAllRepositoryItems(idRepo)
            this.updateDataAlbum(null, null, idRepo)
            this.activeFilter("", null)
         })
         .catch(error => {
            console.error('GET_ALL_REPOSITORY_ITEMS_ERROR -> ', error)
         })
   }

   activeFilter = (filterClick, viewUsedImg) => {
      const { filterActive } = this.state
      if(filterActive === filterClick) {
         this.setState({filterActive: null})
         this.apilateFiltredImgs("ALLIMG")
      } else if(filterClick === ""){
         this.setState({filterActive: null})
         this.apilateFiltredImgs("ALLIMG")
      } else {
         this.setState({filterActive: filterClick})
         this.apilateFiltredImgs(viewUsedImg)
      }
   }
  
   apilateFiltredImgs = (isVisible) => {
      const { allItemImg } = this.state
      let filterImg = []
      if(isVisible === "ALLIMG") {
         filterImg=allItemImg
      } else {
         allItemImg.map(index => {
            if(index.used === isVisible){
               filterImg.push(index)
            }
         })
      }
      this.groupImgByProcedence(filterImg)
   }

   deletePages = () => {
      const { pagesAlbum } = this.state
      Service.deleteTwoPages(pagesAlbum[0].id)
         .then(response => {
            this.reloadActualPages()
            this.displayNotifyDialog()
         })
         .catch(error=>{
            console.error("delete_two_pages_error -> ", error)
         });
   }

   displayPreview = () => {this.setState({showPreviewAlbum: !this.state.showPreviewAlbum})}
   displayNotifyDialog = (msg, func) => {
      this.setState({
         showNotifyDialog: !this.state.showNotifyDialog,
         message: msg,
         acceptFunctDialog: func || null
      })
   }
   showListImg = (selection) => {
      const { classNameList } = this.state
      this.setState({classNameList: classNameList === selection ? '' : selection})
   }

   /* Método que se llama al hacer un update de la imagen en el componente MyEditorImage */
   updateEditedPictureElement = (base64File, pictureElementId) => {
      let file = ImageEditorActions.convertBase64ToFile(base64File)
      let multipart = new FormData();
      multipart.append('file', file);
      Service.updateEditedPictureElement(pictureElementId, multipart)
         .then( response => {
            this.reloadActualPages()
            this.callGetAllRepositoryItems(this.state.album.defaultCollection.id)
         })
         .catch(error => {
            console.error("updateEditedPictureElement error -> ", error)
         })
   }
 
   /* ###### PAGINATION BOOK */
   insertTwoPagesOnTarget = () => {
      const { album, pagesAlbum} = this.state
      
      Service.insertTwoPagesOnSpecificPage(album.id, (pagesAlbum[1].number+1))
         .then(response=>{
            this.pagination(1)
         })
         .catch(error=>{console.error('INSERT_TWO_PAGES_ON_TARGET_ERROR -> ', error)})
   }
   nextPage = () => {this.pagination(1)}
   reloadActualPages = () => {this.pagination(-1)}
   backPage = () => {this.pagination(-3)}

   reloadActualPagesTest = () => {
      const { pagesAlbum } = this.state
      const firstpage = -(pagesAlbum[pagesAlbum.length-1].number-1)
      this.pagination(firstpage)
   }

   /* ###### FUNCTION CHANGE BACKGROUND SKIN ###### */
   changeSkin = (skinImg) => {this.setState({backgroundSkin: skinImg}, this.updateDataAlbum(skinImg, null))}
   /* ###### FUNCTION CHANGE BACKGROUND SKIN ###### */

   menu = () => {
      this.callGetAllRepositoryItems(this.state.album.defaultCollection.id)
      this.setState({menuOpt: true})
   }

   closeMenu = () => {
      this.setState({menuOpt: false})
   }

   /*MÉTODOS EJEMPLO PARA CREAR ENTIDADES DEL ÁLBUM*/
   getAllinfoTemplatesReq = () => {
      Service.getAllInfoTemplates()
         .then(response => {
            this.setState({templatesData: response.data})
         })
         .catch(error => {console.error("getInfo error -> ", error)})
   }

   callGetAllRepositoryItems = (idDefaultCollection) => {
      Service.getAllRepositoryItems(idDefaultCollection)
         .then(response => {
            this.setState({
               allItemImg: response.data.repositoryItemDto,
               defaultCollectionId: idDefaultCollection
            }, ()=>this.groupImgByProcedence(response.data.repositoryItemDto))
         })
         .catch(error => {console.error("Get_All_repo_items_error -> ", error)})
   }

   groupImgByProcedence = (allUserImg) => {
      let allFbImgSelected = []
      let allInstagramImg = []
      let allLocalImg = []
      let allGoogleDriveImg = []
      let allSharedphotos = []
      allUserImg.map(item=>{
         switch(item.origin){
            case "FACEBOOK":
               allFbImgSelected.push(item)
               break;
            case "INSTAGRAM":
               allInstagramImg.push(item)
               break;
            case "LOCAL":
               allLocalImg.push(item)
               break;
            case "SHARED":
               allSharedphotos.push(item)
               break;
            case "GOOGLEDRIVE":
               allGoogleDriveImg.push(item)
               break;
         }
      })
      
      this.setState({
         allFbImgSelected: allFbImgSelected,
         allInstagramImg: allInstagramImg,
         allLocalImg: allLocalImg,
         allSharedphotos: allSharedphotos,
         allGoogleDriveImg: allGoogleDriveImg
      })
   }
   getAllBookPages = () => {
      Service.getAllAlbumPages(this.state.album.id)
         .then(response => {})
         .catch(error => {console.error(" error -> ", error)})
   }  

   addTwoPagesOnAlbum = () => {
      const { album } = this.state

      Service.createtNextTwoPages(album.id)
         .then(response => {
            this.pagination(1)
         })
         .catch(error => {console.error("ADDNextTwoPages error -> ", error)})
   }

   pagination = (backNext) => {
      /* @backNext, VALUE 1 get next two pages / VALUE -2 get before two pages */
      const { pagesAlbum } = this.state
      const pageNum=pagesAlbum ? pagesAlbum[pagesAlbum.length-1].number+backNext : 1
      this.goToNumberPage(pageNum)
   }

   goToNumberPage = (pageNum) => {
      const { album } = this.state
      Service.getNextTwoPages(album.id, pageNum)
      .then(response => {
         this.setState({
            pagesAlbum: response.data,
            templateLeft: null,
            templateRight: null,
            itemLeft: null,
            itemRight: null
         }, ()=>this.loadElements(response.data))
      })
      .catch(error => {
         console.error("getNextTwoPages error -> ", error)
      })
   }
   
   loadElements = (data) => {
      data.map(itemPage => {
         itemPage.elements && (itemPage.elements.length > 0) &&
         itemPage.elements.map(itemElem=>{
            if(itemElem.elementType==="PICT" && itemElem.pictureElement && itemElem.pictureElement.repositoryItemId) {
               this.loadImgOnElement(itemPage, itemElem)
            } 
         })
      })
   }
   
   loadImgOnElement = (itemPage, element) => {
      Service.getImageFromId(element.id)
         .then(response=> {
            if(response.data.content && (response.data.content.length > 0)){
               const id = response.data.content[0].repositoryItem.id
               const origin = response.data.content[0].repositoryItem.origin
               const file = response.data.content[0].repositoryItem.source
               this.setState({
                  [itemPage.number%2===0?'itemRight':'itemLeft']: [{
                     id: id,
                     content: file,
                     origin: origin
                  }]
               })
            }
         })
         .catch(error=>{console.error("No hay elemento", error)})
   }
   updateText = (text, element) => {
      const request = {
         id: element && element.textElement && element.textElement.id,
         text: text.target.value,
         externalResource: false
      }
      
      Service.updateTextElement(request)
         .then(response => {
            this.reloadActualPages()
         })
         .catch(error=>{console.error("errorUpdateTextElement -> ", error)})
   }

   skinsVitalBook = () => {
      Service.getAllSkinsBook()
         .then(response => {
            if(this.state.backgroundSkin !== null) {
               this.setState({allBackgroundSkins: response.data}, this.changeSkin(response.data.find(k => k.id === this.state.album.skin.id)))
            } else {
               this.setState({allBackgroundSkins: response.data}, this.changeSkin(response.data[0]))
            }
         })
         .catch(error => {
            console.error("getAllSkinsBook error -> ", error)
         })
   }
   getInfoAlbum = (idAlbum) => {
      Service.getAlbumById(idAlbum)
         .then(response => {
            this.setState({album: response.data})
         })
         .catch(error => {console.error("GET_ALBUM_BY_ID_ERROR -> ", error)})
   }

   updateDataAlbum = (data, name, idRepo) => {
      new Promise((resolve, reject)=>{
         const album = {
            id: this.state.album.id,
            name: name,
            owner: {
               id: this.state.album.owner.id
            },
            skin: data && {
               id: data.id,
               source: data.source
            },
            defaultCollection: idRepo && {
               id: idRepo
            }
         }

         Service.updateDataAlbum(album)
            .then(response => {
               this.setState({album: response.data})
               resolve(true)
            })
            .catch(error => {
               reject(error)
               console.error("updateDataAlbum error -> ", error)
            })
      })
   }

   /* #### TESTING ELEMENTS SERVICE TO INSTERT PAGE ### */
   insertPictureOnPage = (itemImg, elementPage) => {
      const request = {
         id: elementPage.pictureElement.id,
         altText: null,
         element: {
            id: elementPage.id
         },
         text: null, 
         externalResource: false,
         repositoryItem: {
            id: itemImg.index
         }
      }
      Service.updateElementSetImg(request)
         .then(response => {
            this.reloadActualPages()
         })
         .catch(error => {
            console.error(`insertElementPage -->`,error)
         })
   }

   updateBookPageTemplate = (templateSelectedId, page) => {
      let bookPage = {
         id: page && page.id,
         album: {
            id: this.state.album.id
         },
         bookCover: false,
         number: page && page.number,
         formatPage: {
            id: templateSelectedId
         }
      }
      Service.updateBookPage(bookPage)
         .then(response => {
            if(templateSelectedId > 0) {
               const objTemplatSelect = this.state.templatesData.find(k => k.id === templateSelectedId)
               objTemplatSelect.distribution.map(item=>{
                  this.createElement(bookPage, item.type, item.position)
               })
            } else {
               this.reloadActualPages()
            }
         })
         .catch(error => {
            console.error("updateBookPage error -> ", error)
         })
   }
   createElement = (bookPage, typeEle, position) => {
      const request = {
         elementType: typeEle,
         bookPageId: bookPage.id,
         position: position
      }
      Service.createElementOnPage(request)
         .then(response => {
            this.reloadActualPages()
         })
         .catch(error => {
            console.error("create Element on Page error -> ", error)
         })
   }
   /* #### TESTING> ELEMENTS SERVICE TO INSTERT PAGE ### */

   getAllCollections = () => {
      const { owner } = this.state
      
      Service.getAllUserCollections(owner.id)
         .then(response => {
            this.setState({allColectionsUser: response.data.content})
         })
         .catch(error => {
            console.error("GET_ALL_USER_COLLECTIONS_ERROR -> ", error)
         })
   }

   getAllAlbumsUser = () => {
      Service.getUserAlbums()
   }

   redirectToAlbumPart = () => {
      Service.getUserAlbums(localStorage.getItem('userMail'))
         .then((response)=>{
            this.setState({albumsList: response.data.content})
         })
         .catch((error)=>{
            console.error("GET_USER_ALBUMS_ERROR -> ", error)
         })
    }
}

export default injectIntl(MainVitalBook);