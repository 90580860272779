import React, { Component } from 'react';
import Dialog from './GenericDialog'
import * as Icons from '../Utilities/ImgConfig'
import * as Services from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import CustomInput from '../components/CustomInput'
import FlotingDialog from '../Dialogs/FlotingDialog'
import { FormattedMessage, injectIntl } from 'react-intl';


class AlbumsDialog extends Component {

	componentWillMount() {
		this.loadAlbumsList()
	}

	state = {
		contextView: null,
		nameAlbumInput: null,
		accept: null,
		showDialog: null,
		msgError: null,
		typeMessage: null,
		albumSelected: null,
	}
	
	render() {
		const { contextView, accept, msgError, typeMessage, showDialog } = this.state
		const { open, close } = this.props
		const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null

		return (
			<Dialog open={open} accept={accept} cross={close} customStyles='main-dialog-list-albums'>
				{previewFlotingDialog}
				{contextView}
			</Dialog>
		)
	}

	loadAlbumsList = () => {
		this.setState({
			contextView: 
				<div>
					<div className="dialog-list-albums-title"><FormattedMessage id="MY_VITALBOOK" /></div>
					<div className="dialog-list-albums">
						<div>
							<img src={Icons.AddLogoDegraded} onClick={this.createNewAlbumName}/>
							<label><FormattedMessage id="NEW_ALBUM" /></label>
						</div>
						<div>
							<img src={Icons.Llibre_1} onClick={this.selectAlbumName}/>
							<label><FormattedMessage id="MY_ALBUMS" /></label>
						</div>
					</div>
				</div>
		})
	}

	selectAlbumName = () => {
		const { albums, intl } = this.props

		this.setState({
			accept: ()=>this.goToAlbum(this.state.albumSelected),
			contextView:
				<div className="dialog-list-myalbums">
					<div><FormattedMessage id="SELECT_ALBUM" /></div>
					<label className="dialog-list-myalbums-sub-title"><FormattedMessage id="WHAT_ALBUM" /></label>
					<select className="dialog-list-myalbums-select" onChange={this.selectAlbumToGo}>
						<option key={`album-element-00`} value={-1}>{intl.formatMessage({id:'SELECT_ALBUM'})}</option>
						{albums && albums.map((item, k)=>{
							return <option key={`album-element-${k}`} value={item.id}>{item.name}</option>
						})}
					</select>
				</div> 
		})
	}
	selectAlbumToGo = (data) => {
		const { albums } = this.props
		const albumSelected = albums.find(k => k.id === parseInt(data.target.value))
		this.setState({albumSelected: albumSelected})
	}

	createNewAlbumName = () => {
		const { showDialog, nameAlbumInput } = this.state
		const { intl } = this.props

		this.setState({
			accept: this.createNewAlbum,
			contextView:
				<div className="dialog-list-new-album">
					<div><FormattedMessage id="CREATE_ALBUM" /></div>
					<label className="dialog-list-new-album-sub-title"><FormattedMessage id="WHAT_NAME" /></label>
					<CustomInput addClassName={`home-content-login-input ${nameAlbumInput ? '': 'home-content-login-input-empty'} ${showDialog ? `custom-input-element-error` :''}`} 
								 placeholder={intl.formatMessage({id:'ALBUM_PH'})} height='5vh' width='-webkit-fill-available' methodToRun={(data) => this.setState({nameAlbumInput: data})} />
					<label className="dialog-list-new-album-remember"><FormattedMessage id="REMEMBER" /></label>
				</div>
		})
	}

	createNewAlbum = () => {
		const { allUserInfo, intl } = this.props
		const { nameAlbumInput } = this.state
		const album = {
			name: nameAlbumInput,
			owner: {
				id: allUserInfo.id
			},
		}
		if(nameAlbumInput && nameAlbumInput!== "") {
			Services.createAlbum(album)
			.then(response => {
				this.goToAlbum(response.data);
			})
			.catch(error => {
				this.setState(
					{
						msgError: `${error.response && error.response.data && error.response.data.message}`, 
						typeMessage: 'error' , showDialog: true
					}, Utils.clearError(this))
				console.error('Create_Album_ERROR -> ', error)})
		} else {
			this.setState(
				{
					msgError: `${intl.formatMessage({id:'MSG_ERROR_ALBUM_DIALOG_EMPTY_INPUT'})} ${nameAlbumInput ? '' : 'album' }`, 
					typeMessage: 'error' , showDialog: true
				}, Utils.clearError(this))
		}
	}

	goToAlbum = (album) => {
		const { dataLoginFacebook, push, intl } = this.props
		const albumSelected = album
		if (!(albumSelected === '-1' || albumSelected === null || albumSelected === undefined))
			push('/album', { albumSelected: albumSelected, dataLoginFacebook: dataLoginFacebook })
		else
			this.setState(
				{
					msgError: intl.formatMessage({id:'NO_SELECTED_VALUE'}), 
					typeMessage: 'error' , showDialog: true
				}, Utils.clearError(this))
	}

}
	
export default injectIntl(AlbumsDialog);