import React from 'react';
import { connect } from 'react-redux';

import { injectIntl, FormattedMessage, IntlProvider } from 'react-intl';
import html2pdf from 'html2pdf.js'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CloseXPurple } from '../../Utilities/ImgConfig';

import * as Services from '../../Utilities/Services'

import {
  // fetchFormConfig,
  updateFormValue,
  storeValues,
  // loadInitialState,
  removeStoreKey,
  setFormStep,
  nextStep,
  previousStep
} from '../../Redux/Actions/documentFormActions';

const TypedInput = injectIntl(
  ({ field = {}, value, onChange, type, name, checked, intl }) => (
    <input
      placeholder={
        field.summary ? intl.formatMessage({ id: field.summary }) : ''
      }
      name={name || field.id}
      {...{ value, onChange, type, checked }}
    />
  )
);
const StringInput = props => <TypedInput type="text" {...props} />;
const NumberInput = props => <TypedInput type="number" {...props} />;

const ListInput = injectIntl(
  connect(
    ({ documentForm: { step, values } }) => ({
      values: values[step]
    }),
    { updateFormValue }
  )(
    class extends React.Component {
      state = {
        values: []
      };

      componentDidMount() {
        const { values } = this.props;

        if (!values) {
          this.addNewItem();
        } else {
          this.setState({ values });
        }
      }

      componentDidUpdate(prevProps) {
        const { values } = this.props;
        const { values: prevValues } = prevProps;

        if (JSON.stringify(values) === JSON.stringify(prevValues)) {
        }
      }

      addNewItem = event => {
        event && event.preventDefault();

        const { values } = this.state;
        this.setState({ values: values.concat(['']) });
      };

      removeItem = event => {
        event && event.preventDefault();

        const {
          field: { id },
          updateFormValue
        } = this.props;
        const { name } = event.target;
        const { values } = this.state;

        values.splice(name, 1);
        updateFormValue(id, values);
        this.setState({ values });
      };

      render() {
        const {
          field: { id, field },
          intl,
          updateFormValue
        } = this.props;
        const { values } = this.state;

        return (
          <div className="FillableDocument__Form__ListInput">
            {values.map((value, i) => {
              const elementId = `${id}_${i}`;
              return (
                <div
                  className="FillableDocument__Form__ListInput-wrapper"
                  key={elementId}
                >
                  <img
                    name={i}
                    src={CloseXPurple}
                    title={intl.formatMessage({
                      id: 'DOCUMENT_FORM_LIST_INPUT_REMOVE_ITEM'
                    })}
                    alt="close"
                    onClick={this.removeItem}
                  />
                  <InputWrapper
                    id={elementId}
                    overrideAction
                    onChange={e => {
                      const { value } = e.target;

                      values[i] = value;
                      updateFormValue(id, values);
                    }}
                    {...{ field, value }}
                  />
                </div>
              );
            })}
            <button
              className="FillableDocument__Form__ListInput-add"
              onClick={this.addNewItem}
            >
              <FormattedMessage id="DOCUMENT_FORM_LIST_FIELD_ADD_ITEM" />
            </button>
          </div>
        );
      }
    }
  )
);

const ComposedInput = class extends React.Component {
  state = { value: {} };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ value });
    }
  }

  render() {
    const {
      field: { id, fields },
      onChange
    } = this.props;
    const { value } = this.state;

    return (
      <div>
        {fields.map(f => (
          <InputWrapper
            key={f.id}
            field={f}
            value={value[f.id]}
            onChange={event => {
              event.preventDefault();

              const newValue = {
                ...value,
                [f.id]: event.target.value
              };

              this.setState({ value: newValue });
              onChange({
                ...event,
                target: {
                  name: id,
                  value: newValue
                }
              });
            }}
          />
        ))}
      </div>
    );
  }
};

const SelectInput = injectIntl(
  ({ field: { id, summary, options }, value, onChange, intl }) => (
    <div>
      <h5 style={{ margin: '10px 15px' }}>
        <FormattedMessage id={summary} />
      </h5>
      <select name={id} {...{ onChange, value }}>
        <option />
        {options.map(v => (
          <option key={v} value={v}>
            {intl.formatMessage({ id: v })}
          </option>
        ))}
      </select>
    </div>
  )
);

const RadioInput = injectIntl(
  ({ field: { options, id }, value, onChange, intl }) =>
    options.map(({ label, value: v }) => (
      <label key={label}>
        <TypedInput
          type="radio"
          key={label}
          name={id}
          checked={value === v.toString()}
          {...{ value: v, onChange }}
        />
        {intl.formatMessage({ id: label })}
      </label>
    ))
);

const DateInput = injectIntl(
  connect(
    ({ locale: { key } }) => ({ locale: key }),
    { updateFormValue }
  )(({ field: { id, summary }, value, locale, updateFormValue, intl }) => (
    <DatePicker
      {...{ locale }}
      className="FillableDocument__Form__datePicker"
      selected={value}
      showMonthDropdown
      showYearDropdown
      placeholderText={intl.formatMessage({ id: summary })}
      dateFormat={'dd/MM/yyyy'}
      onChange={v => updateFormValue(id, v.getTime())}
    />
  ))
);

const inputComponents = {
  string: StringInput,
  number: NumberInput,
  select: SelectInput,
  radio: RadioInput,
  date: DateInput,
  composed: ComposedInput,
  list: ListInput
};

const InputWrapper = props => {
  const { type } = props.field;
  return Object.keys(inputComponents).includes(type) ? (
    React.createElement(inputComponents[type], props)
  ) : (
    <span>TODO</span>
  );
};

const Input = props => {
  const { id, title } = props.field;

  return (
    <span key={id}>
      <h4 style={{ margin: '10px 15px' }}>
        <FormattedMessage id={title} />
      </h4>
      <InputWrapper {...props} />
    </span>
  );
};

class Form extends React.Component {
  isFirstStep = () => this.props.stepIndex === 0;
  isLastStep = () =>
    this.props.stepIndex ===
    this.props.fields.filter(({ extension }) => !extension).length - 1;

  submitForm = form => {
    const { submitEvent } = this.props;
    if (typeof submitEvent === 'function') {
      submitEvent(form);
    }
  };
  exitForm = () => {
    const { exitEvent } = this.props;
    if (typeof exitEvent === 'function') {
      exitEvent();
    }
  };

  triggerUpdate = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    const { name, value } = e.target;

    this.props.updateFormValue(name, value);
  };

  nextStep = e => {
    e.preventDefault();

    const { nextStep, step, storeValues, id, values } = this.props;

    if (!values[step]) return;

    if (!this.isLastStep()) {
      nextStep();
    } else {
      storeValues(id, values);
      this.submitForm();
    }
  };

  previousStep = e => {
    e.preventDefault();

    const { id, previousStep, removeStoreKey } = this.props;

    if (!this.isFirstStep()) {
      previousStep();
    } else {
      removeStoreKey(id);
      this.exitForm();
    }
  };

  render() {
    const {
      className,
      values,
      step,
      setFormStep,
      stepIndex,
      fields,
      title,
      description,
      locale
    } = this.props;
    const { lang, documentMessages } = locale;

    const currentField = fields[stepIndex];
    const currentValue = values[step] || '';

    const isFirstStep = this.isFirstStep(step),
      isLastStep = this.isLastStep(step);

    return step ? (
      <IntlProvider locale={lang} messages={documentMessages[lang]}>
        <div className="FillableDocument__Form-container">
          <h2 className="Form__title">
            <FormattedMessage id={title} />
          </h2>
          <h5 className="Form__description">
            <FormattedMessage id={description} />
          </h5>
          <select
            value={step}
            onChange={({ target: { value } }) => setFormStep(value)}
          >
            {fields.map(({ id, type }) => (
              <option value={id} key={id}>
                {type} - {id}
              </option>
            ))}
          </select>
          <form className={className} onSubmit={this.nextStep}>
            <Input
              field={currentField}
              value={currentValue}
              onChange={this.triggerUpdate}
            />

            <div className="FillableDocument__Form__form-controls">
              <button
                className="FillableDocument__Form__form-controls__button-previous"
                onClick={() => null /*console.log('previous step')*/}
              >
                {isFirstStep ? (
                  <FormattedMessage id="DOCUMENT_FORM_BUTTON_CANCEL" />
                ) : (
                  <FormattedMessage id="DOCUMENT_FORM_BUTTON_PREVIOUS" />
                )}
              </button>
              <button
                className="FillableDocument__Form__form-controls__button-next"
                onClick={this.nextStep}
                disabled={!currentValue}
              >
                {isLastStep ? (
                  <FormattedMessage id="DOCUMENT_FORM_BUTTON_SUBMIT" />
                ) : (
                  <FormattedMessage id="DOCUMENT_FORM_BUTTON_NEXT" />
                )}
              </button>
            </div>
          </form>
          <button className={`FillableDocument__Form__form-dowload`} disabled={!isLastStep ? false : true} onClick={this.conversorHTMLToPdf}>{'generarPDF'}</button>
        </div>
      </IntlProvider>
    ) : null;
  }

  conversorHTMLToPdf = () => {
    const element = document.getElementById('FillableDocument__Document');
    const opt = {
        margin:       0.5,
        filename:     'test.pdf',
        image:        { type: 'png', quality: 0.01 },
        html2canvas:  { scale: 4 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] }
    }

    const PDF = html2pdf().set(opt).from(element)
    PDF.save();

    this.generatePDF(PDF)
      .then(succsesPromise => {
        this.loadFile(succsesPromise)
      });
  }

  generatePDF = (PDF) => {
    return new Promise ((resolve) => {
      resolve(PDF.output())
    });
  }

  loadFile = (elem) => {
    const file = btoa(elem)
    let multipart = new FormData();
    multipart.append('file', file);
    this.uploadNewDocument(multipart)
  }

  uploadNewDocument = (formData) => {
    // const { /*intl,*/  idTag, reloadInfo/*, showFlotingMsg*/ } = this.props
    let request = formData
    request.append('tagId', 1);

    Services.uploadDocument(request)
      .then( response => {
          // reloadInfo()
          // showFlotingMsg('ok', intl.formatMessage({id:"MSG_FILE_UPLOAD"}))
      })
      .catch( error => {
          // showFlotingMsg('error', error)
          console.error("UPLOAD_NEW_DOCUMENT_ERROR -> ", error)
      })
  }
}

export default connect(
  ({ documentForm, locale }) => ({
    ...documentForm,
    locale
  }),
  {
    // fetchFormConfig,
    updateFormValue,
    storeValues,
    setFormStep,
    nextStep,
    previousStep,
    removeStoreKey,
    // loadInitialState
  }
)(Form);
