import React, { Component } from 'react'
import * as Service from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import * as Icons from '../Utilities/ImgConfig'
import CustomInput from '../components/CustomInput'
import FacebookLogin from 'react-facebook-login'
import config from '../Utilities/Config'
import FlotingDialog from '../Dialogs/FlotingDialog'
import Dialog from '../Dialogs/GenericDialog'
import LegalNote from '../LegalNote'
import { FormattedMessage, injectIntl } from 'react-intl';

class Login extends Component {
   

   componentWillMount() {
      const { userMail, param1, whereProceed} = this.props
      
      if(whereProceed === 'confirmYourAccount')
         userMail && param1 && this.confirmAccount(param1, userMail)
   }

   state = {
      valueUserMail: "",
      valuePaswd: "",
      showDialog: false,
      msgError: null,
      typeMessage: null,
      responseFB: null,
      isCheked: false,
      showInfoMobile: false,
      needAcceptPopUpMobile: window.innerWidth <= 768 ? true : false,
   }

   render() {
      const { msgError, typeMessage, showDialog, isCheked, showDialogR, valueUserMail, valuePaswd, showInfoMobile } = this.state
      const { goToRecoveryPass, closeSidebar, intl } = this.props
      const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null
      const previewPolicyRules = showDialogR ? 
         <Dialog open={showDialogR} accept={this.acceptPolicyRules} close={this.cancelPolicyRules} cross={true} customStyles='main-dialog-notify-reset scrollbar'>
            <LegalNote />
         </Dialog> : null

      const previewInfoMobile = showInfoMobile ? 
         <Dialog open={showInfoMobile} accept={this.showHideInfoMobile} cross={false} customStyles='main-dialog-notify-mobile'>
            <div className="main-dialog-notify-mobile-text"><FormattedMessage id="INFO_DIALOG_LOG_MOBILE" /></div>
         </Dialog> : null

      return (
         <div className="home-content-login">
            {previewFlotingDialog}
            {previewPolicyRules}
            {previewInfoMobile}
            <label className="home-content-login-start"><FormattedMessage id="START_VITAL" /></label>
            <h2><FormattedMessage id="TITLE_LOG" /></h2>
            <CustomInput addClassName={`home-content-login-input ${valueUserMail ? '': 'home-content-login-input-empty'} ${showDialog && (typeMessage === 'error') ? `custom-input-element-error` :''}`}
                         title={intl.formatMessage({id:'TITLE_USER'})}
                         placeholder={intl.formatMessage({id:'TITLE_USER'})}
                         height='5vh'
                         width='calc(100% - 3vh - 4px)'
                         methodToRun = {this.setEmail}
                         onkeypressEvent={this.goToMainMenuKey}/>
            <CustomInput addClassName={`home-content-login-input ${valuePaswd ? '': 'home-content-login-input-empty'} ${showDialog && (typeMessage === 'error') ? `custom-input-element-error` :''}`}
                         typeinput="password"
                         title={intl.formatMessage({id:'TITLE_PSWD'})}
                         placeholder={intl.formatMessage({id:'TITLE_PSWD'})}
                         height='5vh'
                         width='calc(100% - 3vh - 4px)'
                         methodToRun = {this.setPassword}
                         onkeypressEvent={this.goToMainMenuKey}/>
            <label className="home-content-remember-password" onClick={goToRecoveryPass}><FormattedMessage id="REMEMBER_PSWD" /></label>
            
            <div className="home-content-login-botonera">
               <button className="home-content-login-botonera-btn-registre" onClick={this.register}><FormattedMessage id="REGISTER_BUTTON" /></button>
               <button className="home-content-login-botonera-btn-loging" onClick={this.goToMainMenu} ><FormattedMessage id="START_BTN" /></button>
            </div>

            <div className="home-content-login-alternative">
               {/* <label><FormattedMessage id="OTHER_LOGINS" /></label>
               <FacebookLogin
                  appId="2172458082993015"
                  autoLoad={false}
                  fields="name,email,picture"
                  textButton= {<div className='home-content-login-facebook-btn-text'>{'Iniciar con Facebook'}</div>}
                  cssClass="home-content-login-facebook-btn"
                  callback={this.facebookLog}
                  icon={<img className='home-content-login-facebook-btn-img' src={Icons.FacebookLog} alt="fb" />}
               /> */}
            </div>
         </div>
      )
   }
     
   openCloseDialog = () => {
      this.setState({showDialogR: !this.state.showDialogR})
   }

   showHideInfoMobile = () => {
     this.setState({
         needAcceptPopUpMobile: false,
         showInfoMobile: !this.state.showInfoMobile
      })
   }
   cancelPolicyRules = () => {
      this.setState({isCheked: false})
      this.openCloseDialog()
   }
   acceptPolicyRules = () => {
      this.setState({isCheked: true})
      this.openCloseDialog()
   }

   facebookLog = (response) => {
      const {needAcceptPopUpMobile} = this.state

      if(needAcceptPopUpMobile){
         this.showHideInfoMobile()
      } else {
         const userMail=response.email
         const tokenFb=response.accessToken
         Service.loginFacebook({ baseURL: config.API.ROOT_URL, data: {"token": response.accessToken}})
            .then(response => {
               localStorage.setItem('userMail',userMail)
               localStorage.setItem('tokenFb', tokenFb)
               localStorage.setItem('token', response.data && response.data.token ? response.data.token : null )
               this.props.pushHistory.push('/myapps', {tokenF: response.accessToken, userDataF: response.data})
            })
            .catch(error => {
               console.error("LOGIN_FACEBOOK_ERROR -> ", error)
               const msg = error && error.response && error.response.data && error.response.data.message

               this.setState({
                  showDialog: true,
                  msgError: Utils.formatErrorMsg(msg),
                  typeMessage: 'error'
               }, ()=>Utils.clearError(this))
            })
      }
   }

   goToMainMenu = () => {
      const {needAcceptPopUpMobile} = this.state

      if(needAcceptPopUpMobile){
         this.showHideInfoMobile()
      } else {
         const { valueUserMail, valuePaswd } = this.state
         Service.loginCall({ baseURL: config.API.ROOT_URL, data: {"password": valuePaswd , "email": valueUserMail, "user": valueUserMail}})
            .then(response=>{
               localStorage.setItem('userMail', valueUserMail)
               localStorage.setItem('token', response.data.token)
               this.props.pushHistory.push('/myapps', {token: response.data.token})
            })
            .catch(error=>{
               console.error("Login_ERROR -> ", error)
               const msg = error && error.response && error.response.data && error.response.data.message
               this.setState({
                  showDialog: true, 
                  msgError: msg,
                  typeMessage: 'error'
               }, ()=>Utils.clearError(this))
            })
      }
   }
   goToMainMenuKey = (event) => {
      if(event.key == 'Enter'){
         this.goToMainMenu()
      }
   }

   register = () => {
      window.location.replace("https://vitalbook.me/#registro")
      /*  ##### NO DESCOMENTAR REGISTRO DES DE https://vitalbook.me/#registro ##### 
      */
      // const { intl } = this.props
      // const { valueUserMail, valuePaswd, isCheked } = this.state
      // if(true) {
      //    let user = {
      //       "email" : valueUserMail,
      //       "password": valuePaswd,
      //       "user" : valueUserMail,
      //       "uuid": this.props.param1 ? this.props.param1 : null,
      //       "invitationCode": this.props.specialCode ? this.props.specialCode : null
      //    }
      //    Service.register({ baseURL: config.API.ROOT_URL, data:user})
      //       .then((response)=>{
      //          this.setState({showDialog: true, msgError: intl.formatMessage({id:'MSG_REGISTER_COMPLETE'}), typeMessage: 'ok'}, ()=>Utils.clearError(this))
      //       })
      //       .catch((error)=>{
      //          this.setState({showDialog: true, msgError: Utils.formatErrorMsg(error.response.data.message), typeMessage: 'error'}, ()=>Utils.clearError(this))
      //          console.error("Register_ERROR -> ", error)
      //       })
      // } else {
      //   this.setState({showDialog: true, msgError: intl.formatMessage({id:'ERROR_NO_ACCEPT_POLICY_RULES'}), typeMessage: 'error'}, ()=>Utils.clearError(this))
      // }
   }

   setEmail = (loggedEmail) => {
     this.setState({valueUserMail: loggedEmail});
   }
   setPassword = (password) => {
     this.setState({valuePaswd: password});
   }

   confirmAccount = (param1, userMail) => {
      const { intl } = this.props
      const request = {
          uuid: param1,
          email: userMail
      }

      Service.activateUser(request)
         .then(response => {
            this.setState({showDialog: true, msgError: intl.formatMessage({id:'MSG_ACCOUNT_ACTIVATED'}), typeMessage: 'ok'}, Utils.clearError(this))
         })
         .catch(error => {
            this.setState({showDialog: true, msgError: error.response.data.message, typeMessage: 'error'}, Utils.clearError(this))
            console.error('Send_Mail_Confirmation_Account_ERROR -> ', error)
         })
   }
}

export default injectIntl(Login)
