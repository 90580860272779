import {
  DOCUMENT_FORM_UPDATE_FORM_VALUE,
  DOCUMENT_FORM_SET_STEP,
  DOCUMENT_FORM_FETCH_CONFIG,
  DOCUMENT_FORM_FETCH_DOCUMENT,
  DOCUMENT_FORM_STORE_VALUES,
  DOCUMENT_FORM_INIT_FORM_STATE,
  DOCUMENT_FORM_REMOVE_STORE,
  DOCUMENT_FORM_REMOVE_STORE_KEY
} from '../types';

import config from './config.json';
import { fetchFillableDocument } from '../../Utilities/Services';

const getFormById = id => {
  const { forms } = config;

  if (!forms.map(f => f.id).includes(id)) return false;
  return forms.find(f => f.id === id);
};

export const loadInitialState = () => dispatch =>
  dispatch({ type: DOCUMENT_FORM_INIT_FORM_STATE });

export const fetchDocument = name => async (dispatch, getState) => {
  const { lang } = getState().locale;

  const response = await fetchFillableDocument(name, lang);

  dispatch({
    type: DOCUMENT_FORM_FETCH_DOCUMENT,
    payload: { md: response.data }
  });
};

export const fetchFormConfig = id => (dispatch, getState) => {
  const config = getFormById(id);
  const { store } = getState().documentForm;
  const { extend = [] } = config;

  const values = extend.reduce((acc, e) => {
    const extension = store[e];

    if (!extension) {
      return acc;
    }

    return { ...acc, ...extension };
  }, {});

  config.fields.forEach(f => (f.extension = false));

  config.fields = config.fields.concat(
    extend.reduce((acc, e) => {
      const extension = getFormById(e);

      if (!extension) {
        return acc;
      }

      return [
        ...acc,
        ...extension.fields.map(f => {
          f.extension = true;
          return f;
        })
      ];
    }, [])
  );
  dispatch({ type: DOCUMENT_FORM_FETCH_CONFIG, payload: { config, values } });
};

export const updateFormValue = (key, value) => dispatch =>
  dispatch({
    type: DOCUMENT_FORM_UPDATE_FORM_VALUE,
    payload: { [key]: value }
  });

export const storeValues = (key, values) => dispatch =>
  dispatch({ type: DOCUMENT_FORM_STORE_VALUES, payload: { [key]: values } });

export const removeStore = () => dispatch =>
  dispatch({ type: DOCUMENT_FORM_REMOVE_STORE });

export const removeStoreKey = key => dispatch =>
  dispatch({ type: DOCUMENT_FORM_REMOVE_STORE_KEY, payload: { key } });

export const setFormStep = step => dispatch =>
  dispatch({ type: DOCUMENT_FORM_SET_STEP, payload: { step } });

export const nextStep = () => (dispatch, getState) => {
  const { step, stepIndex, fields } = getState().documentForm;
  setFormStep(
    stepIndex === fields.length - 1
      ? step
      : fields.filter(({ extension }) => !extension)[stepIndex + 1].id
  )(dispatch);
};

export const previousStep = () => (dispatch, getState) => {
  const { step, stepIndex, fields } = getState().documentForm;
  setFormStep(
    stepIndex === 0
      ? step
      : fields.filter(({ extension }) => !extension)[stepIndex - 1].id
  )(dispatch);
};
