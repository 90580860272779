import React, { Component } from 'react';
import CustomInput from '../components/CustomInput'
import * as Service from '../Utilities/Services'
import * as Utils from '../Utilities/Utils'
import * as Icons from '../Utilities/ImgConfig'
import FlotingDialog from '../Dialogs/FlotingDialog'
import { FormattedMessage, injectIntl } from 'react-intl';

class ChangePassword extends Component {

    componentWillMount

    state = {
        valueUserName: "",
        valuePaswd: "",
        repeatValuePaswd: "",
        showDialog: false,
        msgError: null,
        typeMessage: null
    }

    render() {
        const { showDialog, msgError, typeMessage } = this.state
        const { userMail, closeSidebar } = this.props
        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage}/> : null
        
        return (
            <div className="home-content-register">
                {previewFlotingDialog}
                <div className='home-content-login-close' onClick = {closeSidebar} >
                    <img src={Icons.BackArrow} alt="X" />
                </div>
                <h1><FormattedMessage id="TITLE_CHANGE_PASS" /></h1>
                <CustomInput typeinput="text" title={'Mail'} placeholder={window.atob(userMail)}  width='-webkit-fill-available' height='5vh' isDisabled={true} methodToRun={this.getUserValue}/>
                <CustomInput typeinput="password" title={'New Password'} placeholder={'password'} width='93.8%' height='5vh' methodToRun={this.getPassword} />
                <CustomInput typeinput="password" title={'Repeat New Password'} placeholder={'repeat password'} width='93.8%' height='5vh' methodToRun={this.getPasswordRepeat}/>
                <button className="home-content-register-button" onClick={this.sendNewPassword}><FormattedMessage id="SEND_NEW_PASS" /></button>
            </div>
        )
    }

    getUserValue = (val) => {
        this.setState({valUserName: val})
    }
    getPassword = (val) => {
        this.setState({valuePaswd: val})
    }
    getPasswordRepeat = (val) => {
        this.setState({repeatValuePaswd: val})
    }

    sendNewPassword = () => {
        const { valuePaswd, repeatValuePaswd, intl } = this.state

        if( valuePaswd === repeatValuePaswd ) {
            const { param1, userMail} = this.props
            const request = {
                uuid: param1,
                email: userMail,
                password: valuePaswd
            }

            Service.resetUserPassword(request)
                .then(response=>{
                    this.setState({showDialog: true, msgError: 'Ok', typeMessage: 'ok'}, Utils.clearError(this))
                })
                .catch(error=>{
                    this.setState({showDialog: true, msgError: 'error', typeMessage: 'error'}, Utils.clearError(this))
                })
        } else {
            this.setState({showDialog: true, msgError: intl.formatMessage({id:'MSG_ERROR_PASS'}), typeMessage: 'error'}, Utils.clearError(this))
        }
    }
}

export default injectIntl(ChangePassword);
