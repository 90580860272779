import React, { Component } from 'react';
import Dialog from './GenericDialog'


export default class NotifyDialog extends Component {

	render() {
        const { open, close, accept, msg } = this.props
		
		return (
			<Dialog open={open} close={close} accept={accept} cross={true} customStyles='main-dialog-notify-reset'>
				<div className="dialog-notify-reset-content">
                	<label>{msg}</label>
				</div>
			</Dialog>
		)
	}

}
	