import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './styles/App.css';
import MainVitalBook from './UserApplications/AlbumVital/MainVitalBook';
import MyApps from './UserApplications/MyApps';
import Home from './Home';
import VitalAngels from './UserApplications/VitalAngels/VitalAngels';
import Documents from './UserApplications/Documents/DocumentsHome';
import FillableDocument from './UserApplications/Documents/FillableDocument';
import familytree from './UserApplications/FamilyTree/HomeFamilyTree';
import PageWrapper from './PageWrapper';
import okbuy from './RedirectPages/OkBuy';
import kobuy from './RedirectPages/KoBuy';
import LegalInfo from './UserApplications/LegalInfo';
import upload from './UserApplications/Aws/upload';
import video from './UserApplications/Vitalbookcam/Video';

const PageRoute = ({
  exact,
  path,
  defaultLangBrowser,
  component: Component,
  render,
  withWrapper
}) => (
  <Route
    {...{ path, exact }}
    render={props =>
      {
        return withWrapper ? (
          Component ? (
            <PageWrapper router={{...props, defaultLangBrowser}} component={Component} />
          ) : (
            <PageWrapper router={{...props, defaultLangBrowser}} render={render} />
          )
        ) : Component ? (
          <Component router={{...props, defaultLangBrowser}}/>
        ) : (
          render({ ...props, defaultLangBrowser })
        )
      }
    }
  />
);

const Layout = (props) => (
  <Switch>
    <PageRoute exact path="/" component={Home} defaultLangBrowser={{...props}} />
    <PageRoute path="/myapps" withWrapper component={MyApps} defaultLangBrowser={{...props}} />
    <PageRoute path="/album" withWrapper component={MainVitalBook} defaultLangBrowser={{...props}} />
    <PageRoute path="/vitalangels" withWrapper component={VitalAngels} defaultLangBrowser={{...props}} />
    <PageRoute
      path="/fillabledocument"
      withWrapper
      render={() => (
        <FillableDocument id="testament_open" defaultLangBrowser={{...props}} />
      )}
    />
    <PageRoute path="/legalinfo" withWrapper component={LegalInfo} defaultLangBrowser={{...props}} />
    <PageRoute path="/familytree" withWrapper component={familytree} defaultLangBrowser={{...props}} />
    <PageRoute path="/okbuy" withWrapper component={okbuy} />
    <PageRoute path="/kobuy" withWrapper component={kobuy} />
  </Switch>
);

export default Layout;

