import React, { Component } from 'react'
import SlideShot from '../../Dialogs/SlideShot'
import Dialogs from '../../Dialogs/GenericDialog'
import * as Services from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import CustomInput from '../../components/CustomInput'
import { FormattedMessage, injectIntl } from 'react-intl';

class ViewMenu extends Component {

	componentWillMount(){
		Services.getAllElementsPicFromAlbum(this.props.idAlbum)
			.then(response => {
				this.setState({allPhotos: response.data.content})
			})
			.catch(error => {console.error('Get_All_Elements_Pic_From_Album_ERROR -> ', error)})
	}

	state = {
		allPhotos: [],
		timeSelected: 8,
		showSlideShot: false,
		generateVideoOnRow: false,
		showNotifyDialog: false,
		inputError: false,
		inputContent: "",
		videoName: this.props.videoName
	};

	render() {
		const { token, idAlbum, changeSkin, backgroundSkinSelected, skins, music, deleteAudioElement, intl } = this.props
		const { showSlideShot, allPhotos, timeSelected, generateVideoOnRow, showNotifyDialog, inputError } = this.state
		const dialogSlideShot = showSlideShot ? 
								<SlideShot open={showSlideShot} close={()=>this.hideShow('showSlideShot')} allPhotos={allPhotos} timeSelected={timeSelected}
										   token={token} idAlbum={idAlbum} /> : null

		const dialogGenerateVideoOnRow = generateVideoOnRow ?
										<Dialogs open={generateVideoOnRow} close={()=>this.hideShow('generateVideoOnRow')} customStyles="dialog-alert-generate-video" >
											<div>
												<FormattedMessage id="MSG_GENERATING_VIDEO" />
											</div>
										</Dialogs> : null

		const notifyDialog = showNotifyDialog ?
							<Dialogs open={showNotifyDialog} accept={this.deleteVideo} close={()=>this.hideShow('showNotifyDialog')} cross={true} customStyles='main-dialog-notify-reset' >
								<label className="dialog-notify-reset-label-text" ><FormattedMessage id="OPTION_TEXT_DELETE_VIDEO" /></label>
								<div className="dialog-notify-reset-label-text-to-write"><FormattedMessage id="SETTINGS_TETXT_CONFIRM_DEL" /> </div>
								<CustomInput addClassName={`settings-main-input-delete ${inputError ? 'settings-main-input-error':''}`} width='15vw' height='4vh' placeholder={intl.formatMessage({id:'SETTINGS_PLACE_HOLDER_DEL'})} methodToRun={this.getImputCont}/>
							</Dialogs> : null
							
		return (
			<div className="ViewMenu-main">
				{notifyDialog}
				{dialogSlideShot}
				{dialogGenerateVideoOnRow}
				<div className="ViewMenu-content scrollbar">
					<h4>{this.TITLE_EDIT}</h4>
					<div className="ViewMenu-music-menu">
						<div className="ViewMenu-music-menu-text"><FormattedMessage id="OPTION_DESCRIPTION_MUSIC" /></div>
						{music ? 
							<div className="upp-music-btn-local"><label>{music}</label><img src={Icons.BluePintetBGCross} onClick={deleteAudioElement}/></div> :
							(
								<div className="upp-music-btn-local">
									<div>
										<img src={Icons.MiniAdd} />
										<label htmlFor={`input-upload-music-album`} className="custom-input-text-music"><FormattedMessage id="BTN_ADD_MUSIC" /></label>
									</div>
									<input id={`input-upload-music-album`}
										className="input-add-music-files"
										type="file"
										onChange={this.selectMusic}
										accept=".mp3, .mp4"
									/>
								</div>
							) 
						}
						<div className="ViewMenu-music-generate-video">
						<button className={`ViewMenu-music-generate-video-btn ${music ? 'ViewMenu-music-generate-video-active' : 'ViewMenu-music-generate-video-dissabled'}`}
									disabled={ music ? false : true }
									onClick={ this.isActivate() ? ()=>this.hideShow('showNotifyDialog') :  this.generateVideo
									}>
								{this.isActivate()  ? <FormattedMessage id="BTN_DELETE_VIDEO" /> : <FormattedMessage id="BTN_GENERATE_VIDEO" />}
							</button>
							<button className={`ViewMenu-music-generate-video-btn ${this.isActivate() ? 'ViewMenu-music-generate-video-active' : 'ViewMenu-music-generate-video-dissabled'}`}
									disabled={!this.isActivate()}
									onClick={this.isActivate() ? this.dowloadGeneratedVideo : ()=>null}>
								<FormattedMessage id="BTN_DOWLOAD_VIDEO" />
							</button>
						</div>
					</div>
					<h4><FormattedMessage id="OPTION_TITLE_TRANSITIONS" /></h4>
					<div className="ViewMenu-transition-menu">
						<FormattedMessage id="OPTION_DESCRIPTION_TRANSITION" />
						<div>
							<label><FormattedMessage id="TIME_NAME" /></label>
							<input type="range" min={1} max={15} value={timeSelected} className="imagen-editor-slider" id="typeinp" step={1} onChange={this.sliderBar} />
							<label>{`${timeSelected} s`}</label>
						</div>
					</div>
					<h4><FormattedMessage id="STYLE_BOOK" /></h4>
					<div className="ViewMenu-skin-menu">
						{skins && skins.map(item=>{
							return (
								<img key={`skin-element-${item.id}`} className={`carrousel-draggable-img ${backgroundSkinSelected.id === item.id ? 'ViewMenu-skin-menu-selected' : ''}`}
									src={item.image && `data:image/svg+xml;base64,${item.image}`} onClick={()=>changeSkin(item)}/>)
						})}
                	</div>
				</div>
				<div className="settings-btn-peview-album-presentacion" onClick={()=>this.hideShow('showSlideShot')}>
					<img src={Icons.Video}/>
					<label><FormattedMessage id="PREVIEW_SLIDE_SHOT" /></label>
				</div>
			</div>
		)
	}

	isActivate = () => {
		const { videoName } = this.state
		switch(videoName){
			case null:
			case undefined:
			case "pending":
			case "error":
				return false;
			default:
				return true;
		}
	}

	errorInput = () => {
        this.setState({inputError: true}, ()=>setTimeout(()=>{this.setState({inputError: false})}, 3000))
    }

    getImputCont = (value) => {this.setState({inputContent: value})}
	deleteVideo = () => {
		const { idAlbum, intl } = this.props
		const { inputContent } = this.state
		
		if(inputContent.toUpperCase() === intl.formatMessage({id:'SETTINGS_PLACE_HOLDER_DEL'}).toUpperCase()) {
			Services.deleteVideo(idAlbum)
				.then(response=>{
					this.setState({videoName: null})
					this.hideShow('showNotifyDialog')
				})
				.catch(error=>{console.error('DELETE_VIDEO_ALBUM_ERROR -> ', error)})
		} else {
			this.errorInput()
		}
	}
	generateVideo = () => {
		const { idAlbum } = this.props
		const { timeSelected } = this.state
		
		Services.videoCreator(idAlbum, timeSelected)
			.then(response=>{
				this.hideShow('generateVideoOnRow')
			})
			.catch(error=>{console.error('GET_VIDE_CREATOR_ERROR -> ', error)})
	}
	dowloadGeneratedVideo = () => {
		const { idAlbum } = this.props
		Services.getVideo(idAlbum)
			.then(response=>{
				this.dowloadFileVideo("video", response.data)
			})
			.catch(error=>{
				console.error('GET_VIDE_CREATOR_ERROR -> ', error)
			})
	}
	dowloadFileVideo = (reportName, byte) => {
		var blob = new Blob([byte], {type: "video/mp4"});
		var link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		var fileName = reportName;
		link.download = fileName;
		document.body.appendChild(link)
		link.click();
	}
	sliderBar = (event) => {
		this.setState({timeSelected: event.target.value})
	}
	selectMusic = (event) => {
		const { idAlbum, updateDataAlbum } = this.props
		let multipart = new FormData();
		multipart.append('file', event.target.files[0]);
		Services.updateAudioElement(idAlbum, multipart)
			.then(response=>{
				updateDataAlbum()
			})
			.catch(error => {
				console.error('Update_Audio_Element_ERROR -> ', error)
			})
	}
	hideShow = (key) => {
		this.setState({[key]: !this.state[key]})
	}

}

export default injectIntl(ViewMenu);
	