import React, { Component } from 'react';
import Dialog from './GenericDialog'
import * as Services from '../Utilities/Services'
import { injectIntl, FormattedMessage} from 'react-intl'


class DialogUploadDocOnSection extends Component {

    state = {
        inputValue: null,
        formData: null
    }

	render() {
        const { open, close, title, tags, intl } = this.props

		return (
            <Dialog open={open} accept={this.accept} cross={close} customStyles='main-uploadDocOnSection' >
                <div className='uploadDocOnSection-title'><FormattedMessage id="TITLE_UPLOAD_ON_SECTION" /></div>
                <div className='uploadDocOnSection-import'>
                    <div className='uploadDocOnSection-import-info'><FormattedMessage id="SELECT_YOUR_SECTION" /></div>
                    <select className='uploadDocOnSection-import-select' onChange={this.selectSection}>
                        <option key={`optns-tags-00`} value={-1}>{intl.formatMessage({id:'SELECT_SECTION'})}</option>
                        {tags && (tags.length > 0) && tags.map(items=>{
                            return <option key={`optns-tags-${items.id}`} value={items.id}>{items.name}</option>
                        })}
                    </select>
                </div>
               <div className='uploadDocOnSection-input'>
                    <input id={`input-upload-doc-${title}`} 
                           data-multiple-caption="{count} files selected"
                           type="file"
                           onChange={this.loadFile} 
                           accept="application/pdf, application/vnd.ms-excel"
                    />
                </div>
			</Dialog>
		)
    }

    selectSection = (value) => {
        this.setState({inputValue: parseInt(value.target.value)})
    }

    accept = () => {
        const { openShowLoader } = this.props
        openShowLoader()
        this.uploadNewDocument()
    }

    loadFile = (elem) => {
		const file = elem.target.files
        let multipart = new FormData();
        multipart.append('file', file[0]);
        this.setState({formData: multipart})
	}

    uploadNewDocument = () => {
        const { formData, inputValue } = this.state
        const { reloadInfo, showFlotingMsg, openShowLoader,  intl, title} = this.props
        let request = formData
        request.append('tagId', inputValue);
        document.getElementById(`input-upload-doc-${title}`).value = "";
        Services.uploadDocument(request)
            .then(response=>{
                openShowLoader()
                reloadInfo()
                showFlotingMsg('ok', intl.formatMessage({id:'MSG_FILE_UPLOAD'}))
            })
            .catch(error=>{
                openShowLoader()
                showFlotingMsg('error', error)
                console.error("UPLOAD_NEW_DOCUMENT_ERROR -> ", error)
            })
    }
}

export default injectIntl(DialogUploadDocOnSection)
