import React, { Component } from 'react';
import LegalNote from '../LegalNote'
import ShopPolicyRules from '../components/ShopPolicyRules'
import PrivacyRules from '../PrivacyRules'
import { FormattedMessage } from 'react-intl';

class LegalInfo extends Component {

    state = {
        contextView: <LegalNote />,
        isSelected: 'legalNote'
    }

    render() {
        const { contextView, isSelected } = this.state
        
        return (
            <div className='main-legalInfo'>
                <div className='legalInfo-context'>
                    <div className='legalInfo-context-menu'>
                        <h3><FormattedMessage id="TITLE_LEGAL_INFO" /></h3>
                        <div className={`${isSelected==='legalNote'?'legalInfo-context-menu-select':''}`} 
                             onClick={()=>this.watchLegalinfo(<LegalNote />, 'legalNote')}>
                                 <FormattedMessage id="SELECT_LEGAL_INFO" />
                        </div>
                        <div className={`${isSelected==='privacyRules'?'legalInfo-context-menu-select':''}`}
                             onClick={()=>this.watchLegalinfo(<PrivacyRules />, 'privacyRules')}>
                            <FormattedMessage id="SELECT_PRIVACI_RULES" />
                        </div>
                        <div className={`${isSelected==='shopPolicyRules'?'legalInfo-context-menu-select':''}`}
                             onClick={()=>this.watchLegalinfo(<ShopPolicyRules />, 'shopPolicyRules')}>
                            <FormattedMessage id="SELECT_SHOP_POLICY_RULES" />
                        </div>
                    </div>
                    <div className='legalInfo-context-view scrollbar'>
                        {contextView}
                    </div>
                </div>
            </div>
        )
    }

    watchLegalinfo = (element, value) => {
        this.setState({
            contextView: element,
            isSelected: value
        })
    }
}

export default LegalInfo;
