import React, { Component } from 'react';
import Dialog from './GenericDialog'
import * as Icons from '../Utilities/ImgConfig'
import PageComponent from '../components/PageComponent'
import * as Utils from '../Utilities/Utils'
import * as img from '../Utilities/ImgConfig'

const getListStyle = (backgroundSkin) => ({
    background: `url(data:image/svg+xml;base64,${backgroundSkin.image}) center center / 70vw 67vh no-repeat`,
    padding: '4.5vh 4vw',
    margin: '8vh auto',
    width: '50vw'
})

export default class PreviewDialog extends Component {

	state = {};

	render() {
		const { open, pagesAlbum, backgroundSkin, itemLeft, itemRight, templateLeft, templateRight, stateGemerics, albumMaxPage, backPage, nextPage } = this.props

        return (
			<Dialog open={open} close={false} cross={this.close} customStyles='main-content-dialog'>
                <div className={`main-content-dialog-preview`} 
                     style={backgroundSkin && backgroundSkin.image && getListStyle(backgroundSkin)}>
                    <div className='album-context-templates-preview'>
                            <div className='album-context-pages-preview'>
                                { pagesAlbum.map((item, k) => {
                                    return  <PageComponent key={`page-album-${k}`}
                                                        templateSelected={(item.formatPage && item.formatPage.id) || (k===0?templateLeft:templateRight) || null}
                                                        pagePosition={k===0?"left":"right"}
                                                        stateGemerics={stateGemerics}
                                                        items={k===0?itemLeft:itemRight}
                                                        page={item}
                                                        isPreview={true}
                                            />
                                    })
                                }
                            </div>
                        <div className='album-context-paginator-button'>
                            <div className='album-context-paginator-button-arrow-back'>
                                {pagesAlbum[0] && (pagesAlbum[0].number > 1) && <img src={Icons.ArrowBack} onClick={backPage} />}
                            </div>
                            <div className='album-context-paginator-numbers'>   
                                <input disabled placeholder={`${pagesAlbum[0] && pagesAlbum[0].number} - ${(pagesAlbum[1] && pagesAlbum[1].number)}`} />
                                <label>{` de ${albumMaxPage}`}</label>
                            </div>
                            <div className='album-context-paginator-button-arrow-next'>
                                {!(pagesAlbum[1] && pagesAlbum[1].theLastBookPage) && <img src={Icons.ArrowNext} onClick={nextPage} />}
                            </div>
                        </div>
                    </div>
                </div>
			</Dialog>
		)
    }
    
    close = () => {
        const { close } = this.props
        if (!((document.fullScreenElement !== undefined && document.fullScreenElement === null) || 
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen))
        ) {
            this.toggleFullScreen()
        }
        close()
    }
}
	