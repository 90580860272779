import React, { Component } from 'react';
import Dialog from './GenericDialog';
import FlotingDialog from './FlotingDialog'
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomInput from '../components/CustomInput'
import * as Utils from '../Utilities/Utils'
import * as Services from '../Utilities/Services'


class DialogBuyPrinter extends Component {

    state = {
        inputValue: null,
        formData: null,
        showDialog: null,
        msgError: null,
        typeMessage: null,
        valueCountry: "",
        valueName: "",
        valueDirection: "",
        valueCity: "",
        valueProvince: "",
        valuePostalCode: "",
        valueTelefonNumber: ""
    }

	render() {
        const { open, close, intl } = this.props
        const { showDialog, typeMessage, valueCountry, valueName, valueDirection, valueCity, valueProvince, valuePostalCode, valueTelefonNumber, msgError } = this.state
        const previewFlotingDialog = showDialog ? <FlotingDialog msg={msgError} type={typeMessage} /> : null

		return (
            <Dialog open={open} accept={this.acceptBuyPrinter} cross={close} customStyles='main-BuyPrinter'>
                {previewFlotingDialog}
                <div className="BuyPrinter-content-form">
                    <h2 className="BuyPrinter-title">
                        <FormattedMessage id="BUY_PRINTER_TITLE_ALBUM" />
                    </h2>

                    <FormattedMessage id="BUY_PRINTER_ALBUM" />

                    <CustomInput addClassName={`home-content-login-input ${valueCountry ? '': 'home-content-login-input-empty'} ${valueCountry == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_COUNTRY'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_COUNTRY'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valueCountry', data)} />
                    <CustomInput addClassName={`home-content-login-input ${valueName ? '': 'home-content-login-input-empty'} ${valueName == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_NAME'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_NAME'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valueName', data)} />
                    <CustomInput addClassName={`home-content-login-input ${valueDirection ? '': 'home-content-login-input-empty'} ${valueDirection == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_DIRECTION'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_DIRECTION'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valueDirection', data)} />
                    <CustomInput addClassName={`home-content-login-input ${valueCity ? '': 'home-content-login-input-empty'} ${valueCity == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_CITY'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_CITY'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valueCity', data)} />
                    <CustomInput addClassName={`home-content-login-input ${valueProvince ? '': 'home-content-login-input-empty'} ${valueProvince == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_PROVINCE'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_PROVINCE'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valueProvince', data)} />
                    <CustomInput addClassName={`home-content-login-input ${valuePostalCode ? '': 'home-content-login-input-empty'} ${valuePostalCode == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_POSTAL_CODE'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_POSTAL_CODE'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valuePostalCode', data)} />
                    <CustomInput addClassName={`home-content-login-input ${valueTelefonNumber ? '': 'home-content-login-input-empty'} ${valueTelefonNumber == null ? `custom-input-element-error` :''}`}
                                 title={intl.formatMessage({id:'BUY_PRINTER_TELEFON_NUMBER'})}
                                 placeholder={intl.formatMessage({id:'BUY_PRINTER_TELEFON_NUMBER'})}
                                 height='5vh'
                                 methodToRun={(data)=>this.setValue('valueTelefonNumber', data)} />
                </div>
			</Dialog>
		)
    }

    setValue = (key, data) => {
        this.setState({[key]: data})
    }

    validateForm = () => {
        const { valueCountry, valueName, valueDirection, valueCity, valueProvince, valuePostalCode, valueTelefonNumber } = this.state
        const { intl } = this.props

        let form = {"valueCountry":valueCountry,"valueName": valueName, "valueDirection": valueDirection, "valueCity": valueCity, "valueProvince": valueProvince, "valuePostalCode": valuePostalCode, "valueTelefonNumber": valueTelefonNumber}

        for(let k in form){
            if(form[k]=="") {
                form[k]=null
                this.setState({[k]: null})
            }
        }

        if((valueCountry == "" || valueCountry == null) || (valueName == "" || valueName == null) || (valueDirection == "" || valueDirection == null) || (valueCity == "" || valueCity == null) || (valueProvince == "" || valueProvince == null) || (valuePostalCode == "" || valuePostalCode == null) || (valueTelefonNumber == "" || valueTelefonNumber == null)) {
            this.setState(
				{
					msgError: `${intl.formatMessage({id:'MSG_ERROR_BUY_PRINTER_FORM'})}`, 
					typeMessage: 'error' , showDialog: true
                }, Utils.clearError(this))
            return false
        } else {
            return true
        }
    }

    acceptBuyPrinter = () => {
        this.validateForm()
    }

}

export default injectIntl(DialogBuyPrinter);
