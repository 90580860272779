import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Icons from './Utilities/ImgConfig';

class LegalNote extends Component {

    render() {
        
        return (
            <div>
                <div className="main-legal-note scrollbar">
                    <div className="legal-note-header">
                        <div>
                            <img className="legal-note-header-logo" src={Icons.LogoVitalbookBig} />
                        </div>
                    </div>
                    <div className="legal-note-content">
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE" /></h3>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_TEXT" />
                        <br/>
                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_HEADLINE" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_HEADLINE_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_OBLIGATION" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_OBLIGATION_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_RESPONSABILITI" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_RESPONSABILITI_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_SITE_WEB" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_SITE_WEB_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_INTELECTUAL_INDUSTRIAL" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_INTELECTUAL_INDUSTRIAL_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_QUESTIONS_COMENTS" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_QUESTIONS_COMENTS_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_LEGAL_WARNING" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_LEGAL_WARNING_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_NORMATIVE" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_NORMATIVE_TEXT" />
                        </div>

                        <h3 className="legal-note-titles"><FormattedMessage id="LEGAL_NOTE_TITLE_LEGISLATION_JURISDICTION" /></h3>
                        <div>
                            <FormattedMessage id="LEGAL_NOTE_TITLE_LEGISLATION_JURISDICTION_TEXT" />
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        )
    }
}

export default LegalNote;
