import React, { Component } from 'react'
import Template1 from './Templates/Template1'
import Template2 from './Templates/Template2'
import Template3 from './Templates/Template3'
import Template4 from './Templates/Template4'
import Template5 from './Templates/Template5'
import Template6 from './Templates/Template6'
import Template7 from './Templates/Template7'
import Template8 from './Templates/Template8'
import DraggableSpaceTemplate from '../components/DraggableSpaceTemplate'
import * as Icon from '../Utilities/ImgConfig'
import $ from 'jquery'

export default class PageComponent extends Component {

	_renderSwitch() {
		const { templateSelected, items, page, pagePosition, stateGemerics, methodToRun, updateText, reloadActualPages, isPreview, lang } = this.props
		switch(templateSelected) {
			case 1:
				return <Template1 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />;
			case 2:
				return <Template2 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />;
			case 3:
				return <Template3 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />;
			case 4:
				return <Template4 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />;
			case 5:
				return <Template5 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />
			case 6:
				return <Template6 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />
			case 7:
				return <Template7 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />
			case 8:
				return <Template8 stateGemerics={stateGemerics} items={items} page={page} pagePosition={pagePosition} methodToRun={methodToRun} updateText={updateText} reloadActualPages={reloadActualPages} isPreview={isPreview} />
			default:
				return <DraggableSpaceTemplate pagePosition={pagePosition} isPreview={isPreview} lang={lang} />;
		}
	}

	render() {
		const { pagePosition, templateSelected, isPreview } = this.props
		
		$(`#img-trash-album-${pagePosition}`).hover(
			() => {
			   $(`#album-context-page-${pagePosition}`).addClass('album-context-remove')
			}, () => {
			   $(`#album-context-page-${pagePosition}`).removeClass('album-context-remove')
			}
		 );

		return (
			<div id={`id-page-component-${pagePosition}`} key={`page-component-${pagePosition}`} className={`page-position-${pagePosition} ${isPreview?'page-position-preview-'+pagePosition:''}`}>
				{(!isPreview) && templateSelected && templateSelected > 0 && <img id={`img-trash-album-${pagePosition}`} className={`page-component-button-rm-template-${pagePosition}`} src={Icon.TrashWhiteRedBg} onClick={this.resetTemplate}/>}
				{this._renderSwitch()}
			</div>
		);
	}

	resetTemplate = () => {
		this.props.updateBookPageTemplate(0, this.props.page)
	}
}
