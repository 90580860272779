import React, { Component } from 'react';
import * as Services from '../../Utilities/Services'
import * as Icons from '../../Utilities/ImgConfig'
import SlideMenuOptionsDocs from './SlideMenuOptionsDocs'
import RenameFile from '../../Dialogs/RenameFile'
import * as moment from 'moment'
import { injectIntl } from 'react-intl';

class DocumentPreviewMiniature extends Component {

	state = {
		showMenuOptions: null,
		showRenameFile: null,
		nameFile: ''
	};

	render() {
		const { showMenuOptions, showRenameFile, nameFile } = this.state
		const { elementsDoc, reloadInfo, showFlotingMsg } = this.props
		const dialogRenameFile = showRenameFile ? <RenameFile open={showRenameFile}
															  close={this.openCloseRenameDialog} 
															  accept={this.renameDoc}
												  /> : null
        return (
            <div className="main-DocumentPreviewMiniature">
				{dialogRenameFile}
				{
					showMenuOptions ? 	<SlideMenuOptionsDocs openCloseRenameDialog={this.openCloseRenameDialog}
															  elementsDoc={elementsDoc}
															  closeMenuOptns={this.closeMenuOptns}
															  reloadInfo={reloadInfo}
															  showFlotingMsg={showFlotingMsg}
										/> 
									: 
									<div>
										<div className="DocumentPreviewMiniature-img" onClick={this.openCloseMenuOptns}>
											<img src={Icons.Ellipsis} />
										</div>
										<div className="DocumentPreviewMiniature-miniature">
											<img src={this.getIconFile(elementsDoc.filename)} />
										</div>
										<div className="DocumentPreviewMiniature-file-name">{this.formatText(nameFile == '' ? elementsDoc.filename : nameFile)}</div>
										<div className="DocumentPreviewMiniature-dete-imported">{this.formatDate(elementsDoc.uploadDate)}</div>
									</div>
				}
            </div>
		)
	}

	formatDate = (dataFile) => {
        const formatDate = moment(dataFile).format('DD/MM/YYYY')
        return <div>{formatDate}</div>
	}
	formatText = (text) => {
		if(text.length > 12) {
			const marxText = text.slice(0, 9) + '...'
			return marxText
		} else {
			return text
		}
	}
	getIconFile = (docName) => {
		const elements = docName.split('.')
		const extension =  elements[elements.length-1]
		let img
		extension.toUpperCase() === 'PDF' ? img=Icons.IconPdf : img=Icons.IconPlantilla

		return img
	}
	openCloseRenameDialog = () => {
        this.setState({showRenameFile: !this.state.showRenameFile})
    }
	openCloseMenuOptns = () => {
		this.setState({showMenuOptions: !this.state.showMenuOptions})
	}
	closeMenuOptns = () => {
		this.setState({showMenuOptions: false})
	}
	renameDoc = (newNameFile) => {
		const { elementsDoc, intl, reloadInfo, showFlotingMsg } = this.props
        
        Services.renameFilenameDoc(elementsDoc.id, newNameFile)
			.then(response=>{
				reloadInfo()
				showFlotingMsg('ok', intl.formatMessage({id:'MSG_DOC_RENAMED'}))
				this.setState({
					showRenameFile: false,
					nameFile: newNameFile
				})
			})
			.catch(error=>{
				console.error('RENAME_FILENAME_DOC_ERROR -> ', error)
				showFlotingMsg('error', error)
			})
    }
}

export default injectIntl(DocumentPreviewMiniature)
	