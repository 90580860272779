
import React, { Component } from 'react';
import Dialog from './GenericDialog'
import CustomInput from '../components/CustomInput'
import { FormattedMessage, injectIntl } from 'react-intl';

class RenameFile extends Component {

	state = {
        newNameFile: null,
        showDialog: null
    };

	render() {
        const { newNameFile, showDialog } = this.state
		const { open, close, intl } = this.props
        
		return (
            <Dialog open={open} close={close} accept={this.sendNewName} cross={true} customStyles="main-dialog-list-albums">
                <div className="dialog-list-new-album">
                    <div><FormattedMessage id="RENAME_SELECT_FILE" /></div>
                    <label className="dialog-list-new-album-sub-title"><FormattedMessage id="WHAT_NEW_NAME" /></label>
                    <CustomInput addClassName={`home-content-login-input ${newNameFile ? '': 'home-content-login-input-empty'} ${showDialog ? `custom-input-element-error` :''}`} 
                                placeholder={intl.formatMessage({id: 'NEW_NAME_PH'})} height='5vh' width='-webkit-fill-available' methodToRun={(data) => this.setState({newNameFile: data})} />
                </div>
            </Dialog>
        )
    }

    sendNewName = () => {
        const { accept } = this.props
        const { newNameFile } = this.state
        
        if(newNameFile) {
            accept(newNameFile)
        } else {
            console.error('Tiene que renombrar el fichero')
        }
    }
}

export default injectIntl(RenameFile)