import React, { Component } from 'react';


export default class webcam extends Component {

  componentWillMount(){
    
  }

  render() {
    
    const protocol = 'https'
    const url = 'vitalbook.me/webcam'
    const userId = ''
    
    return (
        <div className='main-family-tree'>
            <iframe src={`${protocol}://${url}`} allow="camera;microphone"/>
        </div>
    );
  }

}
