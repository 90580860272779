import React, { Component } from 'react'
import * as Icons from '../../Utilities/ImgConfig'
import { FormattedMessage } from 'react-intl';

export default class HeaderBook extends Component {

	render() {
        const { albumsList, deleteAction, addTwoPagesOnAlbum, displayPreview, albumSelectedId } = this.props

		return (
            <div className="headerBook-main">
                <h3 className="headerBook-title"><FormattedMessage id="TITLE_NAME" /></h3>
                <select className="headerBook-selector" defaultValue={albumSelectedId} onChange={this.albumSelector}>
                    { albumsList && albumsList.map((item, k) => {
                        return <option key={`option-album-${k}`} value={item.id} selected={item.id===albumSelectedId?'selected':null}>{item.name}</option>
                    })}
                </select>
                <div className="headerBook-btns-album">
                    <div onClick={deleteAction}><div className="headerBook-btns-album-trash" ><img src={Icons.EliminarPages} /></div></div>
                    <div onClick={displayPreview}><div className="headerBook-btns-album-eye" ><img src={Icons.Eye} /></div></div>
                </div>
                <div className="headerBook-add-two-pages" onClick={addTwoPagesOnAlbum}>
                    <FormattedMessage id="ADD_PAGE" />
                </div>
            </div>
        )
    }

    albumSelector = (value) => {
        const { albumsList, changeAlbum } = this.props
        const albumSelected = albumsList.find(item => item.id === parseInt(value.target.value))
        changeAlbum(albumSelected)
    }

}
