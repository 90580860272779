import React, { Component } from 'react';

const getHeaderStyle = () => ({
	background: null,
	height: null,
 });

const getContentStyle = () => ({
	background: null,
	height: null,
 });

export default class Tabs extends Component {
		
	state = {
		key: 0
	}

	_renderContent = () => {
		const { key } = this.state
		const { children } = this.props

		return (
			<div className={`menu-tabs`} style={getContentStyle(key)}>
				{children[key]}
			</div>
		)
	}

	render() {
		const { key } = this.state
		const { children } = this.props

		return (
            <div className='Testamento-content-type-docs'>
				<div className='Testamento-content-type-docs-tabs'>
					{children.map((item, k)=> {
						const { title } = item.props
						return (
							<div key={`menu-tabs-key-${k}`} 
								 style={getHeaderStyle()}
								 className={`Testamento-content-type-docs-tabs-btn ${k===key?'menu-tabs-header-btn-selected':''}`}
								 onClick={()=>this.navIntoTabs(k)}
							>
								<div>{title}</div>
							</div>
						)
					})}
				</div>
				{this._renderContent()}
            </div>
        );
	}

	navIntoTabs = (data) => {
		this.setState({key: data})
	}
}
