import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class Datepicker extends Component {

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      this.setState({startDate: this.props.startDate});
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date('01/01/1970')
    };
  }

  render() {
    const { disabled, open } = this.props;
    
    return (
      <DatePicker
        selected={this.state.startDate}
        onSelect={this.selectDay}
        onChange={this.handleChange}
        className={this.props.className}
        dateFormat="dd/MM/yyyy"
        open={open}
        disabled={disabled}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    );
  }

  handleChange = date => {
    this.setState({
      startDate: date
    }, ()=>this.props.blurFunctionDate(this.state.startDate));
  };
  selectDay = () => {
    this.props.openDatePicker(false);
  };
  
}
