import React, { Component } from 'react';

export default class ImgMenuSelector extends Component {

	state = {
		isSelected: false
	};

	render() {
		const { isSelected } = this.state;
		const { imgArray } = this.props;

		return (
			<div className="main-content-custom-dialog">
				{ imgArray && imgArray.map((item, k) => {
					return (
						<div key={`div-id-photo-${k}`} id={`div-id-photo-${k}`}>
							<img id={`id-photo-${k}`} className={`img-selected ${isSelected ? 'selectedImage' : null }`} src={`${item && item.source}`} onClick={(dto)=>this.selectImage(item, dto)} />
						</div>
					)
				})}
			</div>
		)
	}

	selectImage = (item, dto) => {
		dto.currentTarget.className += " selectedImage"
		this.props.imgSelector(item)
	}
}
	