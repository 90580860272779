import React, { Component } from 'react';
import propTypes from 'prop-types';


export default class CustomInput extends Component {
  
   state = {
      inputValue: ''
   }

   render() {
      const { height, title, placeholder, typeinput, inputValue, addClassName, isDisabled, isMultiple, accept, margin, onkeypressEvent } = this.props

      return (
         <div className={`custom-input-main ${addClassName}`} style={{height:'auto', width: 'auto', margin:margin}} >
            <div className="custom-input-title" >
               {title}
            </div>
            <input type={typeinput} onBlur={this.blurFunction} className={`custom-input-element`} style={{height:height}}
               placeholder={typeinput==='password'?'**********':placeholder} multiple={isMultiple} disabled={isDisabled}
               value={inputValue} accept={accept} onChange={(data)=>this.updateInputValue(data, this)} onKeyPress={onkeypressEvent || null}
            />
         </div>
      );
   }

   updateInputValue(evt, context) {
      context.setState({
         inputValue: evt.target.value
      }, context.props.methodToRun(evt.target.value));
   }

   showOptionsPopUo = () => {
      this.setState({showImgOptions: true})
   }

   blurFunction = () => {
      const { methodToRun } = this.props
      methodToRun(this.state.inputValue);
   }
}

CustomInput.defaultProps = {
   typeinput: "text",
   height: "auto",
   width: "auto",
   title: null,
   margin: "none",
   placeholder: "",
   addClassName: "",
   isDisabled: false,
   isMultiple: false,
   accept: ""
};

CustomInput.propTypes = {
   typeinput: propTypes.string,
   height: propTypes.string,
   width: propTypes.string,
   title: propTypes.string,
   margin: propTypes.string,
   placeholder: propTypes.string,
   addClassName: propTypes.string,
   isDisabled: propTypes.bool,
   isMultiple: propTypes.bool,
   accept: propTypes.string
};
