import React from 'react';
import Header from './Header';

const PageWrapper = ({ router, component: Component, render }) => (
  <>
    {!((router.location.pathname.toUpperCase() == '/OKBUY') || (router.location.pathname.toUpperCase() == '/KOBUY')) ? <Header router={router} /> : null}
    {Component ? <Component router={router} /> : render()}
  </>
);

export default PageWrapper;
