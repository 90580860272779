import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class ShopPolicyRules extends Component {

    render() {

        return (
            <div className='main-ShopPolicyRules scrollbar'>
                <h2>Políticas de devolución de Vitalbook</h2>

                <h3>1. RECEPCIÓN DE MERCANCÍA</h3>
                <div>
                    Es condición indispensable que en el momento de la recepción de la mercancía se indique en el albarán
                    que se firma al transportista, cualquier incidencia que pueda haber en el estado de la misma o del
                    embalaje y se comunique a VITALBOOK S.L. dicha incidencia por e-mail a VITALBOOK@VITALBOOK.me
                    en un plazo máximo de 24 horas desde la recepción del paquete. Si el paquete presenta daños
                    importantes con los cuales se cree que el producto pudiese haber sufrido daños deberá ser rechazado y
                    se deberá notificar a VITALBOOK de forma inmediata.
                </div>

                <h3>2. DEVOLUCIÓN DE MERCANCÍA/TERMINOS Y CONDICIONES PARA USUARIOS FINALES</h3>
                <div>
                    El cliente dispondrá de un plazo de 5 días a partir de la recepción del producto para enviar a VITALBOOK
                    cualquier reclamación en relación al producto suministrado. Después de ese plazo los productos serán
                    considerados como conformes por el cliente. VITALBOOK no se hace responsable y se reserva el derecho
                    de rechazar posibles devoluciones en caso de mercancía en mal estado por uso indebido o daños de
                    transporte. Los gastos de transporte ocasionados por una devolución se repartirán entre el Cliente y
                    VITALBOOK, de forma que el Cliente enviará el material a VITALBOOK a portes pagados y VITALBOOK
                    devolverá el material reparado también a portes pagados.
                    No se aceptará devolución de material en los siguientes casos: 
                </div>

                <div>
                    <ul>
                        <li> Un servicio de VITALBOOK si se hubiera prestado y hubieras aceptado su prestación
                        cuando solicitaste el servicio.</li>
                        <li> Contenido digital (que no hubiera sido entregado en un soporte material (es decir, no
                        contenidos en soportes como CD o DVD) si hubieras consentido la ejecución en el
                        momento de la entrega y sin que resulte posible su desistimiento desde dicho
                        momento.

                        El motivo de la devolución del producto se debe a que llegó dañado, era defectuoso o era incorrecto,
                        Caso de álbumes impresos.</li>
                        <li> No te reembolsaremos el importe del envoltorio regalo, los gastos de envío ni ningún
                        otro servicio relacionado con tu pedido; únicamente te reembolsaremos el importe del
                        producto.</li>
                        <li> Si utilizas una etiqueta de devolución con franqueo pagado, deduciremos los gastos de
                        envío del importe total del reembolso.</li>
                        <li> Los productos deben ser devueltos en el mismo estado en el que los recibiste. Por lo
                        tanto deben ser devueltos nuevos, sin usar y completos.</li>
                        <li> Ten en cuenta que, como se establece en nuestra política de devoluciones, el producto
                        devuelto debe estar en el mismo estado en el que lo recibiste. Esto significa que los
                        productos nuevos deben devolverse exactamente en las mismas condiciones en las que
                        se recibieron de VITALBOOK (incluidos todos los accesorios)</li>
                        <li> Se podría reducir el importe de los reembolsos de los productos devueltos que no se
                        reciban en este estado.</li>
                        <li> Si has recibido un regalo y deseas devolverlo, lo examinaremos para comprobar si
                        debemos aplicarle un reembolso parcial. Al igual que con todas las devoluciones de
                        regalos, los importes de los reembolsos reducidos se abonan mediante el método y
                        soporte en el que se realizó el pago.</li>
                        <li> Esta política de devoluciones no afecta a tus derechos legales como cliente.</li>
                    </ul>
                </div>

                <h3>2.1 DEVOLUCIÓN DE MERCANCÍA/TERMINOS Y CONDICIONES ESPECIALES PARA USUARIOS FINALES</h3>
                <div>
                    Material defectuoso al momento de la recepción del mismo: Si el material está defectuoso se deberá
                    realizar la comunicación a nuestra cuenta de mail. Una vez revisado el mail con la respuesta que se
                    procederá del mismo el producto será enviado a VITALBOOK según indicaciones, con su embalaje
                    original en un plazo máximo de 7 días. En el exterior del embalaje deberá constar el número de pedido
                    asignado por mail.
                    Pasados los 7 días el número de pedido será anulado.
                </div>

                <h3>3. GARANTÍA</h3>
                <div>
                    La garantía de todos nuestros productos será la establecida por el fabricante de cada marca y será
                    indicada en la web a la hora de realizar el pedido del producto. 
                </div>

                <h3>4. FORMA DE PAGO - ENVIO DE FACTURAS</h3>
                <div>
                    La forma de pago será con tarjeta de débito/crédito mediante la plataforma de pago segura del Banco de
                    Santander, se verá reflejada en la factura, en las condiciones acordadas entre el Cliente y VITALBOOK y
                    deberán ser respetadas.
                    Las facturas serán enviadas en todos los casos por correo electrónico. Es responsabilidad del
                    cliente informar y mantener actualizado su dirección de email para recibir sus
                    facturas
                </div>

                <h3>5. POLITICA DE PRECIOS Y VALIDEZ DE OFERTAS</h3>
                <div>
                    Las ofertas de VITALBOOK tienen normalmente un plazo de validez de 4 días hábiles salvo que se
                    indique lo contrario y nos reservamos el derecho de poder modificar los precios, hasta que hayas
                    recibido nuestra confirmación de pedido. VITALBOOK se reserva el derecho de cancelación de pedido
                    ante modificaciones de precios imprevistas de nuestros proveedores.
                </div>

                <h3>6. MAILING PUBLICITARIO E INFORMATIVO</h3>
                <div>
                    De acuerdo con las condiciones generales de venta de VITALBOOK, nuestros clientes son incorporado a
                    nuestra base de datos, por lo que recibirá mailings con ofertas de nuestros productos y novedades al
                    respecto de los mismos. Si desea ejercer el derecho de cancelación de este servicio, por favor, contacte
                    con nosotros. En cada mailing que reciba, usted mismo puede darse de baja en caso que ya no desee
                    recibir más ofertas de VITALBOOK.
                </div>

                <h3>7. CANCELACION O MODIFICACION DE PEDIDOS</h3>
                <div>
                    Una vez recibido un pedido, procedemos a la ejecución del mismo y a partir de ese momento no se
                    aceptarán cancelaciones ni modificaciones del mismo.
                </div>

                <h3>8. TRANSPASO DE RIESGO</h3>
                <div>
                    El Riesgo por pérdida y/o daños se transmite al cliente desde el momento en que se produzca el envío
                    de la mercancía al cliente.
                </div>

                <h3>9. ENTREGA DEL PRODUCTO</h3>
                <div>
                    El producto se considera entregado al salir del almacén de VITALBOOK Los plazos previstos para la
                    entrega del material son meramente informativos, no siendo vinculantes para VITALBOOK. Salvo
                    acuerdo expreso por escrito con VITALBOOK, el Cliente no tendrá derecho a solicitar la anulación de un
                    pedido o cualquier indemnización (incluida la devolución de los portes) en el caso de que se produzca un
                    retraso en la entrega del producto. El Cliente no podrá negarse a pagar el precio de los productos ya
                    entregados cuando se produzca, por parte de VITALBOOK, el suministro parcial de un pedido.
                </div>

                <h3>10. RESERVA DE DOMINIO</h3>
                <div>
                    Mientras el cliente no haya pagado totalmente el precio y todas las cantidades debidas como
                    consecuencia de la venta, el producto suministrado se considerará propiedad de VITALBOOK, con todos
                    los derechos inherentes. El cliente será considerado depositario de los productos y deberá almacenarlos
                    aparte, identificándolos claramente.
                    Si al vencimiento cualquier plazo el cliente no hiciere efectivo su importe, VITALBOOK podrá optar entre
                    exigir el inmediato pago de todos los plazos vencidos y por vencer o resolver el contrato y retirar los
                    productos en poder del cliente, sin obligación de reintegrarle cantidad alguna de las percibidas, que
                    quedarán en beneficio de VITALBOOK en concepto de indemnización por incumplimiento de contrato, sin
                    perjuicio de la indemnización por los daños y perjuicios ocasionados a VITALBOOK que ésta pueda
                    reclamar.
                </div>

                <h3>11. LIMITACION DE RESPONSABILIDAD </h3>
                <div>
                    La responsabilidad de VITALBOOK S.L. en la operación de venta respecto al comprador queda limitada al
                    importe de la factura del producto vendido y a aquella establecida por la legislación aplicable en materia
                    de consumo.
                    La responsabilidad de VITALBOOK S.L.  se limita al importe de la factura; VITALBOOK S.L.  no se hace
                    responsable de cualquier pérdida por lucro cesante del comprador de los bienes. 
                </div>

                <h3>12. SEGURIDAD</h3>
                <div>
                    El sitio web utiliza técnicas de seguridad de la información generalmente aceptada en la industria, tales
                    como firewalls, procedimientos de control de acceso y mecanismos criptográficos, todo ello con el objeto
                    de evitar el acceso no autorizado de datos. Para lograr estos fines, el usuario/cliente acepta que el
                    prestador obtenga datos para efecto de la correspondiente autenticación de los controles de acceso.
                </div>

                <h3>13. PLATAFORMA PARA RESOLUCIÓN DE CONFLICTOS ENTRE CONSUMIDORES FINALES Y COMERCIOS &quot;ONLINE&quot;</h3>
                <div>
                    La Comisión Europea abre una nueva plataforma para la resolución de conflictos entre consumidores
                    finales y Comercios &quot;Online&quot;. Abarcará tanto las compras a nivel nacional como internacional. Las
                    resoluciones serán online con el fin de acelerar los tiempos y reducir los costes. Más información 
                    <a href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'>
                        aquí Acceso a la Plataforma de resoluciones de la Comisión Europea.
                    </a>
                </div>
                
                <h3>14. GARANTÍA TOTAL </h3>
                <label><FormattedMessage id="TOTAL_GARANTE" /></label>
            </div>
        )
    }
}

export default ShopPolicyRules