import React, { Component } from 'react';
import Sidebar from "react-sidebar";
import ProfileInformation from '../components/ProfileInformation';
import config from '../Utilities/Config';
import * as Service from '../Utilities/Services';
import * as Icons from '../Utilities/ImgConfig';
import * as Utils from '../Utilities/Utils';

const sidebarStyles = {
    sidebar: {
      background: '#ffffff',
      width: '20vw',
      overflowY: 'none',
      minWidth: "380px"
    },
}

export default class ProfileSidebar extends Component {

  componentWillMount() {
    this.setState({profileImage: this.props.profileImage});
  }

  state = {
    sidebarOpen: false,
    profileImage: null,
    waitingSub: null
  }

  render() {
    const { userData,
            profileFilename,
            changeProfileImage,
            updateStateUserData,
            getDataLoginFacebook,
            logoutApp,
            push,
            isAlbum,
            showHideSuscription,
            lang
          } = this.props
    const { sidebarOpen, waitingSub } = this.state
    
    return(
      <div>
        <Sidebar
          sidebar={<ProfileInformation
                    lang={lang}
                    userData={userData}
                    profileFilename={profileFilename}
                    closeSidebar={()=>this.onSetSidebarOpen(false)}
                    push={push}
                    changeProfileImage={changeProfileImage}
                    updateStateUserData={updateStateUserData}
                    getDataLoginFacebook={getDataLoginFacebook}
                    showHideSuscription={showHideSuscription}
                    logoutApp={logoutApp}
                    waitingSub={waitingSub}
                    removeMyWaitingSubscription={this.removeMyWaitingSubscription}
                  />}
          pullRight={true}
          open={sidebarOpen}
          onSetOpen={()=>this.onSetSidebarOpen(!sidebarOpen)}
          styles={sidebarStyles}
          children={<div/>}
        />
        <div className='profile-container-picture-name-logout' onClick={() => this.onSetSidebarOpen(true)}>
          <div className='profile-container-picture-select'>
            {
              profileFilename ?
                <img className={`profile-container-picture-img ${isAlbum ? 'add-cercle-w' : 'add-cercle-f' }`} 
                    src={Utils.getUserPhoto(Utils.getToken(), Utils.getFacebookToken())} 
                /> :
                <div className={`profile-container-picture-img ${isAlbum ? 'add-cercle-w' : 'add-cercle-f' }`}>
                  <img className='profile-container-picture-unselect' src={Icons.CombinedShape} />
                </div>
            }
          </div>
          <img className="profile-container-picture-down-arrow" src={isAlbum ? Icons.ShortArrowDownWhite : Icons.ShortArrowDown}/>
        </div>
      </div>
    );
  }

  onSetSidebarOpen(open) {
    this.waitingSubscription(open)
  }

  waitingSubscription = (open) => {
    if(open){
      Service.getWaitingSubscription()
        .then(response=>{
          this.setState({ 
            waitingSub: response.data,
            sidebarOpen: open 
          });
        })
        .catch(error=>{
          console.error("GET_WAITING_SUBSCRIPTION_ERROR -> ", error)
        })
    } else {
      this.setState({ 
        sidebarOpen: open 
      });
    }
  }

  removeMyWaitingSubscription = () => {
    Service.deleteMyWaitingSubscription()
      .then(response => {
        this.setState({waitingSub: null})
      })
      .catch(error => {console.error('DELETE_MY_WAITING_SUBSCRIPTION_ERROR -> ', error)})
  }
}
