import React from 'react';
import '../styles/App.css';
import { FormattedMessage } from 'react-intl';
import * as Image from '../Utilities/ImgConfig';

class OkBuy extends React.Component {

    render() {
        return (
            <div className="ok-redirect-page" >
                <h4>
                    <FormattedMessage id="REDIRECT_PAGE_OK" />
                </h4>
                <img src={Image.Check}></img>
            </div>
        );
  }
}

export default OkBuy;
