import React, { Component } from 'react';
import DraggableSpace from '../DraggableSpace'
import * as Utils from '../../Utilities/Utils'

export default class Template5 extends Component {
    render() {
      const { items, page, pagePosition, stateGemerics, methodToRun, reloadActualPages, isPreview } = this.props
      const textContent = Utils.getElementsText(page, "TEXT")
      const imgContent = Utils.getElementsText(page, "PICT")
      const ordedElements = Utils.orderArrayByParam(this.props.page.elements)

      return(
        <div key={`template-5-${pagePosition}-id_${page.id}`} className="main-template-horizontal">
          <DraggableSpace stateGemerics={stateGemerics} 
                          items={imgContent.pictureElement.repositoryItemId ? [imgContent.pictureElement] : null} 
                          page={page}
                          pagePosition={`${pagePosition}-${ordedElements[0].id}`}
                          methodToRun={methodToRun}
                          reloadActualPages={reloadActualPages}
                          isPreview={isPreview}
                          editSize={{height:400,width:153}}
                          classNamesEmpty={'dropContxt-empty-img-template5-full'}
            />
            <textarea key={`template-5-${pagePosition}-text-area-id_${page.id}`} className={`template-6-text scrollbar ${isPreview ? 'template-preview' : ''}`}
                      defaultValue={textContent && textContent.textElement && textContent.textElement.text}
                      disabled={isPreview ? true : false} onBlur={(data)=>this.compileAllText(data, textContent)} />
        </div>
      );
    }

    compileAllText = (data, element) => {
      const { updateText } = this.props
      updateText(data, element)
   }
}
