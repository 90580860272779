import React, { Component } from 'react';
import DraggableSpace from '../DraggableSpace'
import * as Utils from '../../Utilities/Utils'


export default class Template3 extends Component {

   render() {
      const { items, page, pagePosition, stateGemerics, methodToRun, reloadActualPages, isPreview } = this.props
      const ordedElements = Utils.orderArrayByParam(this.props.page.elements)
      const imgContent = Utils.getElementsText(page, "PICT")

      return(
         <div key={`template-3-${pagePosition}-id_${page.id}`} className="main-template">
            <DraggableSpace stateGemerics={stateGemerics}
                            items={imgContent.pictureElement.repositoryItemId ? [imgContent.pictureElement] : null} 
                            page={page} 
                            pagePosition={`${pagePosition}-${ordedElements[0].id}`} 
                            methodToRun={methodToRun} 
                            reloadActualPages={reloadActualPages} 
                            isPreview={isPreview}
                            editSize={{height:400,width:300}}
                            classNamesEmpty={'dropContxt-empty-img-template7-full'}
            />
         </div>
      );
   }

   compileAllText = (data, element) => {
      const { updateText } = this.props
      updateText(data, element)
   }
}
