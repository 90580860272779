import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';

import { LOCALE_SET_LANGUAGE, GET_LANGUAGE } from '../types';

import commonCa from '../../Utilities/lang/ca.json';
import commonEn from '../../Utilities/lang/en.json';
import commonEs from '../../Utilities/lang/es.json';

import documentConfig from '../Actions/config.json';

const documentMessages = documentConfig.i18n;
const CA = { ...commonCa, ...documentMessages.ca };
const EN = { ...commonEn, ...documentMessages.en };
const ES = { ...commonEs, ...documentMessages.es };

const messages = { CA, EN, ES };
const defaultLang = (navigator.language || navigator.userLanguage).split('-')[0].toUpperCase(); //null //(navigator.language || navigator.userLanguage).split('-')[0].toUpperCase();

const initialState = {
  lang: defaultLang,
  messages: messages[defaultLang],
  documentMessages
};

export default (state = initialState, { type, payload = {} }) => {
  const { lang } = payload;
  switch (type) {
    case LOCALE_SET_LANGUAGE:
      moment.locale(lang);
      return {
        ...state,
        lang,
        messages: messages[lang],
      };
    case GET_LANGUAGE:
      moment.locale(lang);
      return {
        ...state,
        lang,
        messages: messages[lang],
      };
    default:
      return state;
  }
};
