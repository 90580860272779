import React, { Component } from 'react'
import * as Services from '../../Utilities/Services'
import * as Utils from '../../Utilities/Utils'
import MenuTabs from '../../components/MenuTabs'
import MyContacts from './TabsContacts/MyContacts'
import PendingAccept from './TabsContacts/PendingAccept'
import Requests from './TabsContacts/Requests'
import { injectIntl } from 'react-intl';


class Contacts extends Component {
	
	render() {
		const { myConnections, requestSend, requestRecived, updateUsers, lang, intl } = this.props

        return (
			<MenuTabs>
				<MyContacts title={intl.formatMessage({id: 'TITLE_MY_CONTACTS'})} personList={myConnections} updateUsers={updateUsers} lang={lang} />
				<PendingAccept title={intl.formatMessage({id: 'TITLE_PENDING'})} personList={requestSend} updateUsers={updateUsers} lang={lang} />
				<Requests title={intl.formatMessage({id: 'TITLE_MY_REQUESTS'})} personList={requestRecived} updateUsers={updateUsers} lang={lang} />
			</MenuTabs>
		)
	}


}
	
export default injectIntl(Contacts)