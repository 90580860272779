import React, { Component } from 'react';
import * as Services from '../../Utilities/Services'
import UserVitalAngels from './UserVitalAngels'
import Contacts from './Contacts'
import InviteFriends from './InviteFriends'

export default class VitalAngels extends Component {

	componentWillMount() {
		//Utils.loadLanguatge(this)
		this.getInfo()
	}

	state = {
		lang: null,
		allContact: null,
		myVitalAngels: null,
		requestSend: null,
		requestRecived: null
	};

	render() {
		const { allContact, myVitalAngels, requestSend, requestRecived, lang } = this.state

        return (
			// <div>
				<div className='main-vitalangels'>
					<div className='vitalangels-comp-invite-user-vital-angels'>
						<InviteFriends updateUsers={()=>this.getInfo()}
									   lang={lang} />
						<UserVitalAngels updateUsers={()=>this.getInfo()}
										 myVitalAngels={myVitalAngels}
										 lang={lang} />
					</div>
					<Contacts updateUsers={()=>this.getInfo()} 
							  myConnections={allContact}
							  requestSend={requestSend}
							  requestRecived={requestRecived}
							  lang={lang} />
				</div>
			// </div>
		)
	}

	getInfo() {
		this.getMyContacts()
		this.getAllTypeRequests()
	}

	getMyContacts = () => {
		Services.getMyConnections()
			.then(response => {
				this.getVitalAngels(response.data)
			})
			.catch(error=> {
				console.error('GET_ALL_MY_CONNECTION_REQUESTS_ERROR -> ', error)
			})
	}

	getVitalAngels = (data) => {
		let vitalAngels = []
		data.map(item => {
			if (item.myVitalangel)
				vitalAngels.push(item)
		})
		
		this.setState({
			allContact: data,
			myVitalAngels: vitalAngels
		})
	}

	getAllTypeRequests = () => {
		Services.getAllMyConnectionRequest()
			.then(response => {
				this.agrupateTypeUsers(response.data)
			})
			.catch(error=> {
				console.error('GET_ALL_MY_CONNECTION_REQUESTS_ERROR -> ', error)
			})
	}

	agrupateTypeUsers = (data) => {
		let requestRecived = []
		let requestSend = []

		data.map(item=>{
			if (item.connectionRequestSent)
				requestSend.push(item)
			else
				requestRecived.push(item)
		})

		this.setState({
			requestSend: requestSend,
			requestRecived: requestRecived
		})
	 }

}
	