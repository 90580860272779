import React, { Component } from 'react'
import graph from 'fb-react-sdk'
import FacebookLogin from 'react-facebook-login'
import LoadImgLocal from '../../components/LoadImgLocal'
import ImgMenuSelector from '../../menuSelector/ImgMenuSelector'
import LoadImgsPreviewDialog from '../../Dialogs/LoadImgsPreviewDialog'
import Dialog from '../../Dialogs/GenericDialog'
import * as Icons from '../../Utilities/ImgConfig'
import * as Service from '../../Utilities/Services'
import { FormattedMessage, injectIntl } from 'react-intl';
//import GoogleDrivePicker from './GoogleDrivePicker'
import GoogleDrivePicker from '../../components/GoogleDrivePicker'

const GOOGLEDRIVE_CLIENT_ID = '179020330943-je5rdou0khp1jb9cei0kmpsomqs81vod.apps.googleusercontent.com';
const GOOGLEDRIVE_DEVELOPER_KEY = 'AIzaSyA6TDEdIzg5GE2Hh-9AVw30wZv_O5GSL20';
const GOOGLEDRIVE_SCOPE = ['https://www.googleapis.com/auth/drive.readonly'];

const getImgs = (element, origin) => {
    let elementTemp = []

    element.map((item, k) => {
        if(origin === 'instagram') {
            elementTemp.push({
                source: item.id || item.source,
                origin: origin || null
            })
        } else {
            elementTemp.push({
                id: item.id,
                source: (origin === 'facebook') ? item.id : `${item.image || item.source || item}`,
                origin: origin || null
            })
        }
    });
    return  elementTemp;
}

class uploadPhotos extends Component {

    clientId = "375625336728296"
    IMPORT_LOCAL = 'Local'
    TEXT_FACEBOOK = "Facebook"
    TEXT_INSTAGRAN = "Instagram"
    IMPORT = "Importar"

    state = {
        dataLoginFacebook: null,
        showLoadImgsPreviewDialog: false,
        dataLoadPreview: null,
        iteratorLoadImgs: 0,
        iteratorLoadImgsError: 0,
        imgToShow: null,
        imgSelector: false,
        allFbImg: null,
        allFbImgSelected: null,
        allInstagramImg: null,
        allIgImgSelected: null
    }

    render() {
        const { close, open, gdToken } = this.props
        const { allInstagramImg, allFbImg, dataLoadPreview, iteratorLoadImgs, iteratorLoadImgsError, showLoadImgsPreviewDialog, imgSelector, googleDriveToken } = this.state

        const loadImgsPreviewDialog = showLoadImgsPreviewDialog ? 
                                    <LoadImgsPreviewDialog  open={showLoadImgsPreviewDialog} 
                                                            close={this.closeInfoUploadImgDialog}
                                                            data={dataLoadPreview}
                                                            cross={true}
                                                            iteratorLoadImgs={iteratorLoadImgs} 
                                                            iteratorLoadImgsError={iteratorLoadImgsError} /> : null
                                    {/**/}
        return (
            <Dialog accept={imgSelector && this.acceptFunct} open={open} close={false} cross={close}>
                <div className={`main-vertical-tap-img-preview ${!imgSelector ? "":"main-vertical-upload-img-preview"}` }>
                    {loadImgsPreviewDialog}
                    <div className="main-vertical-titles">
                        <div className="main-vertical-title"><FormattedMessage id="UPLOAD_PHOTOS_TITLE" /></div>
                        <div className="main-vertical-sub-title"><FormattedMessage id="UPLOAD_PHOTOS_SUB_TITLE" /></div>
                    </div>
                    <div className="main-vertical-tap-img-preview-facebook scrollbar">
                        {imgSelector ? 
                            <div className="main-vertical-tap-controls">
                                <img src={Icons.LeftArrow} className="main-vertical-tap-controls-close-btn" onClick={this.closeImgSelector}/>
                            </div>
                            : null}
                        <div className="main-vertical-tap-img-preview-local">
                            {!imgSelector ?
                                <LoadImgLocal id="upload-fotos" customClassName="btn-upload-local-photos-icon" nameInput={this.IMPORT_LOCAL} multiple={true} getLocalImg={this.getLocalImg} icon={Icons.HardDrive}/> : null
                            }
                        </div>
                        {/* DISABLED FACEBOOK ON 2022-03-09 */}
                        {/*
                        {!imgSelector ?
                            <FacebookLogin
                                appId='2172458082993015'
                                autoLoad={false}
                                cookie={false}
                                reAuthenticate={true}
                                authType="reauthenticate"
                                fields="name,email,picture"
                                scope="user_photos"
                                textButton= {<div className='profile-container-button-text'>{this.TEXT_FACEBOOK}</div>}
                                cssClass="menu-container-btn-facebook"
                                callback={this.responseFacebook}
                                icon={<img className='profile-container-button-icons' src={Icons.Facebook} alt="fb" />}
                            /> : <ImgMenuSelector imgArray={allFbImg} imgSelector={this.getImgSelectFb} />
                        }
                        */}
                        <div>
                            {/*
                            <GoogleDrivePicker 
                                onSelectFiles={(data) => this.onGoogleDriveSelectFiles(data)} 
                                onAuth={(data) => this.onGoogleDriveAuth(data)}
                                token={gdToken}
                            />
                            */}
                            <GoogleDrivePicker 
                                clientId={GOOGLEDRIVE_CLIENT_ID}
                                developerKey={GOOGLEDRIVE_DEVELOPER_KEY}
                                scope={GOOGLEDRIVE_SCOPE}
                                onChange={data => this.onGoogleDriveChange(data)}
                                onAuthenticate={data => this.onGoogleDriveAuth(data)}
                                onAuthFailed={data => this.onGoogleDriveAuthFailed(data)}
                                multiselect={true}
                                navHidden={true}
                                authImmediate={false}
                                mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                viewId={'DOCS_IMAGES'}
                                locale={this.props.intl.locale}>
                                <button className="btn-upload-photos-icon" style={{width: '220px', margin: '0 auto'}}>
                                    <svg width="30" viewBox="0 0 1443.061 1249.993"><path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/><path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/><path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/></svg>
                                    Google Drive
                                </button>
                                <div className="google"></div>
                            </GoogleDrivePicker>
                        </div>

                        {/* TODO DEVELOV API FACEBOOK INSTAGRAM  PROBABLY 02/03/2020 */}
                        {/* <div className="main-vertical-tap-img-preview-instagram">
                            {!imgSelector ?
                                <button className='menu-container-btn-instagram ' onClick={this.clickFunctInsta} >
                                    <img className='profile-container-button-icons' src={Icons.IGLogo} alt="Ig" />
                                    <div className='profile-container-button-text'>{this.TEXT_INSTAGRAN}</div>
                                </button>
                                : <ImgMenuSelector imgArray={allInstagramImg} imgSelector={this.getImgSelectIg} />
                            }
                        </div> */}
                    </div>
                </div>
            </Dialog>
        )
    }

    clickFunctInsta = () => {
        Service.isValidInstagramSession()
            .then(response=>{
                if(response.data) {
                    this.getTokenInstaFromVital()
                 } else {
                    this.getTokenInstagram()
                 }
        })
        .catch(error=>{
            console.error("IS_VALID_INSTAGRAM_SESSION_ERROR -> ", error)
        })
    }

    getTokenInstaFromVital = () => {
        Service.getInstagramTokenIsValidConn()
            .then(response=>{
                this.getPhotosInsta(response.data.socialNetworkToken)
            })
            .catch(error=>{console.error("GET_INSTAGRAM_TOKEN_IS_VALID_CONN_ERROR ->", error)})
    }

    getTokenInstagram = () => {
        const Http = new XMLHttpRequest()
        const redirectUri = `https://app.vitalbook.me/myapps`
        const scope = "user_profile user_media"
        const t = "code"
        
        const url = `https://api.instagram.com/oauth/authorize?client_id=${this.clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${t}`

        const strWindowFeatures = "location=yes,height=570,width=520,scrollbars=no,status=yes"

        Http.open("GET", url)
        Http.send()
        Http.onreadystatechange=(e)=>{
            if (Http.readyState === 4 && Http.status === 200) {
                const instaImgs=Http
                const URL = instaImgs.responseURL;
                window.open(URL, "_blank", strWindowFeatures);
            }
        }
    }

    getPhotosInsta = (token) => {
        const fields = "id,media_type,media_url"
        const Http = new XMLHttpRequest()
        const user_id_instagram = ""
        // const url = `https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret=${"29fc1494f192d58ba4bbf01c57971bb3"}&access_token=${token}` //extends token 60d
        const url = `https://graph.instagram.com/${user_id_instagram}?fields=${fields}&access_token=${token}` //<- good
        Http.open("GET", url)
        Http.send()
        Http.onreadystatechange=(e)=>{
            if (Http.readyState === 4 && Http.status === 200) {
                const instaImgs=JSON.parse(Http.response)
                this.groupImgsInsta(instaImgs.data)
            }
        }
    }
    groupImgsInsta = (response) => {
        let imgs = []
        response.map(item => {
            imgs.push({
                'id': item.id,
                'source': item.images.standard_resolution.url
            })
        })
        this.setState({allInstagramImg: imgs}, ()=>this.setState({imgSelector: true}))
    }
    acceptFunct = () => {
        const { allFbImgSelected, allIgImgSelected } = this.state
        this.callInsertRepositoryItems(allFbImgSelected || allIgImgSelected)
        this.props.callGetAllRepositoryItems()
        this.props.close()
    }
    getImgSelectFb = img => {
        let totalImg = this.state.allFbImgSelected || []
        totalImg=totalImg.concat(img)
        this.setState({
           allFbImgSelected: getImgs(totalImg, "facebook"),
           imgSelector: true
        })
    }
    getImgSelectIg = img => {
        let totalImg = this.state.allIgImgSelected || []
        totalImg=totalImg.concat(img)
        this.setState({
           allIgImgSelected: getImgs(totalImg, "instagram")
        })
    }
    closeInfoUploadImgDialog = () => {
        this.setState({showLoadImgsPreviewDialog: false, dataLoadPreview: null, iteratorLoadImgs: 0, iteratorLoadImgsError: 0})
    }
    infoUploadImgDialog = (key) => {
        this.state[key]++
        this.setState({[key]: this.state[key]})
    }
    callInsertRepositoryItems = (items) => {
        let album = {
            collection: {
                id: this.props.defaultCollectionId
            },
            repositoryItemDto: items,
        }
        Service.insertRepositoryItems(album)
           .then(response => {})
           .catch(error => {console.error("inset_all_repo_items_error -> ", error)})
    }
    responseFacebook = response => {
        const socialNetworkToken = response.accessToken
        const scope = this;
        Service.accessAuth({"socialNetworkToken": socialNetworkToken, "type": "FACEBOOK"})
            .then(response => {
                graph.setVersion("3.3");
                graph.setAccessToken(socialNetworkToken);
                graph.get("/me?fields=albums.fields(photos.fields(source))", function(err, res) {
                    if(err)
                        console.error("error get FB photos -> ", err)

                    if(res && res.albums) {
                        scope.setState({logFB: true})
                        scope.agroupAllImgs(res.albums.data)
                    } else {
                        console.error("no image on profile user, is possible you don't have user_photos permissions")
                    }
                })
            })
            .catch(error => {
                console.error("ACCES_AUTHORIZATION_ERROR -> ", error)
            })
    }
    getLocalImg = data => {
        this.setState({showLoadImgsPreviewDialog: true, dataLoadPreview: data, iteratorLoadImgs: 0, iteratorLoadImgsError: 0})
        if(data.length > 0) {
            for(let i=0; i<data.length; i++) {
                let multipart = new FormData();
                multipart.append('file', data[i]);
                Service.uploadLocalImgItem(this.props.defaultCollectionId, multipart)
                    .then(response => {
                        this.infoUploadImgDialog('iteratorLoadImgs')
                    })
                    .catch(error => {
                        this.infoUploadImgDialog('iteratorLoadImgsError')
                        console.error("upload_local_img_to_collecton error ->  ", error)
                    })
            }
        }
    }
    closeImgSelector = () => {
        this.setState({imgSelector: false})
    }
    agroupAllImgs = albums => {
        let allPhotos = []
        albums.map(item => {
           if(item.photos) {
              item.photos.data.map(item => {
                 allPhotos.push(item)
              })
           }
        })

        this.setState({
           imgToShow: allPhotos,
           allFbImg: getImgs(allPhotos),
           imgSelector: true
        })
     }
     
    onGoogleDriveChange = (data) => {
        switch (data.action) {
            case 'loaded':
                break;
            case 'picked':
                this.onGoogleDriveSelectFiles(data.docs);
                break;
        }
    }

    onGoogleDriveSelectFiles = (files) => {
        if (files.length > 0) {
            this.setState({showLoadImgsPreviewDialog: true, dataLoadPreview: files, iteratorLoadImgs: 0, iteratorLoadImgsError: 0})
            
            files.map(file => {
                Service.importFromGoogleDrive(this.props.defaultCollectionId, file, this.props.gdToken)
                    .then(response => {
                        this.infoUploadImgDialog('iteratorLoadImgs')
                    })
                    .catch(error => {
                        this.infoUploadImgDialog('iteratorLoadImgsError')
                        console.error("import_from_google_drive error ->  ", error)
                        this.onGoogleDriveAuth({access_token: ''})
                    })
            })
            
        }
    }

    onGoogleDriveAuth = (authResponse) => {
        const { gdOnAuth } = this.props;
        gdOnAuth(authResponse)
    }

    onGoogleDriveAuthFailed = (authResponse) => {
        //const { gdOnAuth } = this.props;
        //gdOnAuth({access_token: ''})
        console.log('Auth failed', authResponse);
    }
}

export default injectIntl(uploadPhotos);