import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';

import { FormattedMessage, IntlProvider, injectIntl } from 'react-intl';

import moment from 'moment';

import classnames from 'classnames';
import marked from 'marked';
import $ from 'jquery';

import { setFormStep } from '../../Redux/Actions/documentFormActions';

const display = (data, { type, separator, format, field, fields }, locale) => {
  switch (type) {
    case 'list':
      return data.map(d => display(d, { ...field }, locale)).join(separator);
    case 'composed':
      return fields.reduce(
        (acc, { id }) =>
          acc.replace(
            new RegExp(`%!${id}!%`, 'g'),
            match => display(data[id], {}, locale) || match
          ),
        format
      );
    case 'date':
      moment.locale(locale);
      return moment(data).format(format || 'LL');
    case 'string':
    default:
      return data;
  }
};
class Document extends React.Component {
  state = {
    md: '',
    isLoading: true
  };

  fieldClassName = 'FillableDocument__Document-field';
  selectedFieldClassName = 'FillableDocument__Document__field-selected';
  filledFieldClassName = 'FillableDocument__Document__field-filled';
  extensionFieldClassName = 'FillableDocument__Document__field-extension';

  async componentDidMount() {
    const { md } = this.props;
    const setLoading = isLoading => this.setState({ isLoading });

    setLoading(true);
    if (md) {
      await this.updateDocument();
    }
    setLoading(false);
  }
  async componentDidUpdate(prevProps) {
    const { md, values, path, step } = this.props;

    if (
      path !== prevProps.path ||
      values !== prevProps.values ||
      step !== prevProps.step ||
      md !== prevProps.md
    ) {
      await this.updateDocument();
    }
  }

  tryI18n = id => (id ? this.props.intl.formatMessage({ id }) : null);

  async updateDocument() {
    const {
      values,
      md,
      step,
      fields,
      locale: { lang, documentMessages },
      setFormStep
    } = this.props;

    const makeFieldClassNameObject = ({ id, extension }) => ({
      [this.selectedFieldClassName]: step === id,
      [this.filledFieldClassName]: step !== id && values[id],
      [this.extensionFieldClassName]: extension
    });

    const findFieldById = fieldId => fields.find(({ id }) => fieldId === id);
    const fieldIdRegexp = /%!([\w\-\_\d]+)!%/g;

    const __html = marked(
      Object.entries(values)
        .reduce((acc, [fieldId, value]) => {
          const currentField = findFieldById(fieldId);
          if (!currentField) {
            return acc;
          }

          const { summary, extension } = currentField;
          if (!extension) {
            setTimeout(() =>
              $(`span#${fieldId}:not(.${this.extensionFieldClassName})`).on(
                'click',
                () => setFormStep(fieldId)
              )
            );
          }

          return value
            ? acc.replace(
                new RegExp(`%!${fieldId}!%`, 'g'),
                ReactDOMServer.renderToString(
                  <IntlProvider locale={lang} messages={documentMessages[lang]}>
                    <span
                      id={fieldId}
                      className={classnames(
                        'bold',
                        this.fieldClassName,
                        makeFieldClassNameObject(currentField)
                      )}
                      title={this.tryI18n(summary)}
                    >
                      <FormattedMessage
                        id={display(value, currentField, lang)}
                      />
                    </span>
                  </IntlProvider>
                )
              )
            : acc;
        }, md)
        .replace(fieldIdRegexp, (match, capture) => {
          const field = findFieldById(capture);
          const placeholder = '\\_\\_\\_\\_\\_\\_\\_\\_\\_';
          return ReactDOMServer.renderToString(
            <span
              className={classnames(
                this.fieldClassName,
                makeFieldClassNameObject({ id: capture })
              )}
              title={this.tryI18n(!!field ? field.summary : false)}
            >
              {!!field && field.type === 'composed' && !!field.format
                ? field.format.replace(fieldIdRegexp, placeholder)
                : placeholder}
            </span>
          );
        })
    );

    this.setState({ __html });
  }

  render() {
    const { className } = this.props;
    const { isLoading, __html } = this.state;
    return isLoading ? null : (
      <div id={'FillableDocument__Document'} className={className} dangerouslySetInnerHTML={{ __html }} />
    );
  }
}

export default injectIntl(
  connect(
    ({ documentForm, locale }) => ({
      ...documentForm,
      locale
    }),
    { setFormStep }
  )(Document)
);
