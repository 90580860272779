import React, { Component } from 'react';
import Dialog from './GenericDialog'
import CustomInput from '../components/CustomInput'
import { FormattedMessage, injectIntl } from 'react-intl';


class DialogNewSection extends Component {

    state = {
        inputValue: null
    }

	render() {
        const { open, close, intl } = this.props
        const { inputValue } = this.state

		return (
            <Dialog open={open} accept={this.accept} cross={close} customStyles='main-new-section' >
                <div className="new-section-title"><FormattedMessage id="CREATE_NEW_SECTION" /></div>
                <div className='new-section-subtitle'><FormattedMessage id="SUB_TITLE_NEW_SECTION" /></div>
                <CustomInput placeholder={intl.formatMessage({id:'PH_NEW_SECTION'})}
                             addClassName={`home-content-login-input ${inputValue ? '': 'home-content-login-input-empty'}`}
                             height='5vh'
                             width='65%' 
                             methodToRun={(data) => this.setState({inputValue: data})} />
                <div className='new-section-aditional-info'/>
			</Dialog>
		)
    }

    accept = () => {
        const { inputValue } = this.state
        const { accept } = this.props
        accept(inputValue)
    }

}

export default injectIntl(DialogNewSection)